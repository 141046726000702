import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DarkModeToggle from "./DarkModeToggle";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Container = styled.div`
	position: relative;
	margin: 0;
	width: 100vw;
	height: 800px;
	max-height: 75vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: -1rem;
	margin-left: -1rem;
	margin-right: -1rem;
	background: linear-gradient(179.13deg, #160221 0.75%, rgba(92, 66, 105, 0) 99.26%);
`;

const StyledVideo = styled.video`
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
	opacity: 32%;
`;

const TitleContainer = styled.div`
	position: relative;
	width: 100vw;
	height: 800px;
	max-height: 100vh;
	display: flex;
	padding-top: min(22vh, 200px);
	flex-direction: column;
	align-items: center;
	z-index: 3;
`;

const VowerLogoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const VowerLogo = styled.img`
	width: min(13vw, 170px);
	padding-right: min(1.5vw, 30px);
`;

const VowerHeader = styled.p`
	font-family: Inter;
	font-weight: 800;
	color: #ffffff;
	font-size: min(10vw, 108px);
	align-text: center;
	padding: 0;
	margin: 0;
`;

const VowerTagline = styled.p`
	font-family: Inter;
	font-weight: 800;
	color: #ffffff;
	font-size: min(4.5vw, 46px);
	align-text: center;
	padding: 0;
	margin: 0;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	width: ${props => (props.$windowWidth < 500 ? "35vw" : "min(90vw, 850px)")};
	height: 20vw;
	margin-top: ${props => (props.$windowWidth < 500 ? "50px" : "min(4vw, 30px)")};
	z-index: 2;
`;

const LinkButton = styled.button`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.5vw" : "1.3vw")}, 16px);
	padding: 0;
	background: linear-gradient(180deg, #0fafb0 0%, #1d7a7a 100%);
	box-shadow: 0px 5px 41px 4px rgba(1, 103, 104, 0.7);
	border-radius: min(${props => (props.$windowWidth < 500 ? "2vw" : ".7vw")}, 7px);
	align-items: center;
	justify-content: center;
	color: #ffffff;
	width: ${props => (props.$windowWidth < 500 ? "35vw" : "min(14.2vw, 186px)")};
	height: min(9vw, 46px);
	margin-top: 18px;
	margin-left: 9px;
	margin-right: 9px;
	border: none;
	outline: none;
`;

const DarkModeToggleContainer = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	padding: ${props => (props.$windowWidth < 500 ? "2vw 4vw 2vw 4vw" : "1vw 3vw 1vw 3vw")};
`;

export default function HeroVideo(props) {
	const { setCards, talentsCards, customersCards, setForTalent, howItWorksRef, obtainNewSkillsRef } = props;
	const { windowWidth } = useWindowDimensions();
	const navigate = useNavigate();
	const executeScrollHIW = () => howItWorksRef.current.scrollIntoView();
	const executeScrollONS = () => obtainNewSkillsRef.current.scrollIntoView();
	const findGigsClicked = () => {
		setForTalent(true);
		setCards(talentsCards);
		executeScrollHIW();
	};

	const findTalentClicked = () => {
		setForTalent(false);
		setCards(customersCards);
		executeScrollHIW();
	};

	const learnSkillsClicked = () => {
		executeScrollONS();
	};

	return (
		<Container>
			<StyledVideo src={window.images.homescreen.heroVideo} autoPlay loop muted playsInline />
			<TitleContainer>
				<VowerLogoContainer>
					<VowerLogo src={window.images.vowerLogoPNG} />
					<VowerHeader>VOWER</VowerHeader>
				</VowerLogoContainer>
				<VowerTagline>
					<span className="vower-blue">Reinventing </span>
					the
					<span className="vower-yellow"> Future </span>
					of Work.
				</VowerTagline>
				<ButtonContainer $windowWidth={windowWidth}>
					<LinkButton $windowWidth={windowWidth} onClick={() => navigate("/opportunities")}>
						FIND GIGS
					</LinkButton>
					<LinkButton $windowWidth={windowWidth} onClick={executeScrollONS}>
						LEARN SKILLS
					</LinkButton>
					<LinkButton $windowWidth={windowWidth} onClick={findTalentClicked}>
						FIND TALENT
					</LinkButton>
				</ButtonContainer>
			</TitleContainer>
			<DarkModeToggleContainer $windowWidth={windowWidth}>
				<DarkModeToggle />
			</DarkModeToggleContainer>
		</Container>
	);
}
