import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Check, X } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { resetPassword } from "../actions/userActions";
import { useLocation, useNavigate } from "react-router";

function ResetPasswordScreen() {
	const [email, setEmail] = useState("");
	const userReset = useSelector(state => state.userReset);
	const { loading, userInfo, error, success } = userReset;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const redirect = location && location.search ? location.search.split("=")[1] : "/";
	useEffect(() => {
		if (userInfo) {
			// console.log(userInfo);
			navigate(redirect);
		}
		return () => {
			//
		};
	}, [userInfo]);

	const resetPasswordHandler = e => {
		e.preventDefault();
		dispatch(resetPassword(email));
	};

	return (
		<>
			<Helmet>
				<title>Vower | Reset Password</title>
			</Helmet>
			<div className="d-flex justify-content-center">
				<Form onSubmit={resetPasswordHandler} className="d-flex flex-column">
					<h2>Reset Password</h2>
					<Form.Group controlId="email">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							placeholder="Email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							disabled={loading || success}
						/>
					</Form.Group>
					{success ? (
						<h3 className="text-center">
							Email Sent! <Check fill="var(--green)" />
						</h3>
					) : (
						<>
							<Button
								type="submit"
								variant="main"
								disabled={loading}
								className="d-flex justify-content-center align-items-center"
							>
								{loading ? <ClipLoader size="1.5rem" /> : "Reset Password"}
							</Button>
							{error && (
								<>
									<h3 className="text-center mt-2">
										An error occurred. Please try again. <X fill="red" />
									</h3>
									<div style={{ color: "red" }}>
										<strong>Error Details: </strong>
										{error}
									</div>
								</>
							)}
						</>
					)}
				</Form>
			</div>
		</>
	);
}
export default ResetPasswordScreen;
