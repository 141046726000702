import axios from "axios";

const uploadFiles = files => async (dispath, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		const url = `${process.env.REACT_APP_BACKEND_URL}/files/upload`;

		const formData = new FormData();
		files.map(file => formData.append("files", file));

		const { data } = await axios.post(url, formData, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		return data;
	} catch (error) {
		console.log("Upload Failed", error);
	}
};

const deleteFiles = filePaths => async (dispath, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		const url = `${process.env.REACT_APP_BACKEND_URL}/files/remove`;
		await axios.post(
			url,
			{ filePaths },
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		return;
	} catch (error) {
		console.log("Delete Failed", error);
	}
};

export { uploadFiles, deleteFiles };
