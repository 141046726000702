import axios from "axios";
import Cookie from "js-cookie";
import {
	CART_ADD_ITEM,
	CART_CLEAR,
	CART_EDIT_QUANTITY,
	CART_REMOVE_ITEM,
	CART_SAVE_SHIPPING,
	CART_SAVE_ADDRESS_SELECTION
} from "../constants/cartConstants";

const addToCart = (productId, quantity) => async (dispatch, getState) => {
	try {
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/incentives/${productId}`);
		dispatch({
			type: CART_ADD_ITEM,
			payload: {
				_id: data._id,
				name: data.name,
				image: data.images[0],
				points: data.points,
				quantity,
				stock: data.stock
			}
		});
		const {
			cart: { cartItems }
		} = getState();
		Cookie.set("cartItems", JSON.stringify(cartItems));
	} catch (error) {}
};

const clearCart = () => dispatch => {
	dispatch({ type: CART_CLEAR });
	Cookie.remove("cartItems");
};

const editCartQuantity = (productId, quantity) => (dispatch, getState) => {
	dispatch({ type: CART_EDIT_QUANTITY, payload: { _id: productId, quantity } });
	const {
		cart: { cartItems }
	} = getState();
	Cookie.set("cartItems", JSON.stringify(cartItems));
};

const removeFromCart = productId => (dispatch, getState) => {
	dispatch({ type: CART_REMOVE_ITEM, payload: productId });
	const {
		cart: { cartItems }
	} = getState();
	Cookie.set("cartItems", JSON.stringify(cartItems));
};

const saveShipping = newShipping => (dispatch, getState) => {
	dispatch({ type: CART_SAVE_SHIPPING, payload: newShipping });
	const {
		cart: { shipping }
	} = getState();
	Cookie.set("shipping", JSON.stringify(shipping));
};

const saveAddressSelection = addressSelection => dispatch => {
	dispatch({ type: CART_SAVE_ADDRESS_SELECTION, payload: addressSelection });
};

export { addToCart, clearCart, editCartQuantity, removeFromCart, saveShipping, saveAddressSelection };
