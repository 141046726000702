import {
	USER_REFRESH,
	USER_REFRESH_SUCCESS,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNIN_FAIL,
	USER_LOGOUT,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	USER_SAVE_REQUEST,
	USER_SAVE_SUCCESS,
	USER_SAVE_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_RESET_REQUEST,
	USER_RESET_SUCCESS,
	USER_RESET_FAIL,
	USER_LIST_EVENTS_REQUEST,
	USER_LIST_EVENTS_SUCCESS,
	USER_LIST_EVENTS_FAIL,
	USER_DEL_EVENT_REQUEST,
	USER_DEL_EVENT_SUCCESS,
	USER_DEL_EVENT_FAIL
} from "../constants/userConstants";

function userRefreshReducer(state = {}, action) {
	switch (action.type) {
		case USER_REFRESH:
			return { refreshing: true };
		case USER_REFRESH_SUCCESS:
			return { refreshing: false };
		default:
			return state;
	}
}

function userSigninReducer(state = {}, action) {
	switch (action.type) {
		case USER_SIGNIN_REQUEST:
			return { loading: true };
		case USER_SIGNIN_SUCCESS:
			return { loading: false, userInfo: action.payload };
		case USER_SIGNIN_FAIL:
			return { loading: false, error: action.payload };
		case USER_LOGOUT:
			return {};
		default:
			return state;
	}
}

function userResetReducer(state = {}, action) {
	switch (action.type) {
		case USER_RESET_REQUEST:
			return { loading: true };
		case USER_RESET_SUCCESS:
			return { loading: false, success: true };
		case USER_RESET_FAIL:
			return { loading: false, error: action.payload, success: false };
		default:
			return state;
	}
}

function userListReducer(state = { users: [] }, action) {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loading: true, users: [] };
		case USER_LIST_SUCCESS:
			return { loading: false, users: action.payload };
		case USER_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function userSaveReducer(state = { user: {} }, action) {
	switch (action.type) {
		case USER_SAVE_REQUEST:
			return { loading: true };
		case USER_SAVE_SUCCESS:
			return { loading: false, success: true, userInfo: action.payload };
		case USER_SAVE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function userUpdateReducer(state = {}, action) {
	switch (action.type) {
		case USER_UPDATE_REQUEST:
			return { loading: true };
		case USER_UPDATE_SUCCESS:
			return { loading: false, success: true, userInfo: action.payload };
		case USER_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function userDeleteReducer(state = { user: {} }, action) {
	switch (action.type) {
		case USER_DELETE_REQUEST:
			return { loading: true };
		case USER_DELETE_SUCCESS:
			return { loading: false, user: action.payload, success: true };
		case USER_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

// function userDetailsReducer(state = { user: { reviews: [] } }, action) {
function userDetailsReducer(state = { user: {} }, action) {
	switch (action.type) {
		case USER_DETAILS_REQUEST:
			return { loading: true };
		case USER_DETAILS_SUCCESS:
			// console.log("reducer:", action.payload);
			return { loading: false, user: action.payload };
		case USER_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function userListEventsReducer(state = { events: [] }, action) {
	switch (action.type) {
		case USER_LIST_EVENTS_REQUEST:
			return { loading: true, events: [] };
		case USER_LIST_EVENTS_SUCCESS:
			return { loading: false, events: action.payload };
		case USER_LIST_EVENTS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function userDelEventReducer(state = { event: {} }, action) {
	switch (action.type) {
		case USER_DEL_EVENT_REQUEST:
			return { loading: true };
		case USER_DEL_EVENT_SUCCESS:
			return { loading: false, user: action.payload, success: true };
		case USER_DEL_EVENT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

export {
	userRefreshReducer,
	userSigninReducer,
	userResetReducer,
	userListReducer,
	userSaveReducer,
	userUpdateReducer,
	userDeleteReducer,
	userDetailsReducer,
	userListEventsReducer,
	userDelEventReducer
};
