import {
	OPPORTUNITY_LIST_REQUEST,
	OPPORTUNITY_LIST_SUCCESS,
	OPPORTUNITY_LIST_FAIL,
	OPPORTUNITY_OWN_LIST_REQUEST,
	OPPORTUNITY_OWN_LIST_SUCCESS,
	OPPORTUNITY_OWN_LIST_FAIL,
	OPPORTUNITY_DETAILS_REQUEST,
	OPPORTUNITY_DETAILS_SUCCESS,
	OPPORTUNITY_DETAILS_FAIL,
	OPPORTUNITY_SAVE_REQUEST,
	OPPORTUNITY_SAVE_SUCCESS,
	OPPORTUNITY_SAVE_FAIL,
	OPPORTUNITY_DELETE_REQUEST,
	OPPORTUNITY_DELETE_SUCCESS,
	OPPORTUNITY_DELETE_FAIL,
	OPPORTUNITY_SIGNUP_REQUEST,
	OPPORTUNITY_SIGNUP_SUCCESS,
	OPPORTUNITY_SIGNUP_FAIL,
	OPPORTUNITY_LIST_VOLUNTEERS_REQUEST,
	OPPORTUNITY_LIST_VOLUNTEERS_SUCCESS,
	OPPORTUNITY_LIST_VOLUNTEERS_FAIL,
	OPPORTUNITY_LIST_NUM_VOLUNTEERS_REQUEST,
	OPPORTUNITY_LIST_NUM_VOLUNTEERS_SUCCESS,
	OPPORTUNITY_LIST_NUM_VOLUNTEERS_FAIL,
	OPPORTUNITY_CHECK_STATUS_REQUEST,
	OPPORTUNITY_CHECK_STATUS_SUCCESS,
	OPPORTUNITY_CHECK_STATUS_FAIL,
	OPPORTUNITY_LIST_ALL_VOLUNTEERS_FAIL,
	OPPORTUNITY_LIST_ALL_VOLUNTEERS_REQUEST,
	OPPORTUNITY_LIST_ALL_VOLUNTEERS_SUCCESS
} from "../constants/opportunityConstants";

function opportunityListReducer(state = { opportunities: [] }, action) {
	switch (action.type) {
		case OPPORTUNITY_LIST_REQUEST:
			return { loading: true, opportunities: action.shouldRefetch ? [] : state.opportunities };
		case OPPORTUNITY_LIST_SUCCESS:
			return {
				loading: false,
				opportunities: [...state.opportunities, ...action.payload]
			};
		case OPPORTUNITY_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityListOwnReducer(state = { opportunities: [] }, action) {
	switch (action.type) {
		case OPPORTUNITY_OWN_LIST_REQUEST:
			return { loading: true, opportunities: [] };
		case OPPORTUNITY_OWN_LIST_SUCCESS:
			return { loading: false, opportunities: action.payload };
		case OPPORTUNITY_OWN_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityDetailsReducer(state = { opportunity: { images: [], videos: [] } }, action) {
	switch (action.type) {
		case OPPORTUNITY_DETAILS_REQUEST:
			return { loading: true };
		case OPPORTUNITY_DETAILS_SUCCESS:
			return { loading: false, success: true, opportunity: action.payload };
		case OPPORTUNITY_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunitySignUpReducer(state = { opportunity: {} }, action) {
	switch (action.type) {
		case OPPORTUNITY_SIGNUP_REQUEST:
			return { loading: true };
		case OPPORTUNITY_SIGNUP_SUCCESS:
			return { loading: false, success: true, opportunity: action.payload };
		case OPPORTUNITY_SIGNUP_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityDeleteReducer(state = { opportunity: {} }, action) {
	switch (action.type) {
		case OPPORTUNITY_DELETE_REQUEST:
			return { loading: true };
		case OPPORTUNITY_DELETE_SUCCESS:
			return { loading: false, opportunity: action.payload, success: true };
		case OPPORTUNITY_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunitySaveReducer(state = { opportunity: {} }, action) {
	switch (action.type) {
		case OPPORTUNITY_SAVE_REQUEST:
			return { loading: true };
		case OPPORTUNITY_SAVE_SUCCESS:
			return { loading: false, success: true, opportunity: action.payload };
		case OPPORTUNITY_SAVE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityListVolunteersReducer(state = { users: [] }, action) {
	switch (action.type) {
		case OPPORTUNITY_LIST_VOLUNTEERS_REQUEST:
			return { loading: true, users: [] };
		case OPPORTUNITY_LIST_VOLUNTEERS_SUCCESS:
			return { loading: false, users: action.payload };
		case OPPORTUNITY_LIST_VOLUNTEERS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityListNumVolunteersReducer(state = { numUsers: 0 }, action) {
	switch (action.type) {
		case OPPORTUNITY_LIST_NUM_VOLUNTEERS_REQUEST:
			return { loading: true, numUsers: 0 };
		case OPPORTUNITY_LIST_NUM_VOLUNTEERS_SUCCESS:
			return { loading: false, success: true, numUsers: action.payload };
		case OPPORTUNITY_LIST_NUM_VOLUNTEERS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityCheckStatusReducer(state = { opportunity: {} }, action) {
	switch (action.type) {
		case OPPORTUNITY_CHECK_STATUS_REQUEST:
			return { loading: true };
		case OPPORTUNITY_CHECK_STATUS_SUCCESS:
			return { loading: false, success: true, status: action.payload };
		case OPPORTUNITY_CHECK_STATUS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function opportunityListAllVolunteersReducer(state = { allUsers: [] }, action) {
	switch (action.type) {
		case OPPORTUNITY_LIST_ALL_VOLUNTEERS_REQUEST:
			return { loading: true };
		case OPPORTUNITY_LIST_ALL_VOLUNTEERS_SUCCESS:
			return { loading: false, allUsers: action.payload };
		case OPPORTUNITY_CHECK_STATUS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}
export {
	opportunityListReducer,
	opportunityListOwnReducer,
	opportunityDetailsReducer,
	opportunitySaveReducer,
	opportunityDeleteReducer,
	opportunitySignUpReducer,
	opportunityListVolunteersReducer,
	opportunityListNumVolunteersReducer,
	opportunityCheckStatusReducer,
	opportunityListAllVolunteersReducer
};
