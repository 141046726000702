import PropTypes from "prop-types";
import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

function SocialBlock(props) {
	const { social, required, setSocial, showTitle } = props;
	const { isPerson } = useSelector(state => state.userSignin.userInfo);
	return (
		<>
			{showTitle && <h5>Social</h5>}
			<Form.Row className="block">
				<Form.Group as={Col} xs={12} lg={4} controlId="twitter">
					<Form.Label>Twitter</Form.Label>
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text id="twitter-prepend">@</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							required={required}
							type="text"
							placeholder="Twitter Handle"
							aria-describedby="twitter-prepend"
							pattern="[0-9a-zA-Z_]{4,15}"
							value={social.twitter || ""}
							onChange={e => setSocial({ ...social, twitter: e.target.value })}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="instagram">
					<Form.Label>Instagram</Form.Label>
					<Form.Control
						required={required}
						type="text"
						placeholder="Instagram Username"
						pattern="^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$"
						value={social.instagram || ""}
						onChange={e => setSocial({ ...social, instagram: e.target.value })}
					/>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="facebook">
					<Form.Label>Facebook</Form.Label>
					<Form.Control
						required={required}
						type="text"
						placeholder="Facebook Username"
						aria-describedby="facebook-prepend"
						pattern="^[\w](?!.*?\.{2})[\w.]{3,48}[\w]$"
						value={social.facebook || ""}
						onChange={e => setSocial({ ...social, facebook: e.target.value })}
					/>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="tiktok">
					<Form.Label>TikTok</Form.Label>
					<Form.Control
						required={required}
						type="text"
						placeholder="TikTok Profile"
						aria-describedby="tiktok-prepend"
						pattern="[0-9a-zA-Z_]{4,15}"
						value={social.tiktok || ""}
						onChange={e => setSocial({ ...social, tiktok: e.target.value })}
					/>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="linkedin">
					<Form.Label>Linkedin</Form.Label>
					<Form.Control
						required={required}
						type="text"
						placeholder="linkedin.com/in/profile/"
						aria-describedby="linkedin-prepend"
						pattern="[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
						value={social.linkedin || ""}
						onChange={e => setSocial({ ...social, linkedin: e.target.value })}
					/>
				</Form.Group>

				<Form.Group as={Col} xs={12} lg={4} controlId="portfolio">
					<Form.Label>{isPerson ? "Portfolio" : "Website"}</Form.Label>
					<Form.Control
						required={required}
						type="text"
						placeholder="yourwebsite.com"
						aria-describedby="website-prepend"
						value={social.portfolio || ""}
						onChange={e => setSocial({ ...social, portfolio: e.target.value })}
					/>
				</Form.Group>
			</Form.Row>
		</>
	);
}

SocialBlock.propTypes = {
	social: PropTypes.object.isRequired,
	required: PropTypes.bool,
	setSocial: PropTypes.func.isRequired,
	showTitle: PropTypes.bool
};

SocialBlock.defaultProps = {
	required: false,
	showTitle: true
};

export default SocialBlock;
