import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import "../assets/css/Cards.css";
import "../assets/css/home.css";
import "../assets/scripts/vticker";
import styled from "styled-components";
import HeroVideo from "../components/home/HeroVideo";
import TopRewards from "../components/home/TopRewards";
import LatestGigs from "../components/home/LatestGigs";
import HowItWorks from "../components/home/HowItWorks";
import ObtainNewSkills from "../components/home/ObtainNewSkills";
import Partners from "../components/home/Partners";
import EmailSection from "../components/home/EmailSection";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Cart from "../assets/images/cart.svg";
import PC from "../assets/images/PC.svg";
import Money from "../assets/images/money symbol 5.svg";
import CreditCard from "../assets/images/money.svg";
import Post from "../assets/images/post.svg";
import Human from "../assets/images/human.svg";
import SAPHero from "../components/home/SAPHero";

const HomeContainer = styled.div`
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TimeCurrencyTagLine = styled.p`
	font-family: Inter;
	font-style: italic;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.3vw" : "2.2vw")}, 32px);
	margin: 0;
`;

const Spacer = styled.div`
	margin: 0;
	padding-bottom: 0;
	padding-top: ${props => (props.$windowWidth < 500 ? "10vw" : "min(5vw, 100px)")};
	width: 100%;
`;

const talentsCards = [
	{
		id: 1,
		iconSrc: PC,
		alt: "PC",
		header: "COMPLETE GIGS",
		body: "Find Gigs That Match Your Skil Set",
		buttonLabel: "FIND GIGS",
		link: "/opportunities"
	},
	{
		id: 2,
		iconSrc: Post,
		alt: "Post",
		header: "LEARN SKILLS",
		body: "Missing Required Skills? Learn Immediately Before Applying",
		buttonLabel: "LEARN NOW",
		link: "/opportunities"
	},
	{
		id: 3,
		iconSrc: Money,
		alt: "Money",
		header: "EARN",
		body: "Choose How You're Paid. Cash, Rewards, & More",
		buttonLabel: "LEARN MORE",
		link: "/incentives"
	},
	{
		id: 4,
		iconSrc: Cart,
		alt: "Cart",
		header: "REDEEM",
		body: "Get Paid in Cash or Exchange Points for Trending Rewards",
		buttonLabel: "SHOP NOW",
		link: "/incentives"
	}
];

const customersCards = [
	{
		id: 1,
		iconSrc: PC,
		alt: "PC",
		header: "SIGN UP",
		body: "Register & Verify Your Company/ Organization",
		buttonLabel: "LEARN MORE",
		link: "/how-it-works-organization"
	},
	{
		id: 2,
		iconSrc: CreditCard,
		alt: "Credit Card",
		header: "PAYMENT METHOD",
		body: "Pay Talent in Cash or Points",
		buttonLabel: "LEARN MORE",
		link: "/how-it-works-organization"
	},
	{
		id: 3,
		iconSrc: Human,
		alt: "Human",
		header: "HIRE",
		body: "Select Talent. Get On-demand Support",
		buttonLabel: "LEARN MORE",
		link: "/how-it-works-organization"
	}
];

function HomeScreen() {
	const [forTalent, setForTalent] = useState(true);
	const [cards, setCards] = useState(talentsCards);
	const theme = useSelector(state => state.theme.theme);
	const { windowWidth } = useWindowDimensions();

	const howItWorksRef = useRef(null);
	const obtainNewSkillsRef = useRef(null);

	return (
		<HomeContainer>
			<Helmet>
				<title>Vower</title>
			</Helmet>
			<HeroVideo
				howItWorksRef={howItWorksRef}
				obtainNewSkillsRef={obtainNewSkillsRef}
				forTalent={forTalent}
				setForTalent={setForTalent}
				cards={cards}
				setCards={setCards}
				talentsCards={talentsCards}
				customersCards={customersCards}
			/>
			<Spacer $windowWidth={windowWidth} />
			<SAPHero />
			<Spacer $windowWidth={windowWidth} />
			{windowWidth < 500 ? (
				<>
					<TimeCurrencyTagLine $windowWidth={windowWidth}>Time is the new currency.</TimeCurrencyTagLine>
					<TimeCurrencyTagLine $windowWidth={windowWidth}>
						Get
						<span className="vower-gold"> Rewarded </span>
						for Learning the Skills Companies Need
					</TimeCurrencyTagLine>
				</>
			) : (
				<TimeCurrencyTagLine $windowWidth={windowWidth}>
					Time is the new currency. Get
					<span className="vower-gold"> Rewarded </span>
					for Learning the Skills Companies Need
				</TimeCurrencyTagLine>
			)}
			<Spacer $windowWidth={windowWidth} />
			<TopRewards />
			<Spacer $windowWidth={windowWidth} />
			<LatestGigs />
			<Spacer $windowWidth={windowWidth} />
			<HowItWorks
				howItWorksRef={howItWorksRef}
				forTalent={forTalent}
				setForTalent={setForTalent}
				cards={cards}
				setCards={setCards}
				talentsCards={talentsCards}
				customersCards={customersCards}
			/>
			<Spacer $windowWidth={windowWidth} />
			<Spacer $windowWidth={windowWidth} />
			<ObtainNewSkills obtainNewSkillsRef={obtainNewSkillsRef} />
			<Spacer $windowWidth={windowWidth} />
			<Partners />
			<Spacer $windowWidth={windowWidth} />
			<EmailSection />
		</HomeContainer>
	);
}
export default HomeScreen;
