import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import Cookie from "js-cookie";
import { cartReducer } from "./reducers/cartReducers";
import {
	orderCreateReducer,
	orderDetailsReducer,
	orderEditReducer,
	myOrderListReducer,
	orderListReducer,
	orderDeleteReducer
} from "./reducers/orderReducers";
import {
	opportunityListReducer,
	opportunityListOwnReducer,
	opportunityDetailsReducer,
	opportunityDeleteReducer,
	opportunitySaveReducer,
	opportunitySignUpReducer,
	opportunityListVolunteersReducer,
	opportunityListNumVolunteersReducer,
	opportunityCheckStatusReducer,
	opportunityListAllVolunteersReducer
} from "./reducers/opportunityReducers";
import {
	incentiveListReducer,
	incentiveDetailsReducer,
	incentiveDeleteReducer,
	incentiveSaveReducer
} from "./reducers/incentiveReducers";
import {
	userSigninReducer,
	userUpdateReducer,
	userListReducer,
	userDetailsReducer,
	userDeleteReducer,
	userSaveReducer,
	userResetReducer,
	userListEventsReducer,
	userDelEventReducer,
	userRefreshReducer
} from "./reducers/userReducers";
import { categoryListReducer, categorySaveReducer, categoryDeleteReducer } from "./reducers/categoryReducers";
import themeReducer from "./reducers/themeReducer";

const cartItems = Cookie.getJSON("cartItems") || [];
const shipping = Cookie.getJSON("shipping") || {};
const userInfo = Cookie.getJSON("userInfo") || null;

let totalCost = 0;
cartItems.forEach(item => {
	totalCost += item.points * item.quantity;
});

const initialState = {
	cart: { cartItems, shipping, totalCost, addressSelection: "new" },
	userSignin: { userInfo }
};
const reducer = combineReducers({
	opportunityList: opportunityListReducer,
	opportunityListOwn: opportunityListOwnReducer,
	opportunityDetails: opportunityDetailsReducer,
	opportunitySave: opportunitySaveReducer,
	opportunityDelete: opportunityDeleteReducer,
	opportunitySignUp: opportunitySignUpReducer,
	opportunityListVolunteers: opportunityListVolunteersReducer,
	opportunityListNumVolunteers: opportunityListNumVolunteersReducer,
	opportunityCheckStatus: opportunityCheckStatusReducer,
	opportunityListAllVolunteers: opportunityListAllVolunteersReducer,

	incentiveList: incentiveListReducer,
	incentiveDetails: incentiveDetailsReducer,
	incentiveSave: incentiveSaveReducer,
	incentiveDelete: incentiveDeleteReducer,

	userRefresh: userRefreshReducer,
	userSignin: userSigninReducer,
	userReset: userResetReducer,
	userList: userListReducer,
	userDetails: userDetailsReducer,
	userSave: userSaveReducer,
	userDelete: userDeleteReducer,
	userListEvents: userListEventsReducer,
	userDelEvent: userDelEventReducer,
	userUpdate: userUpdateReducer,

	categoryList: categoryListReducer,
	categorySave: categorySaveReducer,
	categoryDelete: categoryDeleteReducer,

	cart: cartReducer,

	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderEdit: orderEditReducer,
	myOrderList: myOrderListReducer,
	orderList: orderListReducer,
	orderDelete: orderDeleteReducer,

	theme: themeReducer
});

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
export default store;
