import axios from "axios";
import {
	INCENTIVE_LIST_REQUEST,
	INCENTIVE_LIST_SUCCESS,
	INCENTIVE_LIST_FAIL,
	INCENTIVE_SAVE_REQUEST,
	INCENTIVE_SAVE_SUCCESS,
	INCENTIVE_SAVE_FAIL,
	INCENTIVE_DELETE_REQUEST,
	INCENTIVE_DELETE_SUCCESS,
	INCENTIVE_DELETE_FAIL,
	INCENTIVE_DETAILS_REQUEST,
	INCENTIVE_DETAILS_SUCCESS,
	INCENTIVE_DETAILS_FAIL
} from "../constants/incentiveConstants";
import { storage } from "../firebase";

// get the list of incentives
const listIncentives = (params, shouldRefetch = true) => async dispatch => {
	try {
		dispatch({ type: INCENTIVE_LIST_REQUEST, shouldRefetch });
		const queryParams = new URLSearchParams(params).toString();
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/incentives?${queryParams}`);
		dispatch({ type: INCENTIVE_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: INCENTIVE_LIST_FAIL, payload: error.message });
	}
};

// save incentive info to firebase
const saveIncentive = incentive => async (dispatch, getState) => {
	try {
		dispatch({ type: INCENTIVE_SAVE_REQUEST, payload: incentive });
		const {
			userSignin: { userInfo }
		} = getState();
		if (!incentive._id) {
			const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/incentives`, incentive, {
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			});
			dispatch({ type: INCENTIVE_SAVE_SUCCESS, payload: data });
		} else {
			const { data } = await axios.put(
				`${process.env.REACT_APP_BACKEND_URL}/incentives/${incentive._id}`,
				incentive,
				{
					headers: {
						Authorization: `Bearer ${userInfo.token}`
					}
				}
			);
			dispatch({ type: INCENTIVE_SAVE_SUCCESS, payload: data });
		}
	} catch (error) {
		dispatch({ type: INCENTIVE_SAVE_FAIL, payload: error.message });
	}
};

// delete an incentive from firebase
const deleteIncentive = incentive => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		// if not admin, cannot delete
		if (!userInfo.isAdmin) {
			dispatch({ type: INCENTIVE_DELETE_FAIL, payload: "Not authorized to Delete!" });
			return;
		}

		dispatch({ type: INCENTIVE_DELETE_REQUEST, payload: incentive });
		const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/incentives/${incentive._id}`, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});

		// also delete associated images from firebase storage
		if (incentive.images_paths) {
			for (let i = 0; i < incentive.images_paths.length; i++) {
				// console.log(opportunity.images_paths[i]);
				const del_result = await storage.ref(incentive.images_paths[i]).delete();
			}
		}

		dispatch({ type: INCENTIVE_DELETE_SUCCESS, payload: data, success: true });
	} catch (error) {
		dispatch({ type: INCENTIVE_DELETE_FAIL, payload: error.message });
	}
};

// get the details info of an incentive
const detailsIncentive = incentiveId => async dispatch => {
	try {
		dispatch({ type: INCENTIVE_DETAILS_REQUEST, payload: incentiveId });
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/incentives/${incentiveId}`);
		dispatch({ type: INCENTIVE_DETAILS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: INCENTIVE_DETAILS_FAIL, payload: error.message });
	}
};

export { listIncentives, saveIncentive, deleteIncentive, detailsIncentive };
