import axios from "axios";
import {
	CATEGORY_LIST_REQUEST,
	CATEGORY_LIST_SUCCESS,
	CATEGORY_LIST_FAIL,
	CATEGORY_SAVE_REQUEST,
	CATEGORY_SAVE_SUCCESS,
	CATEGORY_SAVE_FAIL,
	CATEGORY_DELETE_REQUEST,
	CATEGORY_DELETE_SUCCESS,
	CATEGORY_DELETE_FAIL
} from "../constants/categoryConstants";

// get the list of categoies, including name and type
const listCategories =
	(category_type = "") =>
		async dispatch => {
			try {
				dispatch({ type: CATEGORY_LIST_REQUEST });
				const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/categories/${category_type}`);
				dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
			} catch (error) {
				dispatch({ type: CATEGORY_LIST_FAIL, payload: error.message });
			}
		};

// save category infor to firebase
const saveCategory = category => async (dispatch, getState) => {
	try {
		dispatch({ type: CATEGORY_SAVE_REQUEST, payload: category });
		const {
			userSignin: { userInfo }
		} = getState();

		// console.log(category);
		const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/categories`, category, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		dispatch({ type: CATEGORY_SAVE_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CATEGORY_SAVE_FAIL, payload: error.message });
	}
};

// remove a category from firebase
const deleteCategory = category => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		// if not admin, cannot delete
		if (!userInfo.isAdmin) {
			dispatch({ type: CATEGORY_DELETE_FAIL, payload: "Not autherized to Delete!" });
			return;
		}

		dispatch({ type: CATEGORY_DELETE_REQUEST, payload: category });
		// console.log(category);
		const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/categories/${category._id}`, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		// console.log(data);
		dispatch({ type: CATEGORY_DELETE_SUCCESS, payload: data, success: true });
	} catch (error) {
		dispatch({ type: CATEGORY_DELETE_FAIL, payload: error.message });
	}
};

export { listCategories, saveCategory, deleteCategory };
