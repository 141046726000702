import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";

function ManageCodesScreen() {
	const { userInfo } = useSelector(state => state.userSignin);

	const [codes, setCodes] = useState({});
	const [numToAdd, setNumToAdd] = useState(1);
	const [enabled, setEnabled] = useState(false);

	const getCodes = async () => {
		const {
			data: { codes }
		} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/misc/codes`);
		if (codes) setCodes(codes);
	};

	const addCodes = async () => {
		if (numToAdd < 1) return;
		setEnabled(false);
		await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/misc/add-codes`,
			{ count: numToAdd },
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		getCodes();
	};

	useEffect(() => {
		getCodes();
	}, []);

	const unusedCodes = Object.entries(codes)
		.filter(code => code[1] === false)
		.map(code => code[0]);
	const allottedCodes = Object.entries(codes)
		.filter(code => code[1] === true)
		.map(code => code[0]);
	const usedCodes = Object.entries(codes)
		.filter(code => typeof code[1] === "string")
		.map(code => ({ code: code[0], userID: code[1] }));

	const copyCode = async code => {
		navigator.clipboard.writeText(code);
		await axios.post(`${process.env.REACT_APP_BACKEND_URL}/misc/code-status`, {
			code,
			status: true
		});
		getCodes();
	};

	return (
		<>
			{!userInfo.isAdmin && <Navigate to="/" />}
			<Helmet>
				<title>Vower | Manage Codes</title>
			</Helmet>
			<div>
				<div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
					<h3>Manage Codes</h3>
					<div className="d-flex align-items-center">
						<Form.Group controlId="num-to-add" className="d-flex align-items-center mb-0 mr-3">
							<Form.Label style={{ whiteSpace: "nowrap", marginBottom: 0, marginRight: "0.5rem" }}>
								# to add:
							</Form.Label>
							<Form.Control
								type="number"
								min={1}
								value={numToAdd}
								onChange={e => setNumToAdd(e.target.value)}
								style={{ width: 75 }}
							/>
						</Form.Group>
						<Form.Switch
							id="enable-add"
							className="mr-2"
							checked={enabled}
							onChange={e => setEnabled(e.target.checked)}
						/>
						<Button variant="main" onClick={addCodes} disabled={!enabled}>
							Add Codes
						</Button>
					</div>
				</div>
				<h4>Unused Codes</h4>
				<Table responsive>
					<thead>
						<tr>
							<th>Code</th>
							<th>Copy</th>
						</tr>
					</thead>
					<tbody>
						{unusedCodes.map(code => (
							<tr key={code}>
								<td>{code}</td>
								<td>
									<Button variant="main" onClick={() => copyCode(code)} className="py-1 px-2">
										<Clipboard size="1.75rem" />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<h4 className="mt-3">Allotted Codes</h4>
				<Table responsive>
					<thead>
						<tr>
							<th>Code</th>
						</tr>
					</thead>
					<tbody>
						{allottedCodes.map(code => (
							<tr key={code}>
								<td>{code}</td>
							</tr>
						))}
					</tbody>
				</Table>
				<h4 className="mt-3">Used Codes</h4>
				<Table responsive>
					<thead>
						<tr>
							<th>Code</th>
							<th>User ID</th>
						</tr>
					</thead>
					<tbody>
						{usedCodes.map(code => (
							<tr key={code.code}>
								<td>{code.code}</td>
								<td>
									<Link to={`/profile/${code.userID}`}>{code.userID}</Link>{" "}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</>
	);
}

export default ManageCodesScreen;
