import React from "react";
import styled from "styled-components";
import RewardsCarousel from "./RewardsCarousel";
import { Link } from "react-router-dom";
import GigsCarousel from "./GigsCarousel";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Container = styled.div`
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${props => (props.$windowWidth < 500 ? "90vw" : "min(1200px, 82vw)")};
`;

const GigsTitle = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "5vw" : "2.5vw")}, 40px);
`;

const Description = styled.p`
	font-family: Inter;
	font-weight: 400;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.1vw" : "1.6vw")}, 22px);
	width: min(1200px, 82vw);
	text-align: center;
`;

const CarouselContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const CarouselLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: min(${props => (props.$windowWidth < 500 ? "6vw" : "6vw")}, 60px);
	margin-right: min(${props => (props.$windowWidth < 500 ? "6vw" : "6vw")}, 60px);
`;

const GigsLabel = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.4vw" : "1.8vw")}, 28px);
	padding: 0;
	margin: 0;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const ViewAllLabel = styled(Link)`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "2.4vw" : "1.3vw")}, 17px);
	text-decoration: underline;
`;

export default function LatestGigs() {
	const { windowWidth } = useWindowDimensions();
	return (
		<Container $windowWidth={windowWidth}>
			<GigsTitle $windowWidth={windowWidth}>FIND GIGS</GigsTitle>
			<Description $windowWidth={windowWidth}>
				Find Gigs That Match Your Skillset. Build your Resume In Real Time.
			</Description>
			<CarouselContainer>
				<CarouselLabelContainer $windowWidth={windowWidth}>
					<GigsLabel $windowWidth={windowWidth}>Latest Gigs</GigsLabel>
					<ViewAllLabel $windowWidth={windowWidth} to="/opportunities" style={{ color: "#0fafb0" }}>
						VIEW ALL
					</ViewAllLabel>
				</CarouselLabelContainer>
				<GigsCarousel />
			</CarouselContainer>
		</Container>
	);
}
