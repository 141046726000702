import axios from "axios";
import emailjs from "emailjs-com";
import {
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_SUCCESS,
	ORDER_CREATE_FAIL,
	ORDER_DETAILS_REQUEST,
	ORDER_DETAILS_SUCCESS,
	ORDER_DETAILS_FAIL,
	MY_ORDER_LIST_REQUEST,
	MY_ORDER_LIST_SUCCESS,
	MY_ORDER_LIST_FAIL,
	ORDER_DELETE_REQUEST,
	ORDER_DELETE_SUCCESS,
	ORDER_DELETE_FAIL,
	ORDER_EDIT_REQUEST,
	ORDER_EDIT_SUCCESS,
	ORDER_EDIT_FAIL,
	ORDER_LIST_REQUEST,
	ORDER_LIST_SUCCESS,
	ORDER_LIST_FAIL
} from "../constants/orderConstants";
import { clearCart } from "./cartActions";

const createOrder = orderInfo => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_CREATE_REQUEST });
		const {
			userSignin: { userInfo }
		} = getState();
		const order = {
			total_cost: orderInfo.order.totalCost,
			mailing_address: orderInfo.order.shipping,
			order_items: orderInfo.order.cartItems.map(item => ({ incentive_id: item._id, quantity: item.quantity }))
		};
		const { data: newOrder } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders`, order, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		let orderDetails = "";
		orderInfo.order.cartItems.forEach((item, i) => {
			orderDetails += `${item.name}: ${item.quantity}`;
			if (i < newOrder.order_items.length - 1) {
				orderDetails += ", ";
			}
		});
		emailjs.send("Gmail", "incentive_redeem_confirm", {
			name: userInfo.name,
			email: userInfo.email,
			line1: orderInfo.order.shipping.line1,
			line2: orderInfo.order.shipping.line2,
			city: orderInfo.order.shipping.city,
			state: orderInfo.order.shipping.state,
			zip: orderInfo.order.shipping.zip,
			orderDetails
		});
		dispatch({ type: ORDER_CREATE_SUCCESS, payload: newOrder });
		dispatch(clearCart());
	} catch (error) {
		dispatch({ type: ORDER_CREATE_FAIL, payload: error.response.data });
	}
};

const listOrders = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_LIST_REQUEST });
		const {
			userSignin: { userInfo }
		} = getState();
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders`, {
			headers: { Authorization: `Bearer ${userInfo.token}` }
		});
		dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: ORDER_LIST_FAIL, payload: error.response.data.message });
	}
};

const detailsOrder = orderId => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_DETAILS_REQUEST });
		const {
			userSignin: { userInfo }
		} = getState();
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` }
		});
		dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: ORDER_DETAILS_FAIL, payload: error.response.data.message });
	}
};

const deleteOrder = order => async (dispatch, getState) => {
	const { _id, user_id } = order;
	try {
		dispatch({ type: ORDER_DELETE_REQUEST });
		const {
			userSignin: { userInfo }
		} = getState();
		const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/orders/${_id}/${user_id}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` }
		});
		dispatch({ type: ORDER_DELETE_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: ORDER_DELETE_FAIL, payload: error.response });
	}
};

const editOrder = order => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_EDIT_REQUEST });
		const {
			userSignin: { userInfo }
		} = getState();
		const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/orders/${order._id}`, order, {
			headers: { Authorization: `Bearer ${userInfo.token}` }
		});
		dispatch({ type: ORDER_EDIT_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: ORDER_EDIT_FAIL, payload: error.response.data.message });
	}
};

export { createOrder, detailsOrder, listOrders, deleteOrder, editOrder };
