import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import HowItWorksCarousel from "./HowItWorksCarousel";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useSelector } from "react-redux";

const Container = styled.div`
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Spacer = styled.div`
	margin: 0;
	padding-top: 0;
	padding-bottom: min(1.3vw, 40px);
	width: 100%;
`;

const VowerLogo = styled.img`
	width: min(${props => (props.$windowWidth < 500 ? "14vw" : "7vw")}, 90px);
`;

const Header = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "5vw" : "2.5vw")}, 40px);
`;

const Description = styled.p`
	font-family: Inter;
	font-weight: 400;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.1vw" : "1.6vw")}, 22px);
	text-align: center;
`;

const ToggleFG = styled(motion.div)`
	width: min(${props => (props.$windowWidth < 500 ? "31vw" : "16vw")}, 236px);
	height: min(${props => (props.$windowWidth < 500 ? "6.9vw" : "3.5vw")}, 52px);
	margin-top: -1px;
	background: linear-gradient(180deg, #0fafb0 0%, #1d7a7a 100%);
	border: 1px solid #25bebd;
	border-radius: min(${props => (props.$windowWidth < 500 ? "5vw" : "0.7vw")}, 10px);
`;

const ToggleBG = styled(motion.a)`
	width: min(${props => (props.$windowWidth < 500 ? "61vw" : "32vw")}, 471px);
	height: min(${props => (props.$windowWidth < 500 ? "6.9vw" : "3.5vw")}, 52px);
	border: 1px solid #25bebd;
	display: flex;
	border-radius: min(${props => (props.$windowWidth < 500 ? "5vw" : "0.7vw")}, 10px);
`;

const TextContainer = styled.div`
	width: min(${props => (props.$windowWidth < 500 ? "61vw" : "32vw")}, 471px);
	height: min(${props => (props.$windowWidth < 500 ? "6.9vw" : "3.5vw")}, 52px);
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
`;

const ToggleText = styled.p`
	font-family: Inter;
	font-weight: 600;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.1vw" : "1.6vw")}, 22px);
	width: min(${props => (props.$windowWidth < 500 ? "61vw" : "32vw")}, 471px);
	text-align: center;
	padding: 0;
	margin: 0;
	user-select: none;
	color: ${props => (props.isHighlight && props.theme == "light" ? "#1D7A7A" : "#FFFFFF")};
`;

function HowItWorks(props) {
	const { cards, setCards, talentsCards, customersCards, howItWorksRef, forTalent, setForTalent } = props;
	const { windowWidth } = useWindowDimensions();
	const theme = useSelector(state => state.theme.theme);

	useLayoutEffect(() => {
		// pre-load all images
		talentsCards.forEach(card => {
			const img = new Image();
			img.src = card.iconSrc;
		});
		customersCards.forEach(card => {
			const img = new Image();
			img.src = card.iconSrc;
		});
	}, []);

	const togglePress = () => {
		setForTalent(forTalent => !forTalent);
		setCards(cards => (cards == talentsCards ? customersCards : talentsCards));
	};

	return (
		<Container ref={howItWorksRef}>
			<VowerLogo $windowWidth={windowWidth} src={window.images.vowerLogoPNG} />
			<Spacer />
			<Header $windowWidth={windowWidth}>HOW VOWER WORKS</Header>
			<Description $windowWidth={windowWidth}>Do it. Earn it. Buy it.</Description>
			<Spacer />
			<ToggleBG $windowWidth={windowWidth} onClick={togglePress}>
				<TextContainer $windowWidth={windowWidth}>
					<ToggleText $windowWidth={windowWidth} theme={theme} isHighlight={!forTalent}>
						For Talents
					</ToggleText>
					<ToggleText $windowWidth={windowWidth} theme={theme} isHighlight={forTalent}>
						For Customers
					</ToggleText>
				</TextContainer>
				<ToggleFG
					$windowWidth={windowWidth}
					animate={{ marginLeft: `${forTalent ? 0 : 50}%` }}
					transition={{
						ease: "ease",
						duration: 0.2
					}}
				/>
			</ToggleBG>
			<HowItWorksCarousel cards={cards} />
		</Container>
	);
}
export default HowItWorks;
