import axios from "axios";
import emailjs from "emailjs-com";
import {
	OPPORTUNITY_CHECK_STATUS_FAIL,
	OPPORTUNITY_CHECK_STATUS_REQUEST,
	OPPORTUNITY_CHECK_STATUS_SUCCESS,
	OPPORTUNITY_DELETE_FAIL,
	OPPORTUNITY_DELETE_REQUEST,
	OPPORTUNITY_DELETE_SUCCESS,
	OPPORTUNITY_DETAILS_FAIL,
	OPPORTUNITY_DETAILS_REQUEST,
	OPPORTUNITY_DETAILS_SUCCESS,
	OPPORTUNITY_LIST_FAIL,
	OPPORTUNITY_LIST_REQUEST,
	OPPORTUNITY_LIST_SUCCESS,
	OPPORTUNITY_LIST_VOLUNTEERS_FAIL,
	OPPORTUNITY_LIST_VOLUNTEERS_REQUEST,
	OPPORTUNITY_LIST_VOLUNTEERS_SUCCESS,
	OPPORTUNITY_LIST_NUM_VOLUNTEERS_FAIL,
	OPPORTUNITY_LIST_NUM_VOLUNTEERS_REQUEST,
	OPPORTUNITY_LIST_NUM_VOLUNTEERS_SUCCESS,
	OPPORTUNITY_OWN_LIST_FAIL,
	OPPORTUNITY_OWN_LIST_REQUEST,
	OPPORTUNITY_OWN_LIST_SUCCESS,
	OPPORTUNITY_SAVE_FAIL,
	OPPORTUNITY_SAVE_REQUEST,
	OPPORTUNITY_SAVE_SUCCESS,
	OPPORTUNITY_SIGNUP_FAIL,
	OPPORTUNITY_SIGNUP_REQUEST,
	OPPORTUNITY_SIGNUP_SUCCESS,
	OPPORTUNITY_LIST_ALL_VOLUNTEERS_REQUEST,
	OPPORTUNITY_LIST_ALL_VOLUNTEERS_SUCCESS,
	OPPORTUNITY_LIST_ALL_VOLUNTEERS_FAIL
} from "../constants/opportunityConstants";
import { storage } from "../firebase";

// list all opportunities
const listOpportunities = (params, shouldRefetch = true) => async dispatch => {
	try {
		dispatch({ type: OPPORTUNITY_LIST_REQUEST, shouldRefetch });
		const queryParams = new URLSearchParams(params).toString();
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/opportunities?${queryParams}`);
		dispatch({ type: OPPORTUNITY_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_LIST_FAIL, payload: error.message });
	}
};

// list the opportunities for the current user -- only for organization user
const listOwnOpportunities = () => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({ type: OPPORTUNITY_OWN_LIST_REQUEST });
		const { data } = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/opportunities/listownopportunities/${userInfo._id}`,
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		dispatch({ type: OPPORTUNITY_OWN_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_OWN_LIST_FAIL, payload: error.message });
	}
};

// save opportunity info to firebase
const saveOpportunity = opportunity => async (dispatch, getState) => {
	try {
		dispatch({ type: OPPORTUNITY_SAVE_REQUEST, payload: opportunity });
		const {
			userSignin: { userInfo }
		} = getState();

		if (!opportunity._id) {
			const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/opportunities`, opportunity, {
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			});
			if (userInfo.isOrganization) {
				emailjs.send("Gmail", "gig-posted", {
					name: opportunity.name,
					email: userInfo.email,
					company_name: opportunity.company_name,
					line1: opportunity.address?.line1,
					line2: opportunity.address?.line2,
					city: opportunity.address?.city,
					state: opportunity.address?.state,
					zip: opportunity.address?.zip,
					start_time: new Date(opportunity.start_date_time).toString(),
					end_time: new Date(opportunity.end_date_time).toString(),
					points: opportunity.points,
					total_spaces: opportunity.total_spaces
				});
			}
			console.log(data);
			dispatch({ type: OPPORTUNITY_SAVE_SUCCESS, payload: data });
		} else {
			// if it is not organization's own post
			if (userInfo.isOrganization && userInfo._id != opportunity.userId) {
				dispatch({ type: OPPORTUNITY_SAVE_FAIL, payload: "Not authorized to edit opportunity" });
			}

			const { data } = await axios.put(
				`${process.env.REACT_APP_BACKEND_URL}/opportunities/${opportunity._id}`,
				opportunity,
				{
					headers: {
						Authorization: `Bearer ${userInfo.token}`
					}
				}
			);
			dispatch({ type: OPPORTUNITY_SAVE_SUCCESS, payload: data });
		}
	} catch (error) {
		dispatch({ type: OPPORTUNITY_SAVE_FAIL, payload: error.response.data });
	}
};

// delete an opportunity from firebase
const deleteOpportunity = opportunity => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();
		dispatch({ type: OPPORTUNITY_DELETE_REQUEST, payload: opportunity });

		// if not admin, or if not organization, cannot delete
		// for organization, will check if is own post in router
		if (!userInfo.isAdmin && !userInfo.isOrganization) {
			dispatch({ type: OPPORTUNITY_SAVE_FAIL, payload: "Not authorized to Delete!" });
			return;
		}

		const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/opportunities/${opportunity._id}`, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});

		// also delete associated images and videos from firebase storage
		if (opportunity.images_paths) {
			for (let i = 0; i < opportunity.images_paths.length; i++) {
				// console.log(opportunity.images_paths[i]);
				const del_result = await storage.ref(opportunity.images_paths[i]).delete();
			}
		}

		if (opportunity.videos_paths) {
			for (let i = 0; i < opportunity.videos_paths.length; i++) {
				// console.log(opportunity.videos_paths[i]);
				const del_result = await storage.ref(opportunity.videos_paths[i]).delete();
			}
		}

		dispatch({ type: OPPORTUNITY_DELETE_SUCCESS, payload: data, success: true });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_DELETE_FAIL, payload: error.message });
	}
};

// get the details info of an opportunity
const detailsOpportunity = opportunityId => async dispatch => {
	try {
		dispatch({ type: OPPORTUNITY_DETAILS_REQUEST, payload: opportunityId });
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/opportunities/${opportunityId}`);
		dispatch({ type: OPPORTUNITY_DETAILS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_DETAILS_FAIL, payload: error.message });
	}
};

// when user clicks "sign up" button for an event, add this event to the user's list,
// also add the user to the opportunity's list
const signUpForOpportunity = (opportunityId, userId, opportunity) => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({
			type: OPPORTUNITY_SIGNUP_REQUEST,
			payload: { opportunityId, userId }
		});
		const { data } = await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/opportunities/signup/`,
			{ opportunityId, userId, opportunity },
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		dispatch({ type: OPPORTUNITY_SIGNUP_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_SIGNUP_FAIL, payload: error.message });
	}
};

// get the list of volunteers who have signed up for the current opportunity
const listOpportunityVolunteers = opportunityId => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({ type: OPPORTUNITY_LIST_VOLUNTEERS_REQUEST, payload: opportunityId });
		const { data } = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/opportunities/listvolunteers/${opportunityId}`,
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		dispatch({ type: OPPORTUNITY_LIST_VOLUNTEERS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_LIST_VOLUNTEERS_FAIL, payload: error.message });
	}
};

// get the list of volunteers who have signed up for the current opportunity
const listNumOpportunityVolunteers = opportunityId => async (dispatch, getState) => {
	try {
		dispatch({ type: OPPORTUNITY_LIST_NUM_VOLUNTEERS_REQUEST, payload: opportunityId });
		const { data } = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/opportunities/listnumvolunteers/${opportunityId}`
		);
		dispatch({ type: OPPORTUNITY_LIST_NUM_VOLUNTEERS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_LIST_NUM_VOLUNTEERS_FAIL, payload: error.message });
	}
};

// check if userId has signed up for opportunityId
const checkStatus = (opportunityId, userId) => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({
			type: OPPORTUNITY_CHECK_STATUS_REQUEST,
			payload: { opportunityId, userId }
		});

		const { data } = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/opportunities/checkstatus/${opportunityId}/${userId}`,
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		dispatch({ type: OPPORTUNITY_CHECK_STATUS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_CHECK_STATUS_FAIL, payload: error.message });
	}
};

const listAllVolunteers = () => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({
			type: OPPORTUNITY_LIST_ALL_VOLUNTEERS_REQUEST
		});

		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/applicants`, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		dispatch({ type: OPPORTUNITY_LIST_ALL_VOLUNTEERS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPPORTUNITY_LIST_ALL_VOLUNTEERS_FAIL, payload: error.message });
	}
};

export {
	listOpportunities,
	listOwnOpportunities,
	saveOpportunity,
	deleteOpportunity,
	detailsOpportunity,
	signUpForOpportunity,
	listOpportunityVolunteers,
	listNumOpportunityVolunteers,
	checkStatus,
	listAllVolunteers
};
