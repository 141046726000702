import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { saveAddressSelection, saveShipping } from "../actions/cartActions";
import { detailsUser } from "../actions/userActions";
import FakeRadioButton from "../components/FakeRadioButton";
import Loading from "../components/Loading";
import AddressBlock from "../components/blocks/AddressBlock";

function ShippingScreen(props) {
	const storedAddress = useSelector(state => state.cart.shipping);
	const [address, setAddress] = useState(storedAddress || {});
	const [correct, setCorrect] = useState(false);

	const dispatch = useDispatch();
	const { user, loading } = useSelector(state => state.userDetails);
	const { userInfo } = useSelector(state => state.userSignin);
	const addressSelection = useSelector(state => state.cart.addressSelection) || "new";

	const showSavedAddress = user?.address?.line1;

	useEffect(() => {
		if (userInfo?._id) {
			dispatch(detailsUser(userInfo._id));
		}
		if (address.line1 && address.city && address.state && address.zip) {
			setCorrect(true);
		}
	}, []);

	useEffect(() => {
		if (showSavedAddress) {
			dispatch(saveShipping({ address: user.address }));
			dispatch(saveAddressSelection("saved"));
		}
	}, [loading]);

	useEffect(() => {
		dispatch(saveShipping({ address }));
	}, [address]);

	const updateAddressSelection = sel => {
		if (sel !== addressSelection) {
			dispatch(saveAddressSelection(sel));
			if (sel === "saved") {
				dispatch(saveShipping({ address: user.address }));
			}
		}
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
		updateAddressSelection("new");
	};

	return (
		<>
			<Helmet>
				<title>Vower | Shipping</title>
			</Helmet>
			<Container style={{ maxWidth: 500 }}>
				<h2>Mailing Address</h2>

				{loading ? (
					<div>
						<Loading text="Loading Saved Address..." />
					</div>
				) : (
					<div>
						{showSavedAddress && (
							<div
								className="p-3"
								style={{
									border: "2px solid",
									borderTopLeftRadius: "1rem",
									borderTopRightRadius: "1rem"
								}}
							>
								<div
									className="d-flex align-items-center"
									style={{ cursor: "pointer" }}
									onClick={() => updateAddressSelection("saved")}
								>
									<FakeRadioButton selected={addressSelection === "saved"} className="mr-2" />
									<h4 className="mb-0">Use Saved Address:</h4>
								</div>
								{user.address.line1}, {user.address.city}, {user.address.state}, {user.address.zip}
							</div>
						)}
						<div
							className="p-3"
							style={{
								border: "2px solid",
								borderWidth: showSavedAddress ? "2px" : "0px",
								borderTop: "none",
								borderBottomLeftRadius: "1rem",
								borderBottomRightRadius: "1rem"
							}}
						>
							<div
								className="d-flex align-items-center"
								style={{ cursor: "pointer" }}
								onClick={() => updateAddressSelection("new")}
							>
								<FakeRadioButton selected={addressSelection === "new"} className="mr-2" />
								<h4 className="mb-0">Use New Address</h4>
							</div>
							<div
								style={{
									maxHeight: addressSelection === "new" ? "500px" : "0",
									transition: "max-height 1s",
									overflowY: "hidden"
								}}
							>
								<Form validated onChange={handleFormChange}>
									<AddressBlock
										address={address}
										required
										setAddress={setAddress}
										showTitle={false}
									/>
								</Form>
							</div>
						</div>
					</div>
				)}

				<div className="d-flex justify-content-between mt-3">
					<Link to="/cart">
						<Button variant="main">&larr; Back To Cart</Button>
					</Link>
					<Link to="/place-order" className="ml-3">
						<Button variant="main" disabled={!(correct || addressSelection === "saved")}>
							Continue To Order Review
						</Button>
					</Link>
				</div>
			</Container>
		</>
	);
}
export default ShippingScreen;
