import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import Chevron from "../../assets/images/chevron.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const CardContainer = styled(motion.div)`
	width: min(${props => (props.$windowWidth < 500 ? "85vw" : "95vw")}, 1250px);
	height: ${props => (props.$windowWidth < 500 ? "110vw" : "auto")};
	margin: 0;
	margin-top: min(${props => (props.$windowWidth < 500 ? "10vw" : "5.2vw")}, 80px);
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`;

const Card = styled.div`
	font-size: min(${props => (props.$windowWidth < 500 ? "34vw" : "17vw")}, 240px);
	width: min(${props => (props.$windowWidth < 500 ? "35vw" : "13vw")}, 250px);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: min(${props => (props.$windowWidth < 500 ? "2vw" : "2vw")}, 20px);
	margin-right: min(${props => (props.$windowWidth < 500 ? "2vw" : "2vw")}, 20px);
	margin-bottom: min(${props => (props.$windowWidth < 500 ? "10vw" : "5.2vw")}, 80px);
`;

const CardIcon = styled.img`
	max-height: min(${props => (props.$windowWidth < 500 ? "10vw" : "7vw")}, 100px);
`;

const CardHeader = styled.p`
	font-family: Inter;
	font-weight: 800;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.5vw" : "1.8vw")}, 28px);
	margin-top: min(5vw, 60px);
	height: min(3vw, 100px);
	text-align: center;
`;

const CardBody = styled.p`
	font-family: Inter;
	font-weight: 400;
	font-size: min(${props => (props.$windowWidth < 500 ? "2.9vw" : "1.5vw")}, 20px);
	text-align: center;
	margin-top: min(3vw, 40px);
	height: min(3vw, 80px);
`;

const LinkButton = styled(Link)`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "2.5vw" : "1.28vw")}, 16px);
	padding: 0;
	background: linear-gradient(180deg, #0fafb0 0%, #1d7a7a 100%);
	box-shadow: 0px 5px 41px 4px rgba(1, 103, 104, 0.7);
	border-radius: min(${props => (props.$windowWidth < 500 ? "1.5vw" : "0.8vw")}, 8px);
	align-items: center;
	justify-content: center;
	width: ${props => (props.$windowWidth < 500 ? "70%" : "80%")};
	margin-top: min(${props => (props.$windowWidth < 500 ? "10vw" : "7vw")}, 100px);
	height: min(3vw, 45px);
	height: min(${props => (props.$windowWidth < 500 ? "5.9vw" : "3vw")}, 45px);
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ChevronIcon = styled.img`
	margin-top: min(4vw, 50px);
	max-width: min(${props => (props.$windowWidth < 500 ? "4vw" : "3vw")}, 50px);
	max-height: min(${props => (props.$windowWidth < 500 ? "4vw" : "3vw")}, 50px);
`;

function HowItWorksCarousel(props) {
	const { cards } = props;
	const { windowWidth } = useWindowDimensions();

	return (
		<CardContainer $windowWidth={windowWidth}>
			{cards.map((card, index) => (
				<div key={card.id} style={{ display: "flex", flexDirection: "row" }}>
					<Card $windowWidth={windowWidth}>
						<CardIcon $windowWidth={windowWidth} alt={card.alt} src={card.iconSrc} />
						<CardHeader $windowWidth={windowWidth}>{card.header}</CardHeader>
						<CardBody $windowWidth={windowWidth}>{card.body}</CardBody>
						<LinkButton $windowWidth={windowWidth} to={card.link} style={{ color: "#FFFFFF" }}>
							{card.buttonLabel}
						</LinkButton>
					</Card>
					{index != cards.length - 1 && (windowWidth >= 500 || index != 1) && (
						<ChevronIcon $windowWidth={windowWidth} src={Chevron} />
					)}
				</div>
			))}
		</CardContainer>
	);
}
export default HowItWorksCarousel;
