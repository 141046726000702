import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { listCategories } from "../../actions/categoryActions";
import { deleteFiles, uploadFiles } from "../../actions/fileActions";
import { deleteIncentive, listIncentives, saveIncentive } from "../../actions/incentiveActions";
import { storage } from "../../firebase";

function IncentivesScreen(props) {
	const [modalVisible, setModalVisible] = useState(false);
	const [correct, setCorrect] = useState(false);
	const [id, setId] = useState("");
	const [category, setCategory] = useState("");
	const [name, setName] = useState("");
	const [points, setPoints] = useState("");
	const [short_description, setShortDescription] = useState("");
	const [long_description, setLongDescription] = useState("");
	const [featured, setFeatured] = useState(false);
	const [stock, setStock] = useState(-1);
	const [images, setImages] = useState("");
	const [images_paths, setImagesPaths] = useState([]);
	const [imageUploading, setImageUploading] = useState(false);

	const [files, setFiles] = useState([]);

	const { userInfo } = useSelector(state => state.userSignin);
	const { incentives } = useSelector(state => state.incentiveList);
	const { success: successSave } = useSelector(state => state.incentiveSave);
	const { success: successDelete } = useSelector(state => state.incentiveDelete);
	const { categories } = useSelector(state => state.categoryList);

	const dispatch = useDispatch();
	// handler when user select files for images/videos
	const onFileChange = e => {
		setFiles([]);
		for (let i = 0; i < e.target.files.length; i++) {
			const newFile = e.target.files[i];
			setFiles(prevState => [...prevState, newFile]);
		}
	};

	// handler to remove old images/videos while uploading new images/videos
	async function successDeleteOldFiles(files_paths) {
		try {
			await dispatch(deleteFiles(files_paths));
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	// handler to upload new images or videos
	const handleUpload = async e => {
		e.preventDefault();

		const urls = [];
		const paths = [];
		console.log(files);
		files.forEach(file => {
			// check size of file, should not be too large
			if (file.size > 60000000) {
				// larger than 60M
				alert("Please use image with a size less than 60Mb");
				return null;
			}
		});

		const results = await dispatch(uploadFiles(files));
		const promises = results.map(
			item =>
				new Promise((resolve, reject) => {
					storage
						.ref(item.filePath)
						.getDownloadURL()
						.then(downloadURL => {
							urls.push(downloadURL);
							paths.push(item.filePath);
							resolve();
						});
				})
		);

		// if have preivous images and videos, delete them
		const success_del_old_files = successDeleteOldFiles(images_paths);
		if (!success_del_old_files) return;

		Promise.all(promises)
			.then(() => {
				alert("All files uploaded.");
				setImages(urls);
				setImagesPaths(paths);
				setImageUploading(false);
			})
			.catch(err => console.error(err.code));
	};

	useEffect(() => {
		if (userInfo?.isAdmin) {
			dispatch(listCategories("incentive_category"));
			if (successSave) {
				setModalVisible(false);
			}
			dispatch(listIncentives({ all: true }));
		}
	}, [successSave, successDelete]);

	// handler to open create/edit incentive modal
	const openModal = incentive => {
		setModalVisible(true);
		setCorrect(true);
		setId(incentive._id);
		setCategory(incentive.category || categories[0].category_name);
		setName(incentive.name);
		setPoints(incentive.points);
		setShortDescription(incentive.short_description);
		setLongDescription(incentive.long_description);
		setFeatured(incentive.featured || false);
		setStock(incentive.stock ?? 1);
		setImages(incentive.images);
		setImagesPaths(incentive.images_paths);
	};

	const closeModal = () => setModalVisible(false);

	// handler to save incentive info
	const handleSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		setImages("");
		setImagesPaths([]);
		setFiles([]);
		dispatch(
			saveIncentive({
				_id: id,
				category,
				name,
				points,
				short_description,
				long_description,
				featured,
				stock: parseInt(stock),
				images,
				images_paths
			})
		);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	// handler to delete an opportunity
	const handleDelete = incentive => {
		if (window.confirm("Do you really want to delete this item?")) {
			dispatch(deleteIncentive(incentive));
		}
	};

	return (
		<>
			{!userInfo?.isAdmin && <Navigate to="/" />}
			<Helmet>
				<title>Vower | Manage Incentives</title>
			</Helmet>
			<div>
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<h3>Manage Incentives</h3>
					<Button variant="main" onClick={() => openModal({})}>
						Create Incentive
					</Button>
				</div>

				<Modal dialogClassName="manage-modal" size="lg" show={modalVisible} onHide={closeModal}>
					<Form noValidate validated onSubmit={handleSubmit} onChange={handleFormChange}>
						<Modal.Body>
							<h2>{id ? "Update" : "Create"} Incentive</h2>

							<Form.Group controlId="category">
								<Form.Label>Category</Form.Label>
								<Form.Control
									required
									as="select"
									className="custom-select"
									value={category}
									onChange={e => setCategory(e.target.value)}
								>
									{categories &&
										categories.map(category => (
											<option key={category._id} value={category.category_name}>
												{category.category_name}
											</option>
										))}
								</Form.Control>
							</Form.Group>

							<Form.Group controlId="name">
								<Form.Label>Incentive Name</Form.Label>
								<Form.Control
									required
									type="text"
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="points">
								<Form.Label>Points Cost</Form.Label>
								<Form.Control
									required
									type="number"
									inputMode="numeric"
									min={0}
									value={points}
									onChange={e => setPoints(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="short_description">
								<Form.Label>Short Description</Form.Label>
								<Form.Control
									type="text"
									value={short_description}
									onChange={e => setShortDescription(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="long_description">
								<Form.Label>Long Description</Form.Label>
								<Form.Control
									as="textarea"
									value={long_description}
									onChange={e => setLongDescription(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="featured">
								<Form.Check
									label="Featured"
									checked={featured}
									onChange={e => setFeatured(e.target.checked)}
								/>
							</Form.Group>

							<Form.Group controlId="stock">
								<Form.Label>Stock</Form.Label>
								<Form.Control
									required
									type="number"
									inputMode="numeric"
									min={0}
									value={stock}
									onChange={e => setStock(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="image-file">
								<Form.Label>Images</Form.Label>
								<Form.File type="file" accept="image/*" multiple onChange={onFileChange} />
								<span style={{ fontWeight: "lighter" }}>Must be less than 60Mb</span>
								{files.length > 0 && !imageUploading && (
									<Button variant="main" onClick={handleUpload}>
										Upload
									</Button>
								)}
								{imageUploading && <div>Uploading...</div>}
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="main" type="submit" disabled={!correct}>
								{id ? "Update" : "Create"}
							</Button>
							<Button variant="main" onClick={closeModal}>
								Close
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>

				<div>
					<Table responsive>
						<thead>
							<tr>
								<th>Category</th>
								<th>Name</th>
								<th>Price</th>
								<th>Stock</th>
								<th>Short Description</th>
								<th>Long Description</th>
								<th>Featured</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{incentives &&
								incentives.map(incentive => (
									<tr key={incentive._id}>
										<td>{incentive.category}</td>
										<td>
											<Link to={`/incentive/${incentive._id}`}>{incentive.name}</Link>
										</td>
										<td>{incentive.points}</td>
										<td>{incentive.stock}</td>
										<td>{incentive.short_description}</td>
										<td>{incentive.long_description}</td>
										<td>{incentive.featured ? "Yes" : "No"}</td>
										<td>
											<Button variant="main" onClick={() => openModal(incentive)}>
												Edit
											</Button>
										</td>
										<td>
											<Button variant="main" onClick={() => handleDelete(incentive)}>
												X
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}
export default IncentivesScreen;
