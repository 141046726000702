import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../assets/css/slidingSelector.css";

function SlidingSelector({ choices, currentChoice, setCurrentChoice }) {
	const [currWidth, setCurrWidth] = useState(0);
	const [currOffset, setCurrOffset] = useState(0);

	const updateSelector = () => {
		const selected = document.querySelector(".sliding-selector.selected");
		if (selected) {
			setCurrWidth(selected.offsetWidth);
			setCurrOffset(selected.offsetLeft);
		}
	};

	const handleClick = (e, choice) => {
		e.preventDefault();
		setCurrentChoice(choice);
	};

	useEffect(() => {
		updateSelector();
	}, [currentChoice]);

	useEffect(() => {
		window.addEventListener("resize", updateSelector);
		return () => window.removeEventListener("resize", updateSelector);
	}, []);

	return (
		<div className="sliding-selector-wrapper">
			<div className="slider" style={{ width: currWidth, left: currOffset }} />
			{choices.map(choice => (
				<button
					key={choice}
					className={`sliding-selector ${choice.toLowerCase() === currentChoice.toLowerCase() && "selected"}`}
					onClick={e => handleClick(e, choice)}
				>
					{choice}
				</button>
			))}
		</div>
	);
}

SlidingSelector.propTypes = {
	choices: PropTypes.arrayOf(PropTypes.string).isRequired,
	currentChoice: PropTypes.string.isRequired,
	setCurrentChoice: PropTypes.func.isRequired
};

export default SlidingSelector;
