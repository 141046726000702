import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import "../assets/css/Navbar.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { getTheme } from "../reducers/themeReducer";

const StyledNavbar = styled.div`
	position: fixed;
	width: 100%;
	height: 70px;
	background: linear-gradient(180deg, rgba(123, 123, 123, 0.1) 0%, rgba(138, 138, 138, 0.19) 100%);
	backdrop-filter: blur(10px);
	z-index: 4;
`;

const NavbarLinkContainer = styled.div`
	padding-left: ${(117 / 1440) * 100}%;
	padding-right: ${(91 / 1440) * 100}%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
`;

const LeftStyledLink = styled(Link)`
	font-family: Inter;
	font-weight: 700;
	font-size: min(1.8vw, 18px);
	padding-right: 56px;
`;

const RightStyledLink = styled(Link)`
	font-family: Inter;
	font-weight: 700;
	font-size: min(1.8vw, 18px);
	padding-right: 33px;
`;

const CollapseNavbar = () => {
	const { userInfo } = useSelector(state => state.userSignin);
	const { theme } = useSelector(getTheme);
	return (
		<Navbar
			collapseOnSelect
			expand="lr"
			fixed="top"
			className="mobile-navbar"
			style={{
				background: "linear-gradient(180deg, rgba(123, 123, 123, 0.5) 0%, rgba(138, 138, 138, 0.4) 100%)",
				backdropFilter: "blur(10px)",
				zIndex: 4
			}}
		>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="justify-content-between align-items-end">
					<Nav.Link as={Link} eventKey="1" to="/opportunities">
						Gigs
					</Nav.Link>
					<Nav.Link as={Link} eventKey="2" to="/incentives">
						Shop
					</Nav.Link>
					<Nav.Link as={Link} eventKey="3" to="/how-it-works">
						Learn More
					</Nav.Link>
					{userInfo ? (
						<>
							<Nav.Link as={Link} eventKey="4" to={`/profile/${userInfo._id}`}>
								My Profile
							</Nav.Link>
							<Nav.Link as={Link} eventKey="5" to="/logout">
								Logout
							</Nav.Link>
						</>
					) : (
						<>
							<Nav.Link as={Link} eventKey="4" to="/sign-in">
								Sign In
							</Nav.Link>
							<Nav.Link as={Link} eventKey="5" to="/register">
								Register
							</Nav.Link>
						</>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

function VowerNavbar() {
	const { userInfo } = useSelector(state => state.userSignin);
	const { windowHeight, windowWidth } = useWindowDimensions();
	return windowWidth >= 500 ? (
		<StyledNavbar>
			<NavbarLinkContainer>
				<div>
					<LeftStyledLink style={{ color: "#25BEBD" }} to="/opportunities">
						Gigs
					</LeftStyledLink>
					<LeftStyledLink style={{ color: "#25BEBD" }} to="/incentives">
						Shop
					</LeftStyledLink>
					<LeftStyledLink style={{ color: "#25BEBD" }} to="/how-it-works">
						Learn More
					</LeftStyledLink>{" "}
					{/* Link to FAQ Page*/}
				</div>
				<div>
					{!userInfo ? (
						<>
							<RightStyledLink style={{ color: "#25BEBD" }} to="/sign-in">
								Sign In
							</RightStyledLink>
							<RightStyledLink style={{ color: "#25BEBD" }} to="/register">
								Register
							</RightStyledLink>
						</>
					) : (
						<>
							<RightStyledLink style={{ color: "#25BEBD" }} to={`/profile/${userInfo._id}`}>
								My Profile
							</RightStyledLink>
							<RightStyledLink style={{ color: "#25BEBD" }} to="/logout">
								Logout
							</RightStyledLink>
						</>
					)}
				</div>
			</NavbarLinkContainer>
		</StyledNavbar>
	) : (
		<CollapseNavbar />
	);
}

export default VowerNavbar;
