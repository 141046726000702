import {
	INCENTIVE_LIST_REQUEST,
	INCENTIVE_LIST_SUCCESS,
	INCENTIVE_LIST_FAIL,
	INCENTIVE_DETAILS_REQUEST,
	INCENTIVE_DETAILS_SUCCESS,
	INCENTIVE_DETAILS_FAIL,
	INCENTIVE_SAVE_REQUEST,
	INCENTIVE_SAVE_SUCCESS,
	INCENTIVE_SAVE_FAIL,
	INCENTIVE_DELETE_REQUEST,
	INCENTIVE_DELETE_SUCCESS,
	INCENTIVE_DELETE_FAIL
} from "../constants/incentiveConstants";

function incentiveListReducer(state = { incentives: [] }, action) {
	switch (action.type) {
		case INCENTIVE_LIST_REQUEST:
			return { loading: true, incentives: action.shouldRefetch ? [] : state.incentives };
		case INCENTIVE_LIST_SUCCESS:
			return { loading: false, incentives: [...state.incentives, ...action.payload] };
		case INCENTIVE_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function incentiveDetailsReducer(state = { incentive: { reviews: [] } }, action) {
	switch (action.type) {
		case INCENTIVE_DETAILS_REQUEST:
			return { loading: true };
		case INCENTIVE_DETAILS_SUCCESS:
			return { loading: false, incentive: action.payload };
		case INCENTIVE_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function incentiveDeleteReducer(state = { incentive: {} }, action) {
	switch (action.type) {
		case INCENTIVE_DELETE_REQUEST:
			return { loading: true };
		case INCENTIVE_DELETE_SUCCESS:
			return { loading: false, incentive: action.payload, success: true };
		case INCENTIVE_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

function incentiveSaveReducer(state = { incentive: {} }, action) {
	switch (action.type) {
		case INCENTIVE_SAVE_REQUEST:
			return { loading: true };
		case INCENTIVE_SAVE_SUCCESS:
			return { loading: false, success: true, incentive: action.payload };
		case INCENTIVE_SAVE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

export { incentiveListReducer, incentiveDetailsReducer, incentiveSaveReducer, incentiveDeleteReducer };
