/* eslint-disable indent */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Image, Modal } from "react-bootstrap";
import { Check, FileEarmarkPlusFill, PlusSquareFill, X, XSquareFill } from "react-bootstrap-icons";
import DateTimePicker from "react-datetime-picker";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { deleteFiles, uploadFiles } from "../../actions/fileActions";
import { saveOpportunity } from "../../actions/opportunityActions";
import { storage } from "../../firebase";
import AddressBlock from "../blocks/AddressBlock";
import SlidingSelector from "../SlidingSelector";

function ManageOpportunityModal(props) {
	const dispatch = useDispatch();

	const [correct, setCorrect] = useState(true);
	const [opp, setOpp] = useState({});
	const [uploading, setUploading] = useState(false);
	// the files of the new images the user uploads
	const [newImageFiles, setNewImageFiles] = useState([]);
	// the local urls of the new images the user uploads as well as the urls of the previously uploaded images
	const [oppImageURLs, setOppImageURLs] = useState([]);
	// list of previously uploaded images to delete on submit (stores firebase image paths)
	const [imageDelList, setImageDelList] = useState([]);
	const [newVideoFiles, setNewVideoFiles] = useState([]);
	const [oppVideoURLs, setOppVideoURLs] = useState([]);
	const [videoDelList, setVideoDelList] = useState([]);

	const [initialPoints, setInitialPoints] = useState(0);
	const [initialSpaces, setInitialSpaces] = useState(0);

	const pointsReq = opp.points * opp.total_spaces - initialPoints * initialSpaces;

	const disabled = !correct || uploading || parseInt(props.user?.points) < pointsReq;

	useEffect(() => {
		const { opportunity } = props;
		const defaultAddress = { line1: "", line2: "", city: "", state: "", zip: "" };
		const address = opportunity.address || defaultAddress;
		const company_name = opportunity.company_name || props.userInfo?.name;
		// For dates, must use null to represent no date. Undefined throws an error
		const end_date_time = opportunity.end_date_time ? new Date(opportunity.end_date_time) : null;
		const expire_date_time = opportunity.expire_date_time ? new Date(opportunity.expire_date_time) : null;
		const remote = opportunity.remote || false;
		const requirements = Array.isArray(opportunity.requirements)
			? opportunity.requirements
			: opportunity.requirements
			? [opportunity.requirements]
			: [];
		const start_date_time = opportunity.start_date_time ? new Date(opportunity.start_date_time) : null;
		const tasks = opportunity.tasks || (opportunity.task ? [opportunity.task] : []);
		setOppImageURLs(opportunity.images || []);
		setOppVideoURLs(opportunity.videos || []);
		setOpp({
			...opportunity,
			address,
			category: opportunity.category || "",
			company_name,
			devOnly: opportunity.devOnly || false,
			end_date_time,
			expire_date_time,
			featured: opportunity.featured || false,
			images: opportunity.images || [],
			images_paths: opportunity.images_paths || [],
			long_description: opportunity.long_description || "",
			name: opportunity.name || "",
			points: opportunity.points || "",
			remote,
			requirements,
			short_description: opportunity.short_description || "",
			start_date_time,
			tasks,
			total_spaces: opportunity.total_spaces || "",
			videos: opportunity.videos || [],
			videos_paths: opportunity.videos_paths || []
		});
		setInitialPoints(opportunity.points || 0);
		setInitialSpaces(opportunity.total_spaces || 0);
	}, [props.opportunity]);

	const deleteOldFiles = async file_paths => {
		setUploading(true);
		try {
			await dispatch(deleteFiles(file_paths));
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	const handleImageUpload = e => {
		const image = e.target.files[0];
		if (image.type.indexOf("image") === -1) {
			alert("Please only upload images here");
			return;
		}
		if (image.size > 5000000) {
			// larger than 5MB
			alert("Please upload an image with a size less than 5MB");
			return;
		}
		setOppImageURLs([...oppImageURLs, URL.createObjectURL(image)]);
		setNewImageFiles([...newImageFiles, image]);
	};

	const handleVideoUpload = e => {
		const video = e.target.files[0];
		if (video.type.indexOf("video") === -1) {
			alert("Please only upload videos here");
			return;
		}
		if (video.size > 60000000) {
			// larger than 60MB
			alert("Please upload a video with a size less than 60MB");
			return;
		}
		setOppVideoURLs([...oppVideoURLs, URL.createObjectURL(video)]);
		setNewVideoFiles([...newVideoFiles, video]);
	};

	const uploadNewFiles = async isImages => {
		const urls = [];
		const paths = [];
		const files = isImages ? newImageFiles : newVideoFiles;
		const results = await dispatch(uploadFiles(files));
		const promises = results.map(item =>
			// initiates the firebase side uploading
			 new Promise((resolve, reject) => {
				storage
					.ref(item.filePath)
					.getDownloadURL()
					.then(firebaseURL => {
						urls.push(firebaseURL);
						paths.push(item.filePath);
						resolve();
					});
			})
		);
		await Promise.all(promises);
		if (isImages) return { images: urls, images_paths: paths };
		return { videos: urls, videos_paths: paths };
	};

	const uploadMedias = async () => {
		setUploading(true);

		// Necessary because react does not synchronously update state using the setter method
		// so the images to be uploaded have to be stored in tempImages instead of the newImageFiles state
		let tempImages = opp.images || [];
		let tempImagePaths = opp.images_paths || [];
		if (opp.images?.length > 0 && oppImageURLs?.length === 0) {
			// The user deleted all of their old images
			await deleteOldFiles(opp.images_paths);
			setNewImageFiles([]);
			setOppImageURLs([]);
			tempImages = [];
			tempImagePaths = [];
		} else if (imageDelList?.length > 0) {
			await deleteOldFiles(imageDelList);
		}

		if (newImageFiles?.length > 0) {
			// the user uploaded images
			const response = await uploadNewFiles(true);
			tempImages = tempImages.concat(response.images);
			tempImagePaths = tempImagePaths.concat(response.images_paths);
		}

		let tempVideos = opp.videos || [];
		let tempVideoPaths = opp.videos_paths || [];
		if (opp.videos?.length > 0 && oppVideoURLs?.length === 0) {
			// The user deleted all of their old videos
			await deleteOldFiles(opp.videos_paths);
			setNewVideoFiles([]);
			setOppVideoURLs([]);
			tempVideos = [];
			tempVideoPaths = [];
		} else if (videoDelList?.length > 0) {
			await deleteOldFiles(videoDelList);
		}

		if (newVideoFiles?.length > 0) {
			// the user uploaded videos
			const response = await uploadNewFiles(false);
			tempVideos = tempVideos.concat(response.videos);
			tempVideoPaths = tempVideoPaths.concat(response.videos_paths);
		}

		setUploading(false);

		return { tempImages, tempImagePaths, tempVideos, tempVideoPaths };
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (!props.user) return;

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		if (props.user?.points < pointsReq) {
			alert(`You don't have enough points to ${opp._id ? "update" : "post"} this opportunity.`);
			return;
		}
		if (opp.points < 0) {
			alert("You can't create an opportunity with a negative amount of points");
			return;
		}
		if (opp.total_spaces < 1) {
			alert("You can't create an opportunity without any spaces available to sign up");
			return;
		}
		if (!opp.remote && (!opp.start_date_time || !opp.end_date_time)) {
			alert("Can't create an in person opportunity without a start and end date and time");
			return;
		}

		const { tempImages, tempImagePaths, tempVideos, tempVideoPaths } = await uploadMedias();

		dispatch(
			saveOpportunity({
				...opp,
				points: opp.points || 0,
				start_date_time: opp.remote ? undefined : opp.start_date_time?.getTime(),
				end_date_time: opp.remote ? undefined : opp.end_date_time?.getTime(),
				expire_date_time: opp.remote ? undefined : opp.end_date_time?.getTime(),
				images: tempImages,
				images_paths: tempImagePaths,
				videos: tempVideos,
				videos_paths: tempVideoPaths
			})
		);

		// Reset image and video states
		setNewImageFiles([]);
		setOppImageURLs([]);
		setImageDelList([]);
		setNewVideoFiles([]);
		setOppVideoURLs([]);
		setVideoDelList([]);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	const addTask = () => {
		setOpp({ ...opp, tasks: [...opp.tasks, ""] });
	};
	const updateTask = (e, i) => {
		const currTasks = [...opp.tasks];
		currTasks[i] = e.target.value;
		setOpp({ ...opp, tasks: currTasks });
	};
	const removeTask = i => {
		const currTasks = [...opp.tasks];
		currTasks.splice(i, 1);
		setOpp({ ...opp, tasks: currTasks });
	};
	const addRequirement = () => {
		setOpp({ ...opp, requirements: [...opp.requirements, ""] });
	};
	const updateRequirement = (e, i) => {
		const currRequirements = [...opp.requirements];
		currRequirements[i] = e.target.value;
		setOpp({ ...opp, requirements: currRequirements });
	};
	const removeRequirement = i => {
		const currRequirements = [...opp.requirements];
		currRequirements.splice(i, 1);
		setOpp({ ...opp, requirements: currRequirements });
	};

	const removeImage = (e, i) => {
		e.preventDefault();

		const currImages = [...newImageFiles];
		const currImageURLs = [...oppImageURLs];
		currImages.splice(i, 1);
		const [removedImage] = currImageURLs.splice(i, 1);
		setNewImageFiles(currImages);
		setOppImageURLs(currImageURLs);
		if (opp.images?.length > 0) {
			const index = opp.images.indexOf(removedImage);
			if (index !== -1) {
				const currOppImages = [...opp.images];
				currOppImages.splice(index, 1);
				const currOppImagePaths = [...opp.images_paths];
				currOppImagePaths.splice(index, 1);
				setOpp({ ...opp, images: currOppImages, images_paths: currOppImagePaths });
				setImageDelList([...imageDelList, opp.images_paths[index]]);
			}
		}
	};
	const removeVideo = (e, i) => {
		e.preventDefault();

		const currVideos = [...newVideoFiles];
		const currVideoURLs = [...oppVideoURLs];
		currVideos.splice(i, 1);
		const [removedVideo] = currVideoURLs.splice(i, 1);
		setNewVideoFiles(currVideos);
		setOppVideoURLs(currVideoURLs);
		if (opp.videos?.length > 0) {
			const index = opp.videos.indexOf(removedVideo);
			if (index !== -1) {
				const currOppVideos = [...opp.videos];
				currOppVideos.splice(index, 1);
				const currOppVideoPaths = [...opp.videos_paths];
				currOppVideoPaths.splice(index, 1);
				setOpp({ ...opp, videos: currOppVideos, videos_paths: currOppVideoPaths });
				setVideoDelList([...videoDelList, opp.videos_paths[index]]);
			}
		}
	};

	const clearImages = e => {
		e.preventDefault();

		setNewImageFiles([]);
		setOppImageURLs([]);
		setImageDelList(opp.images_paths);
		setOpp({ ...opp, images: [], images_paths: [] });
	};
	const clearVideos = e => {
		e.preventDefault();

		setNewVideoFiles([]);
		setOppVideoURLs([]);
		setVideoDelList(opp.videos_paths);
		setOpp({ ...opp, videos: [], videos_paths: [] });
	};

	return (
		<Modal
			dialogClassName="manage-modal"
			size="lg"
			show={props.modalVisible}
			onHide={() => props.setModalVisible(false)}
		>
			<Form noValidate validated onSubmit={handleSubmit} onChange={handleFormChange}>
				<Modal.Body>
					<h2>{opp._id ? "Update" : "Create"} Opportunity</h2>

					<Form.Group controlId="category">
						<Form.Label>Category</Form.Label>
						<Form.Control
							required
							as="select"
							className="custom-select"
							value={opp.category}
							onChange={e => setOpp({ ...opp, category: e.target.value })}
						>
							<option value="" disabled>
								Choose a Category
							</option>
							{props.categories &&
								props.categories.map(category => (
									<option value={category.category_name} key={category.category_name}>
										{category.category_name}
									</option>
								))}
						</Form.Control>
					</Form.Group>

					<Form.Group controlId="name">
						<Form.Label>Opportunity Name</Form.Label>
						<Form.Control
							required
							type="text"
							value={opp.name}
							onChange={e => setOpp({ ...opp, name: e.target.value })}
						/>
					</Form.Group>

					{props.userInfo?.isAdmin && (
						<Form.Group controlId="company_name">
							<Form.Label>Company Name</Form.Label>
							<Form.Control
								required
								type="text"
								value={opp.company_name}
								onChange={e => setOpp({ ...opp, company_name: e.target.value })}
							/>
						</Form.Group>
					)}

					<div className="block p-3">
						<SlidingSelector
							choices={["In Person", "Remote"]}
							currentChoice={opp.remote ? "Remote" : "In Person"}
							setCurrentChoice={newChoice =>
								setOpp({ ...opp, remote: newChoice.toLowerCase() === "remote" })
							}
						/>
						{!opp.remote && (
							<>
								<AddressBlock
									blockClassName="mx-0 mb-0"
									className="mt-2 mb-3"
									address={opp.address}
									setAddress={address => setOpp({ ...opp, address })}
								/>

								<Form.Group controlId="start_date_time">
									<Form.Label>Start Date & Time</Form.Label>
									<DateTimePicker
										name="start_date_time"
										value={opp.start_date_time}
										id="start_date_time"
										format="y-MM-dd hh:mm a"
										onChange={newDate => setOpp({ ...opp, start_date_time: newDate })}
									/>
								</Form.Group>

								<Form.Group controlId="end_date_time">
									<Form.Label>End Date & Time</Form.Label>
									<DateTimePicker
										name="end_date_time"
										value={opp.end_date_time}
										id="end_date_time"
										format="y-MM-dd hh:mm a"
										onChange={newDate => setOpp({ ...opp, end_date_time: newDate })}
									/>
								</Form.Group>
							</>
						)}
					</div>

					<Form.Group controlId="points">
						<Form.Label>Points (per participant)</Form.Label>
						<Form.Control
							required
							type="number"
							inputMode="numeric"
							min={0}
							value={opp.points}
							onChange={e => setOpp({ ...opp, points: e.target.value })}
						/>
					</Form.Group>

					<Form.Group controlId="total_spaces">
						<Form.Label>Number of Participants</Form.Label>
						<Form.Control
							required
							type="number"
							inputMode="numeric"
							min={1}
							value={opp.total_spaces}
							onChange={e => setOpp({ ...opp, total_spaces: e.target.value })}
						/>
					</Form.Group>

					{props.user && opp.points && opp.total_spaces && (
						<div className="block">
							<div>
								<b>
									Number of Points{" "}
									{pointsReq >= 0 ? `Required to ${opp._id ? "Update" : "Post"}` : "Refunded"}:
								</b>{" "}
								{Math.abs(pointsReq)}
							</div>
							{props.userInfo?.isOrganization && (
								<div>
									<b>Your Points:</b> {props.user.points}
									{pointsReq > props.user.points ? (
										<X fill="red" size="1.5rem" />
									) : (
										<Check fill="lime" size="1.5rem" />
									)}
								</div>
							)}
						</div>
					)}

					<Form.Group controlId="long_description">
						<Form.Label>Description</Form.Label>
						<Form.Control
							as="textarea"
							value={opp.long_description}
							onChange={e => setOpp({ ...opp, long_description: e.target.value })}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Tasks</Form.Label>
						<div className="block">
							{opp.tasks?.length > 0 &&
								opp.tasks.map((task, i) => (
									<div className="mb-2 d-flex align-items-center" key={`task-${i}`}>
										<Form.Control
											required
											type="text"
											pattern="\S+.*"
											className="mr-3"
											value={task}
											onChange={e => updateTask(e, i)}
											onKeyPress={e => {
												if (e.key === "Enter") {
													e.preventDefault();
													addTask();
												}
											}}
										/>
										<button className="remove wrapper" onClick={() => removeTask(i)}>
											<XSquareFill />
										</button>
									</div>
								))}
							<button className="add" onClick={addTask}>
								<div>Add Task</div> <PlusSquareFill />
							</button>
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label>Requirements</Form.Label>
						<div className="block">
							{opp.requirements?.length > 0 &&
								opp.requirements.map((requirement, i) => (
									<div className="mb-2 d-flex align-items-center" key={`requirement-${i}`}>
										<Form.Control
											required
											type="text"
											pattern="\S+.*"
											className="mr-3"
											value={requirement}
											onChange={e => updateRequirement(e, i)}
											onKeyPress={e => {
												if (e.key === "Enter") {
													e.preventDefault();
													addRequirement();
												}
											}}
										/>
										<button className="remove wrapper" onClick={() => removeRequirement(i)}>
											<XSquareFill />
										</button>
									</div>
								))}
							<button className="add" onClick={addRequirement}>
								<div>Add Requirement</div> <PlusSquareFill />
							</button>
						</div>
					</Form.Group>

					{props.userInfo?.isAdmin && (
						<>
							<Form.Group controlId="featured">
								<Form.Check
									label="Featured"
									checked={opp.featured}
									onChange={e => setOpp({ ...opp, featured: e.target.checked })}
								/>
							</Form.Group>
							<Form.Group controlId="development">
								<Form.Check
									label="Development Only"
									checked={opp.devOnly}
									onChange={e => setOpp({ ...opp, devOnly: e.target.checked })}
								/>
							</Form.Group>
						</>
					)}

					<Form.Group controlId="images">
						<Form.Label>
							Images (Max 5MB each)
							{oppImageURLs?.length > 0 && (
								<Button className="clear ml-2" variant="danger" onClick={clearImages}>
									Clear
								</Button>
							)}
						</Form.Label>
						<div className="file-upload block">
							<div className="file-list">
								{oppImageURLs &&
									oppImageURLs.map((newImageURL, i) => (
										<div key={newImageURL}>
											<button className="remove wrapper" onClick={e => removeImage(e, i)}>
												<XSquareFill />
											</button>
											<Image src={newImageURL} className="mb-2" />
										</div>
									))}
							</div>
							<Form.File
								label={
									<div className="add">
										<div>Add Image</div> <FileEarmarkPlusFill />
									</div>
								}
								onChange={handleImageUpload}
								onClick={e => {
									// This allows for the same image to be uploaded two times in a row
									e.target.value = "";
								}}
								accept="image/*"
							/>
						</div>
					</Form.Group>

					<Form.Group controlId="videos">
						<Form.Label>
							Videos (Max 60MB each)
							{oppVideoURLs?.length > 0 && (
								<Button className="clear ml-2" variant="danger" onClick={clearVideos}>
									Clear
								</Button>
							)}
						</Form.Label>
						<div className="file-upload block">
							<div className="file-list">
								{oppVideoURLs &&
									oppVideoURLs.map((newVideoURL, i) => (
										<div key={newVideoURL}>
											<button className="remove wrapper" onClick={e => removeVideo(e, i)}>
												<XSquareFill />
											</button>
											<video autoPlay muted loop src={newVideoURL} className="mb-2" />
										</div>
									))}
							</div>
							<Form.File
								label={
									<div className="add">
										<div>Add Video</div> <FileEarmarkPlusFill />
									</div>
								}
								onChange={handleVideoUpload}
								onClick={e => {
									// This allows for the same video to be uploaded two times in a row
									e.target.value = "";
								}}
								accept="video/*"
							/>
						</div>
					</Form.Group>
					{props.error && <Alert variant="danger">{props.error}</Alert>}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex">
						<Button variant="main" type="submit" disabled={disabled}>
							{opp._id ? "Update" : "Create"}
						</Button>
						{uploading && (
							<div className="d-flex align-items-center ml-3">
								<ClipLoader size="2rem" />
								<div className="ml-2">Uploading Files...</div>
							</div>
						)}
					</div>
					<Button variant="main" onClick={() => props.setModalVisible(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

ManageOpportunityModal.propTypes = {
	opportunity: PropTypes.object.isRequired,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	userInfo: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	error: PropTypes.string,
	modalVisible: PropTypes.bool.isRequired,
	setModalVisible: PropTypes.func.isRequired
};

ManageOpportunityModal.defaultProps = {
	error: ""
};

export default ManageOpportunityModal;
