import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Container = styled.div`
	margin: 0;
	width: 75vw;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Form = styled.div`
	width: min(60vw, 750px);
	margin-top: min(3vw, 50px);
	margin-bottom: min(3vw, 50px);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const EmailField = styled.div`
	width: min(35vw, 430px);
	height: min(5vw, 48px);

	background: ${props => (props.theme == "dark" ? "rgba(217, 217, 217, 0.06)" : "rgba(0, 0, 0, 0.04)")};
	border: 1px solid ${props => (props.theme == "dark" ? "#ffffff" : "#000000")};
	backdrop-filter: blur(14.5px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: min(0.8vw, 8px);
	display: flex;
	align-items: center;
`;

const StyledTextArea = styled.textarea`
	font-family: Inter;
	height: 50%;
	width: 100%;
	font-size: min(1.5vw, 18px);
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: none;
	outline: none;
	padding-left: min(2vw, 20px);
	padding-right: min(2vw, 20px);

	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;

	resize: none; /*remove the resize handle on the bottom right*/
	color: ${props => (props.theme == "dark" ? "#FFFFFF" : "#000000")};
	overflow: hidden;
	white-space: nowrap;
`;

const Description = styled.div`
	font-family: Inter;
	font-weight: 700;
	margin-top: min(2vw, 24px);
	font-size: min(${props => (props.$windowWidth < 500 ? "4vw" : "2vw")}, 24px);
	text-align: center;
`;

const Subscribe = styled.div`
	font-family: Inter;
	font-weight: 700;
	font-size: min(1.5vw, 18px);
	padding: 0;
	width: min(15vw, 227px);
	background: linear-gradient(180deg, #0fafb0 0%, #1d7a7a 100%);
	color: #ffffff;
	box-shadow: 0px 5px 41px 4px rgba(1, 103, 104, 0.7);
	border-radius: min(0.8vw, 8px);
	align-items: center;
	justify-content: center;
	height: min(5vw, 48px);
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Response = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(1.2vw, 22px);
	height: min(1.64vw, 30px);
	margin: 0;
	padding: 0;
`;

const Spacer = styled.div`
	height: min(1.64vw, 30px);
	margin: 0;
	padding: 0;
`;

export default function EmailSection() {
	const theme = useSelector(state => state.theme.theme);
	const { windowWidth } = useWindowDimensions();
	const [email, setEmail] = useState("");
	const [attempted, setAttempted] = useState(false);
	const [emailAdded, setEmailAdded] = useState(false);
	const [loading, setLoading] = useState(false);

	const addEmail = async () => {
		setLoading(true);
		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/misc/add-user-to-audience`, {
				email,
				isOrganization: false
			});
			setEmailAdded(true);
		} catch (error) {
			setEmailAdded(false);
		}
		setAttempted(true);
		setLoading(false);
	};

	return (
		<Container>
			<Description $windowWidth={windowWidth}>
				VowerUP & Stay Updated With The Latest Gigs, Skill Courses & Rewards
			</Description>
			<Form>
				<EmailField theme={theme}>
					<StyledTextArea
						theme={theme}
						email={email}
						placeholder="EMAIL"
						onChange={e => setEmail(e.target.value)}
					/>
				</EmailField>
				<Subscribe onClick={addEmail}>SUBSCRIBE</Subscribe>
			</Form>
			{attempted && (
				<div>
					{loading ? (
						<ClipLoader />
					) : emailAdded ? (
						<Response>Signed Up!</Response>
					) : (
						<Response>There was an error while trying to sign you up</Response>
					)}
				</div>
			)}
			{!attempted && <Spacer /> /* For Padding */}
		</Container>
	);
}
