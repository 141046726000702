import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { editOrder } from "../../actions/orderActions";
import AddressBlock from "../blocks/AddressBlock";

function ManageOrderModal(props) {
	const dispatch = useDispatch();

	const [order, setOrder] = useState({});
	const [correct, setCorrect] = useState(false);

	useEffect(() => {
		if (props.modalVisible) setOrder(props.order);
	}, [props.modalVisible]);

	const hideModal = () => {
		props.setModalVisible(false);
		setCorrect(false);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	const handleFormSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		props.setModalVisible(false);
		dispatch(editOrder(order));
	};

	return (
		<Modal dialogClassName="manage-modal" size="lg" show={props.modalVisible} onHide={hideModal}>
			<Form noValidate validated onSubmit={handleFormSubmit} onChange={handleFormChange}>
				<Modal.Body>
					<h2>Update Order</h2>

					<h5>Order ID</h5>
					<div className="block">{order._id}</div>

					<h5>User ID</h5>
					<div className="block">
						<a href={`/profile/${order.user_id}`} target="_blank" rel="noopener noreferrer">
							{order.user_id}
						</a>
					</div>

					<h5>Order Date</h5>
					<div className="block">{new Date(order.order_date).toString()}</div>

					<h5>Order Cost</h5>
					<div className="block">{order.total_cost}</div>

					<h5>Order Items</h5>
					<div className="block">
						<table>
							<thead>
								<tr>
									<th>Item ID</th>
									<th>Quantity</th>
								</tr>
							</thead>
							<tbody>
								{order.order_items?.length > 0 &&
									order.order_items.map((item, i) => (
										<tr key={`order-item-${i}`}>
											<td className="pr-3">
												<a
													href={`/incentive/${item.incentive_id}`}
													target="_blank"
													rel="noopener noreferrer"
												>
													{item.incentive_id}
												</a>
											</td>
											<td>{item.quantity}</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>

					<Form.Group controlId="status">
						<Form.Label>Order Status</Form.Label>
						<Form.Control
							required
							as="select"
							className="custom-select"
							value={order.order_status || ""}
							onChange={e => setOrder({ ...order, order_status: e.target.value })}
						>
							<option value="">Select Status</option>
							<option>Cancelled</option>
							<option>Completed</option>
							<option>Requested</option>
							<option>Shipped</option>
						</Form.Control>
					</Form.Group>

					<AddressBlock
						address={order.mailing_address}
						setAddress={address =>
							setOrder({
								...order,
								mailing_address: address
							})
						}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="main" type="submit" disabled={!correct}>
						Update
					</Button>
					<Button variant="main" onClick={hideModal}>
						Close
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

ManageOrderModal.propTypes = {
	modalVisible: PropTypes.bool.isRequired,
	order: PropTypes.object.isRequired,
	setModalVisible: PropTypes.func.isRequired
};

export default ManageOrderModal;
