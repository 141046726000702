import axios from "axios";
import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import { ArrowLeft, InfoCircleFill } from "react-bootstrap-icons";
import { CircularProgressbar } from "react-circular-progressbar";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import {
	checkStatus,
	detailsOpportunity,
	listNumOpportunityVolunteers,
	listOpportunityVolunteers,
	signUpForOpportunity
} from "../actions/opportunityActions";
import { deleteEvent } from "../actions/userActions";
import Loading from "../components/Loading";
import ManageOpportunityStatus from "../components/ManageOpportunityStatus";

function OpportunityScreen() {
	const [isOwnPost, setIsOwnPost] = useState(false);
	const [spacesRemaining, setSpacesRemaining] = useState(-1);
	const [statuses, setStatuses] = useState({});
	const [loadingStatuses, setLoadingStatuses] = useState({});

	const { userInfo } = useSelector(state => state.userSignin);
	const { id } = useParams();
	const navigate = useNavigate();

	// list details of this opportunity
	const { opportunity, success: opportunityDetailsSuccess, loading } = useSelector(state => state.opportunityDetails);
	// list volunteers who have signedup for this opportunity, only for admin and creator of the opportunity
	const { users } = useSelector(state => state.opportunityListVolunteers);
	// list the number of volunteers. Can still be used when the user does not have permission to see the list of users
	const {
		loading: loadingNumVolunteers,
		success: numUsersSuccess,
		numUsers
	} = useSelector(state => state.opportunityListNumVolunteers);
	// check if the current user has signed up for this opportunity, only when current user is person
	const { status } = useSelector(state => state.opportunityCheckStatus);
	// status updated after person user clicks "sign up" button
	const { success: opportunitySignUpSuccess } = useSelector(state => state.opportunitySignUp);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(detailsOpportunity(id));
		dispatch(listNumOpportunityVolunteers(id));
		if (userInfo?.isPerson) dispatch(checkStatus(id, userInfo._id));
	}, [userInfo, opportunitySignUpSuccess]);

	useEffect(() => {
		if (opportunityDetailsSuccess) {
			if (userInfo && (userInfo.isAdmin || (userInfo.isOrganization && userInfo._id == opportunity.userId))) {
				dispatch(listOpportunityVolunteers(id));
				setIsOwnPost(true);
			}
		}
	}, [opportunityDetailsSuccess]);

	useEffect(() => {
		if (opportunity && !loading && !loadingNumVolunteers) {
			setSpacesRemaining(parseInt(opportunity.total_spaces) - numUsers);
		}
	}, [opportunityDetailsSuccess, numUsersSuccess]);

	useEffect(() => {
		if (users) {
			const newStatuses = {};
			const newLoadingStatuses = {};
			users.forEach(user => {
				newStatuses[user._id] = user.status;
				newLoadingStatuses[user._id] = false;
			});
			setStatuses(newStatuses);
			setLoadingStatuses(newLoadingStatuses);
		}
	}, [users]);

	const handleApply = () => {
		if (userInfo) {
			if (window.confirm("Do you want to apply for this event?")) {
				dispatch(signUpForOpportunity(id, userInfo._id, opportunity));
			}
		} else {
			const redirect = "/register";
			navigate(redirect);
		}
	};

	const cancelHandler = event => {
		if (window.confirm("Do you really want to cancel?")) {
			dispatch(deleteEvent(userInfo._id, opportunity._id));
			window.location.reload();
		}
	};

	const updateUserStatus = async (userId, status) => {
		const newLoadingStatuses = loadingStatuses;
		newLoadingStatuses[userId] = true;
		setLoadingStatuses({ ...newLoadingStatuses });
		await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/opportunities/update-user-status/`,
			{ opportunityId: opportunity._id, userId, status },
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);

		const newStatuses = statuses;
		newStatuses[userId] = status;
		setStatuses({ ...newStatuses });
		newLoadingStatuses[userId] = false;
		setLoadingStatuses({ ...newLoadingStatuses });
		dispatch(listNumOpportunityVolunteers(id));

		if (status === "signed up") {
			const { data: userDetails } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, {
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			});
			emailjs.send("Gmail", "application_accepted", {
				email: userDetails.email,
				name: userDetails.name,
				opportunityId: opportunity._id,
				opportunityName: opportunity.name
			});
		}
	};

	const showApply = !(spacesRemaining == 0 || (userInfo && (userInfo.isOrganization || userInfo.isAdmin)));
	const start = opportunity && new Date(opportunity.start_date_time);
	const end = opportunity && new Date(opportunity.end_date_time);

	const dateOptions = {
		weekday: "long",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric"
	};

	let statusDisplay = "You have ";
	if (status == "applied") statusDisplay += "applied to this opportunity";
	else if (status == "signed up") statusDisplay += "signed up for this opportunity!";
	else if (status == "complete") statusDisplay += "completed this opportunity!";
	else if (status == "rejected") statusDisplay = "Your application to this opportunity was not accepted";

	const showImages = opportunity?.images?.length > 0;
	const showVideos = opportunity?.videos?.length > 0;
	const showCarousel = showImages || showVideos;

	let carouselContent = [];
	if (showImages) {
		carouselContent = opportunity.images.map(img => <img src={img} key={img} alt="opportunity" />);
	}
	if (showVideos) {
		carouselContent = [
			...carouselContent,
			opportunity.videos.map(video => <video controls src={video} key={video} alt="opportunity" />)
		];
	}

	return (
		<>
			<Helmet>
				<title>Vower | {!loading && opportunity.name ? opportunity.name : "Opportunity"}</title>
			</Helmet>
			<span onClick={() => navigate(-1)} className="fake-anchor display-3 mb-2">
				<ArrowLeft />
			</span>
			{loading ? (
				<Loading />
			) : (
				<Container fluid>
					<Row>
						{showCarousel && (
							<Col
								xs={12}
								md={6}
								className="mb-3 d-flex flex-column justify-content-center align-items-center"
							>
								<div className="carousel-container">
									<Carousel
										dynamicHeight
										emulateTouch
										swipeable
										infiniteLoop
										showArrows
										useKeyboardArrows
									>
										{carouselContent}
									</Carousel>
								</div>
							</Col>
						)}
						<Col
							xs={12}
							md={showCarousel ? 6 : 12}
							className="d-flex flex-column mb-3 mb-lg-0 text-center text-md-left"
						>
							<div className="mb-2">
								<h1 className="mb-0">{opportunity.name}</h1>
								<div className="text-muted">From {opportunity.company_name}</div>
							</div>
							<div>
								{opportunity.remote ? (
									<h4>Remote</h4>
								) : (
									<>
										{opportunity.address?.line1 && (
											<h4>
												{opportunity.address.city}, {opportunity.address.state}
											</h4>
										)}
										<h4>
											{start.toLocaleTimeString("en-US", dateOptions)} -{" "}
											{end.toLocaleTimeString("en-US", dateOptions)}
										</h4>
									</>
								)}
							</div>
							<p className="mb-0">{opportunity.long_description}</p>
							<Row className="mt-5">
								<Col xs={12} lg={8}>
									<div className="widget-block">
										<h4>Tasks</h4>
										<div>
											{opportunity.tasks ? (
												Array.isArray(opportunity.tasks) ? (
													<ul>
														{opportunity.tasks.map(task => (
															<li key={task}>{task}</li>
														))}
													</ul>
												) : (
													opportunity.task
												)
											) : (
												"No Tasks"
											)}
										</div>
									</div>
									<div className="widget-block mt-3">
										<h4>Requirements</h4>
										<div>
											{opportunity.requirements ? (
												Array.isArray(opportunity.requirements) ? (
													<ul>
														{opportunity.requirements.map(requirement => (
															<li key={requirement}>{requirement}</li>
														))}
													</ul>
												) : (
													opportunity.requirements
												)
											) : (
												"No Requirements"
											)}
										</div>
									</div>
									<div className="h3 my-3 d-flex justify-content-center justify-content-md-start align-items-center widget-block">
										<Image
											src={window.images.vowerLogoPNG}
											style={{ width: "2.5rem", height: "auto" }}
											className="mr-3"
										/>
										<b>{opportunity.points || 0}</b>&nbsp;Points
									</div>
								</Col>
								<Col xs={12} lg={4} className="pl-lg-0">
									<div className="d-flex flex-column justify-content-center align-items-center text-center widget-block">
										{spacesRemaining != -1 &&
											(spacesRemaining == 0 ? (
												<h4 className="mb-0">No Spaces Remaining</h4>
											) : (
												<div
													className="d-flex flex-column align-items-center"
													style={{ maxWidth: "200px" }}
												>
													<CircularProgressbar
														value={100 - (spacesRemaining / opportunity.total_spaces) * 100}
														text={`${spacesRemaining} / ${opportunity.total_spaces}`}
														className="mb-2"
													/>
													<h4 className="text-center">Available Spots</h4>
												</div>
											))}
										{status && !userInfo.isOrganization ? (
											<div>
												<h5>{statusDisplay}</h5>
												{status == "applied" && (
													<Button variant="main" onClick={cancelHandler}>
														Cancel Application
													</Button>
												)}
												{status == "signed up" && (
													<Button variant="main" onClick={cancelHandler}>
														Cancel Registration
													</Button>
												)}
											</div>
										) : showApply ? (
											<Button variant="main" onClick={handleApply}>
												Apply
											</Button>
										) : (
											""
										)}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			)}

			{userInfo && (userInfo.isAdmin || isOwnPost) && (
				<>
					<hr />
					<h2>Applicants Who Have Signed Up</h2>
					<div className="volunteer-list">
						<Table responsive>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{users &&
									users.map(user => (
										<tr key={user._id}>
											<td>
												<Link to={`/profile/${user._id}`}>{user.name}</Link>
											</td>
											<td>
												<a
													href={`mailto:${user.email}`}
													target="_blank"
													rel="noopener noreferrer"
												>
													{user.email}
												</a>
											</td>
											<td>{user.phone}</td>
											<td>
												{loadingStatuses[user._id] ? (
													<BeatLoader margin="0" color="var(--main-text)" />
												) : spacesRemaining == 0 && statuses[user._id] === "applied" ? (
													<div>Maximum # of applicants accepted</div>
												) : (
													<ManageOpportunityStatus
														status={statuses[user._id]}
														updateStatus={updateUserStatus}
														userID={user._id}
													/>
												)}
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</div>
				</>
			)}
		</>
	);
}
export default OpportunityScreen;
