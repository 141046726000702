import { React } from "react";

import TopRewards from "../home/TopRewards";

function SAPIncentives() {
	return (
		<>
			<div className="sap-incentives-container mt-5 px-3 px-md-5">
				<h2>Rewards you can redeem with SAP {String.fromCodePoint(0x1f440)}...</h2>
				<TopRewards showTitle={false} />
			</div>
		</>
	);
}

export default SAPIncentives;
