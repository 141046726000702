import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { ArrowLeft, Cart, CartPlus } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { positions, useAlert } from "react-alert";
import { detailsIncentive, listIncentives } from "../actions/incentiveActions";
import { addToCart } from "../actions/cartActions";

function IncentiveScreen() {
	const { incentives, listLoading } = useSelector(state => state.incentiveList);
	const { incentive, loading } = useSelector(state => state.incentiveDetails);
	const { id } = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(detailsIncentive(id));
		dispatch(listIncentives());
	}, [id]);

	const alert = useAlert();
	const handleAddToCart = () => {
		dispatch(addToCart(id, 1));
		alert.show("Item Added to Cart!", { type: "addToCart", timeout: 2000, offset: "30px" });
	};
	const handleBuyNow = () => {
		dispatch(addToCart(id, 1));
	};

	return (
		<>
			<Helmet>
				<title>Vower | {(!loading && incentive.name) || "Incentive"}</title>
			</Helmet>
			<div className="d-flex justify-content-between mb-2">
				<div className="fake-anchor">
					<ArrowLeft onClick={() => navigate(-1)} size="3.5rem" />
				</div>
				<Link to="/cart">
					<Cart size="2.5rem" />
				</Link>
			</div>
			{!loading && (
				<Container fluid>
					<Row>
						<Col xs={12} md={6} className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
							<div className="carousel-container">
								{incentive.images && (
									<Carousel
										dynamicHeight
										emulateTouch
										swipeable
										infiniteLoop
										showArrows
										useKeyboardArrows
									>
										{incentive.images.map((img, i) => (
											<div key={`img-${i}`}>
												<img src={img} alt="incentive" />
											</div>
										))}
									</Carousel>
								)}
							</div>
						</Col>
						<Col xs={12} md={6} className="d-flex flex-column text-center text-md-left">
							<div>
								<h1>{incentive.name}</h1>
							</div>
							<p className="mb-0">{incentive.long_description}</p>
							<h4 className="mt-2 mb-0">
								<b>Stock:</b> {incentive.stock}
							</h4>
							<div className="display-4 my-4 d-flex justify-content-center justify-content-md-start align-items-center">
								<Image
									src={window.images.vowerLogoPNG}
									style={{ width: "3.5rem", height: "auto" }}
									className="mr-3"
								/>
								<b>{incentive.points}</b>&nbsp;Points
							</div>
							<div
								className="d-flex justify-content-center justify-content-md-start align-items-center"
								style={{ gap: "0.5rem" }}
							>
								{incentive.stock > 0 ? (
									<>
										<Button
											variant="main"
											className="d-flex align-items-center"
											onClick={handleAddToCart}
										>
											Add To Cart&nbsp;
											<CartPlus size="1.5rem" fill="inherit" />
										</Button>
										<Link to="/cart" onClick={handleBuyNow}>
											<Button variant="main">Buy Now</Button>
										</Link>
										<Link to="/opportunities">
											<Button variant="main" className="w-100">
												Need More Points?
											</Button>
										</Link>
									</>
								) : (
									<h3>Out of Stock</h3>
								)}
							</div>
						</Col>
					</Row>
					<h2 className="my-4">You Might Also Like:</h2>
					<Row>
						{!listLoading &&
							incentives
								.filter(incent => incent._id !== incentive._id)
								.map((incentive, i) => {
									// Shows the first four other products right now
									const show = i <= 3;
									return show ? (
										<Col xs={12} sm={6} lg={3} className="mb-4 mb-lg-0" key={incentive._id}>
											<Link to={`/incentive/${incentive._id}`}>
												<div className="listing">
													<div className="square-img-box listing-image">
														<Image src={incentive.images[0]} alt={incentive.name} />
													</div>
													<div style={{ fontSize: "1.25rem" }}>{incentive.name}</div>
													<div className="points">
														<Image
															src={window.images.vowerLogoPNG}
															className="vower-logo"
															alt="Vower"
														/>
														<span>{incentive.points}</span> Points
													</div>
												</div>
											</Link>
										</Col>
									) : (
										""
									);
								})}
					</Row>
				</Container>
			)}
		</>
	);
}
export default IncentiveScreen;
