import { createSlice } from "@reduxjs/toolkit";

let theme;

const { localStorage } = window;
const oldDeviceDefault = localStorage.getItem("deviceTheme");
// const currentDeviceDefault =
// 	window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
const currentDeviceDefault = "dark";

if (oldDeviceDefault === null) {
	// If this first time loading the site
	localStorage.setItem("deviceTheme", currentDeviceDefault);
	localStorage.setItem("userTheme", currentDeviceDefault);
	theme = currentDeviceDefault;
} else if (oldDeviceDefault !== currentDeviceDefault) {
	// If the user changed their device default
	localStorage.setItem("deviceTheme", currentDeviceDefault);
	localStorage.setItem("userTheme", currentDeviceDefault);
	theme = currentDeviceDefault;
} else {
	theme = localStorage.getItem("userTheme");
}
if (theme === "dark") document.documentElement.classList.add("dark");

const themeSlice = createSlice({
	name: "theme",
	initialState: { theme },
	reducers: {
		setTheme(state, action) {
			state.theme = action.payload;
			if (action.payload === "dark") {
				document.documentElement.classList.add("dark");
			} else document.documentElement.classList.remove("dark");
			localStorage.setItem("userTheme", action.payload);
		}
	}
});

export const { setTheme } = themeSlice.actions;
export const getTheme = state => state.theme;

export default themeSlice.reducer;
