import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Container = styled.div`
	margin: 0;
	width: 75vw;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Title = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "5vw" : "2.5vw")}, 40px);
`;

const Description = styled.p`
	font-family: Inter;
	font-weight: 400;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.1vw" : "1.6vw")}, 22px);
	text-align: center;
`;

const ItemContainer = styled.div`
	margin-top: min(2.5vh, 37.5px);
	width: min(${props => (props.$windowWidth < 500 ? "95vw" : "82vw")}, 1250px);
	justify-content: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const Item = styled.div`
	width: min(${props => (props.$windowWidth < 500 ? "35vw" : "23vw")}, 325px);
	display: flex;
	flex-direction: column;
	margin: 0px min(2vw, 45px) max(7%, 55px) min(2vw, 45px);
	padding: min(2vw, 45px) min(1.5vw, 35px) min(2vw, 45px) min(1.5vw, 35px);
	background: ${props => (props.theme == "dark" ? "linear-gradient(180deg, #351e41 0%, #20062e 100%)" : "#f6f6f6")};
	border: 1px solid ${props => (props.theme == "dark" ? "#2f133e" : "#25BEBD")};
	border-radius: 13px;
	align-items: center;
	justify-content: ${props => (props.$windowWidth < 500 ? "none" : "space-between")};
`;

const CardHeader = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: ${props => (props.$windowWidth < 500 ? "3.5vw" : "min(1.6vw, 22px)")};
	margin: 0;
	text-align: center;
	width: 80%;
`;

const LinkButton = styled(Link)`
	font-family: Inter;
	font-weight: 700;
	font-size: min(1.28vw, 16px);
	padding: 0;
	background: linear-gradient(180deg, #0fafb0 0%, #1d7a7a 100%);
	box-shadow: 0px 5px 41px 4px rgba(1, 103, 104, 0.7);
	border-radius: min(0.8vw, 8px);
	align-items: center;
	justify-content: center;
	width: 80%;
	margin-top: min(7vw, 100px);
	height: min(3vw, 45px);
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SkillContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${props => (props.$windowWidth < 500 ? "2vw" : "min(1vw, 50px)")};
`;

const SkillLink = styled.a`
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: ${props => (props.$windowWidth < 500 ? "3vw" : "min(1.2vw, 16px)")};
	padding-bottom: ${props => (props.$windowWidth < 500 ? "2vw" : "min(0.4vw, 5px)")};
	text-align: center;
	text-decoration-line: underline;
	color: #0fafb0;
`;

export default function ObtainNewSkills(props) {
	const { obtainNewSkillsRef } = props;
	const theme = useSelector(state => state.theme.theme);
	const navigate = useNavigate();
	const { windowWidth } = useWindowDimensions();
	const cards = [
		{
			id: 1,
			header: "Social Media, Marketing, Sales",
			skills: [
				{ title: "Canva", link: "/opportunity/-Mg6DstN2DJUpxNXLyi3" },
				{ title: "JasperA.I.", link: "/opportunity/-NSbpKlPVIOOV4NPt9Qf" },
				{ title: "YouTube", link: "/opportunity/-NSbt7LYn3jdqmauXkjt" },
				{ title: "Editing/Publishing", link: "/opportunity/-NSc13j-0uiGTkoSrfKX" },
				{ title: "Instagram Reels & Posts", link: "/opportunity/-N75yaArVzTKAdZVE569" },
				{ title: "Lead Generation", link: "/opportunity/-MfJzhX7RkEeM8HJrRuP" },
				{ title: "StoryTelling", link: "/opportunity/-N6ODY5it9KTU8S3mulB" }
			]
		},
		{
			id: 2,
			header: "Admin, Operations, Customer Service",
			skills: [
				{ title: "S.A.P. Courses", link: "/opportunity/-NHc-6vZfo2-mjfIl4I7" },
				{ title: "Customer Support", link: "/opportunity/-NHcwiKHf62AbebsLt7X" },
				{ title: "Zapier", link: "/opportunity/-NScEVBwQZtbPt50dpmt" },
				{ title: "Research & Development", link: "/opportunity/-MfJzhX7RkEeM8HJrRuP" },
				{ title: "Microsoft Excel", link: "/opportunity/-Mg6C6YaB9nyEkWU3uoY" },
				{ title: "Google Certifications", link: "/opportunity/-NHcvb-4bdnis3qHtn2I" }
			]
		},
		{
			id: 3,
			header: "Events & Volunteering",
			skills: [
				{ title: "Creative Problem Solving", link: "/opportunity/-Mg6BKZ3FmczRbJ0yTq0" },
				{ title: "Risk Analysis", link: "/opportunity/-NHcwAVUG5LXYmPis_Js" },
				{ title: "Time Management", link: "/opportunity/-Mg6BKZ3FmczRbJ0yTq0" },
				{ title: "Professionalism", link: "/opportunity/-NHcwiKHf62AbebsLt7X" }
			]
		}
	];
	return (
		<Container ref={obtainNewSkillsRef}>
			<Title $windowWidth={windowWidth}>OBTAIN NEW SKILLS</Title>
			<Description $windowWidth={windowWidth}>
				Step Up Your Game. Accelerate Your Development. Learn The Latest Skills On Demand.
			</Description>
			<ItemContainer $windowWidth={windowWidth}>
				{cards.map((card, index) => (
					<Item $windowWidth={windowWidth} key={card.id} theme={theme}>
						<CardHeader $windowWidth={windowWidth}>{card.header}</CardHeader>
						<SkillContainer $windowWidth={windowWidth}>
							{card.skills.map((skill, index2) => (
								<SkillLink
									$windowWidth={windowWidth}
									key={index + " " + index2}
									onClick={() => navigate(skill.link)}
									style={{ color: "#0fafb0" }}
								>
									{skill.title}
								</SkillLink>
							))}
						</SkillContainer>
						{windowWidth >= 500 && (
							<LinkButton to={"/opportunities"} style={{ color: "#FFFFFF" }}>
								LEARN MORE
							</LinkButton>
						)}
					</Item>
				))}
			</ItemContainer>
		</Container>
	);
}
