export default {
	ABOUTUS: "/about-us",
	BUYPOINTS: "/buy-points",
	CART: "/cart/:id?",
	CONTACTUS: "/contact-us",
	HOWITWORKS: "/how-it-works",
	HOWITWORKSORGANIZATION: "/how-it-works-organization",
	INCENTIVES: "/incentives",
	INCENTIVESID: "/incentive/:id",
	LOGOUT: "/logout",
	MAILINGLIST: "/mailing-list",
	MANAGECATEGORIES: "/manage-categories",
	MANAGECODES: "/manage-codes",
	MANAGEINCENTIVES: "/manage-incentives",
	MANAGEOPPORTUNITIES: "/manage-opportunities",
	MANAGEORDERS: "/manage-orders",
	MANAGEORGANIZATIONS: "/manage-organizations",
	MANAGEPERSONS: "/manage-persons",
	ORDERID: "/order/:id",
	OPPORTUNITIES: "/opportunities",
	OPPORTUNITIESID: "/opportunity/:id",
	PLACEORDER: "/place-order",
	PRIVACY: "/privacy-policy",
	PROFILEID: "/profile/:id",
	REGISTER: "/register",
	RESETPASSWORD: "/reset-password",
	SAP: "/sap",
	SIGNIN: "/sign-in",
	SHIPPING: "/shipping",
	STRIPESUCCESS: "/stripe-success",
	TERMS: "/terms"
};
