import React from "react";
import { Button } from "react-bootstrap";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div
					className="d-flex flex-column justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<h1>Something Went Wrong.</h1>
					<a href="/" className="mt-1">
						<Button variant="main">Go Home</Button>
					</a>
					<a href="/logout" className="mt-2">
						<Button variant="main">Logout & Go Home</Button>
					</a>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
