import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ArrowLeft, ArrowLeftCircleFill, ArrowRight, ArrowRightCircleFill } from "react-bootstrap-icons";
import "../assets/css/testimonials.css";

function Testimonials(props) {
	const tests = props.testimonials;

	const [selected, setSelected] = useState(0);
	const [transitioning, setTransitioning] = useState(false);
	const [userClicked, setUserClicked] = useState(false);

	const transitionLength = 500;
	const viewLength = 7500;

	const resetTestimonials = () => {
		document.querySelectorAll(".testimonial-body").forEach(test => {
			test.style.transition = "none";
			test.style.transform = "translateX(-100%)";
			// By checking the height, the css changes are applied before changing the transition backing
			const h = test.offsetHeight;
			test.style.transition = `transform ${transitionLength}ms`;
		});
		setTransitioning(false);
	};
	/**
	 * @param {Boolean} increase Whether or not the change is an increase or decrease
	 * @param {Boolean} bot Whether or not the function is being called by a bot
	 */
	const updateSelected = (increase, bot = false) => {
		if (!bot) {
			setUserClicked(true);
		}

		if (transitioning) {
			return;
		}

		if (increase) {
			setTransitioning(true);
			document.querySelectorAll(".testimonial-body").forEach(test => {
				test.style.transition = `transform ${transitionLength}ms`;
				test.style.transform = "translateX(-200%)";
			});
			setTimeout(() => {
				setSelected(selected < tests.length - 1 ? selected + 1 : 0);
				resetTestimonials();
			}, transitionLength);
		} else {
			setTransitioning(true);
			document.querySelectorAll(".testimonial-body").forEach(test => {
				test.style.transition = `transform ${transitionLength}ms`;
				test.style.transform = "translateX(0%)";
			});
			setTimeout(() => {
				setSelected(selected > 0 ? selected - 1 : tests.length - 1);
				resetTestimonials();
			}, transitionLength);
		}
	};

	const getTests = () => {
		const threeTests = [];
		threeTests.push(tests[selected > 0 ? selected - 1 : tests.length - 1]);
		threeTests.push(tests[selected]);
		threeTests.push(tests[selected < tests.length - 1 ? selected + 1 : 0]);
		return threeTests;
	};

	useEffect(() => {
		const autoScroll = setInterval(() => {
			if (!userClicked) {
				updateSelected(true, true);
			}
		}, viewLength);
		return () => clearInterval(autoScroll);
	});

	if (tests.length == 1) {
		return (
			<div className="single-testimonial" style={props.style}>
				<div>
					<h1 className="testimonial-title">Testimonials</h1>
					<h2 className="testimonial-body">
						<i>“{tests[0].text}”</i>
						<div className="testimonial-source">- {tests[0].source}</div>
					</h2>
				</div>
			</div>
		);
	}

	return (
		<div className="testimonial-carousel" style={props.style}>
			<div>
				<h1 className="testimonial-title">Testimonials</h1>
				<div className="testimonial-main">
					{getTests().map((test, i) => (
						<div className="testimonial-body" id={`testimonial-${i + 1}`} key={`testimonial-${i + 1}`}>
							<i>“{test.text}”</i>
							<div>—</div>
							<div className="testimonial-source">{test.source}</div>
						</div>
					))}
				</div>
				<button className="wrapper arrow arrow-left d-none d-sm-flex" onClick={() => updateSelected(false)}>
					<ArrowLeftCircleFill />
				</button>
				<button className="wrapper arrow arrow-right d-none d-sm-flex" onClick={() => updateSelected(true)}>
					<ArrowRightCircleFill />
				</button>
			</div>
		</div>
	);
}

Testimonials.propTypes = {
	testimonials: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			source: PropTypes.string
		})
	),
	style: PropTypes.object
};

Testimonials.defaultProps = {
	testimonials: [
		{
			text:
				"Vower provided us with competent short term labor. The Vower service is great for small and medium size businesses who need extra help now and then. ",
			source: "Clothez Minded"
		},
		{
			text:
				"Our company is a small start-up. We have been making magic for a little more than three years but had an issue finding quality help and support for some of our technical aspects. Vower in a short amount of time found us the best interns at rates we could afford. The amount of time saved and profit gained is remarkable.",
			source: "Kareem Manuel, We. Society"
		},
		{
			text:
				"Vower helped us to put on an amazing, incredible event. We couldn't have done it without them. This is a company event promoters and venues should reach out to.",
			source: "Omar Ali, Ali events @Lakewood"
		},
		{
			text:
				"We are so grateful to Vower. This was our first Digital Arts Event. Vower came and showed out. All the Volunteers came, showed up, and showed out. They were onsight and ready to go. This type of Teamwork made our dream work",
			source: "Courtney Brooks, CBrooks Art"
		},
		{
			text:
				"Thank you Vower! Our Intern has been wonderful. She is more skilled than the older man who works at a prestigious computer design company who I have been working with for years. I am thankful y’all helped me find her!",
			source: "Missy Maude, Missy McGuirk Maude Art"
		},
		{
			text: "Vower has been an important part of our growth at ImaginEd",
			source: "Martin Chaplin, ImagineED Georgia"
		},
		{
			text:
				"Vower provided us with the design help we needed for a photoshoot. They were driven,talented, and full of energy. This is what small businesses need right now",
			source: "Alexandria, The Thrift Jesus"
		}
	],
	style: {}
};

export default Testimonials;
