import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import requiredIf from "react-required-if";
import "../assets/css/sidebar.css";

function Sidebar(props) {
	return (
		<div className={props.expanded ? "expanded" : ""}>
			<div className="sidebar-shadow" onClick={() => props.setExpanded(false)} />
			<div className="sidebar">
				<div className="sidebar-collapser" onClick={() => props.setExpanded(false)}>
					&#x2715;
				</div>
				{props.useSort && (
					<>
						<h1>Sort By:</h1>
						<div className="mb-4">
							<select
								className="my-select"
								onChange={e => props.updateSort(e)}
								style={{ fontSize: "0.9rem" }}
							>
								{Object.entries(props.sortOptions).map(option => {
									const [value, title] = option;
									return (
										<option value={value} key={title}>
											{title}
										</option>
									);
								})}
							</select>
						</div>
					</>
				)}
				<h1>Filter By:</h1>
				<div>
					{Object.entries(props.filters).map(filter => {
						const [filterName, values] = filter;
						return (
							<div className="mb-4" key={filterName}>
								<h4>{filterName}</h4>
								{values.sort().map(value => (
									<Form.Check
										type="checkbox"
										label={value}
										checked={props.selectedFilter === value}
										onChange={() => props.updateSelectedFilter(filterName, value)}
										id={`${value
											.replaceAll(",", "")
											.replaceAll("&", "")
											.replaceAll(" ", "-")}-checkbox`}
										key={value}
									/>
								))}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

Sidebar.propTypes = {
	filters: PropTypes.object.isRequired,
	/** Must have the same value as the filters */
	selectedFilter: PropTypes.string.isRequired,
	/** Must have the same value as the filters */
	updateSelectedFilter: PropTypes.func.isRequired,
	useSort: PropTypes.bool,
	/** Must set useSort to use sorting functionality */
	sortOptions: requiredIf(PropTypes.object, props => props.useSort),
	/** Must set useSort to use sorting functionality */
	updateSort: requiredIf(PropTypes.func, props => props.useSort),
	expanded: PropTypes.bool.isRequired,
	setExpanded: PropTypes.func.isRequired
};

Sidebar.defaultProps = {
	useSort: false,
	sortOptions: {},
	updateSort: () => {}
};

export default Sidebar;
