import HundredBlackMenImage from "../../assets/images/HundredBlackMen.png";

const KroonzWear = {
	_id: "-NRC1yowxaw3OmZFtDZk",
	address: {
		city: "Atlanta",
		line1: "",
		line2: "",
		state: "GA",
		zip: ""
	},
	company_name: "Kroonz Wear",
	images: [
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1679551096638.jpg?alt=media&token=74046b63-9d7f-441e-8a10-be27a45b87bd"
	],
	name: "Sales Associate",
	tasks: [
		"Engage & create a customer experience worth remembering.",
		"The Sales Associate will help manage Operations, Sales, and Marketing."
	],
	compensation: "$10.00/hour",
	job_type: "Part-Time",
	is_cash: true
};

const LinwoodCourt = {
	_id: "-NOu4kgk_Zd3KcLI0cdZ",
	address: {
		city: "Atlanta",
		line1: "",
		line2: "",
		state: "GA",
		zip: ""
	},
	company_name: "Linwood Court Candles & Decor",
	images: [
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1677085626822.jpg?alt=media&token=c8c94c6c-a4d2-4c63-b845-f341586299dc"
	],
	name: "Sales Associate",
	tasks: ["Manage Operations, Sales, and In-Store Marketing; located in Citizen's Supply."],
	compensation: "$15.00/hour",
	job_type: "Part-Time",
	is_cash: true
};

const HundredBlackMen = {
	_id: "-NI4QwW3Uwfkgk7TKK1N",
	address: {
		city: "Atlanta",
		line1: "",
		line2: "",
		state: "GA",
		zip: ""
	},
	company_name: "Hundred Black Men",
	images: [HundredBlackMenImage],
	name: "Volunteer",
	tasks: ["Maintain general Hospitality.", "Confirm Tickets purchased.", "Greet guests."],
	compensation: "10 Points",
	job_type: "Volunteer",
	is_cash: false
};

const KroonzWearTwo = {
	_id: "-NRDC9t0w0vJUsPt99qp",
	address: {
		city: "",
		line1: "",
		line2: "",
		state: "",
		zip: ""
	},
	remote: true,
	company_name: "Kroonz Wear",
	images: [
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1679570544871.jpg?alt=media&token=e1c7eba6-e721-48d4-9942-68c990b7239b"
	],
	name: "Social Media & Digital Marketing Intern",
	tasks: [
		"Create Highly dynamic Digital Marketing Assets.",
		"Create videos and content that will be used for marketing and storytelling."
	],
	job_type: "Intern",
	compensation: "25 Points",
	is_cash: false
};

const SAP = {
	_id: "-NRDEs-rEZpiIGOoa7Os",
	address: {
		city: "",
		line1: "",
		line2: "",
		state: "",
		zip: ""
	},
	remote: true,
	company_name: "SAP",
	images: [
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1679571227512.jfif?alt=media&token=1154e0ac-82db-4218-a483-b4eb1849cd96"
	],
	name: "Technical Consulting Virtual Internship",
	tasks: ["Enroll in Virtual Internship Program"],
	compensation: "5 Points",
	job_type: "Virtual Internship",
	is_cash: false
};

const Vower = {
	_id: "-NREnRZ9h6cs9TSl4fH4",
	address: {
		city: "",
		line1: "",
		line2: "",
		state: "",
		zip: ""
	},
	remote: true,
	company_name: "Vower",
	images: [
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1679597356123.jpeg?alt=media&token=df70cea4-6012-4b86-a5d2-91b811023bf0"
	],
	name: "Sales & Digital Marketing Intern",
	tasks: [
		"Increasing Customer/Talent engagement and brand awareness.",
		"Deliver growth, increase conversions, and expand Vower’s engagement online as well as offline."
	],
	compensation: "50 Points",
	job_type: "Intern",
	is_cash: false
};

export { KroonzWear, LinwoodCourt, HundredBlackMen, KroonzWearTwo, SAP, Vower };
