import React from "react";

function NotFound() {
	return (
		<div className="d-flex flex-column justify-content-center align-items-center h-100">
			<img src={window.images.vowerLogoPNG} alt="vower" style={{ maxHeight: 100 }} />
			<h2>Page Not Found :(</h2>
		</div>
	);
}

export default NotFound;
