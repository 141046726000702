import axios from "axios";
import delay from "delay";
import jwt from "jsonwebtoken";
import React from "react";
import { Provider as AlertProvider } from "react-alert";
import { Provider } from "react-redux";
import "./assets/images.js";
import Route from "./route";
import store from "./store";

import "react-circular-progressbar/dist/styles.css";
import * as ReactDOMClient from "react-dom/client";
import { refreshToken } from "./actions/userActions";
import "./assets/css/fonts/Inter.css";
import "./assets/css/fonts/Poppins.css";
import "./assets/css/general.css";
import "./assets/css/index.css";
import AlertTemplate from "./components/alerts/AlertTemplate";

// Check if access jwt needs to be refreshed before request is sent out
axios.interceptors.request.use(
	async config => {
		const refreshing = store.getState()?.userRefresh?.refreshing;
		let token = store.getState()?.userSignin?.userInfo?.token;
		if (token) {
			const { exp } = jwt.decode(token);
			// If the token has expired, refresh it
			if (Date.now() >= exp * 1000) {
				if (!refreshing) {
					// If we haven't tried to refresh the token yet (which menas this is the first request), then do it
					await store.dispatch(refreshToken());
				} else {
					// If the token is already refreshing, wait for it to finish and poll every 50ms to check if it's done
					while (store.getState()?.userRefresh?.refreshing) {
						await delay(50);
					}
				}
				// Now that the token has been updated, update the request authorization to match
				token = store.getState()?.userSignin?.userInfo?.token;
				if (token && config.headers.Authorization) {
					config.headers.Authorization = `Bearer ${token}`;
				}
			}
		}
		return config;
	},
	error => {
		console.error(error);
		return Promise.reject(error);
	}
);

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
	<Provider store={store}>
		<AlertProvider template={AlertTemplate}>
			<Route />
		</AlertProvider>
	</Provider>
);
