import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Check, X } from "react-bootstrap-icons";
import { ClipLoader } from "react-spinners";

function MailingListScreen() {
	const [email, setEmail] = useState("");
	const [attempted, setAttempted] = useState(false);
	const [emailAdded, setEmailAdded] = useState(false);
	const [isOrganization, setIsOrganization] = useState(false);
	const [loading, setLoading] = useState(false);

	const addEmail = async () => {
		setLoading(true);
		try {
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/misc/add-user-to-audience`, {
				email,
				isOrganization
			});
			setEmailAdded(true);
		} catch (error) {
			setEmailAdded(false);
		}
		setAttempted(true);
		setLoading(false);
	};

	return (
		<div className="text-center">
			<h1>Sign Up For Our Mailing List!</h1>
			<div className="d-flex justify-content-center">
				<div className="mr-2">Individual</div>
				<Form.Switch
					id="is-org-toggle"
					value={isOrganization}
					onChange={e => setIsOrganization(e.target.checked)}
				/>
				<div>Organization</div>
			</div>
			<div className="d-flex justify-content-center align-items-center mt-3 mb-2">
				<Form.Group controlId="mailchimp-email" className="d-flex align-items-center mb-0 mr-3">
					<Form.Control
						type="email"
						placeholder="Email"
						value={email}
						onChange={e => !emailAdded && setEmail(e.target.value)}
						disabled={emailAdded}
					/>
				</Form.Group>
				<Button
					variant="main"
					onClick={addEmail}
					disabled={loading || email.length === 0 || emailAdded}
					className="mr-2"
					style={{ whiteSpace: "nowrap" }}
				>
					Sign Up
				</Button>
			</div>
			{attempted && (
				<div className="text-center d-flex justify-content-center align-items-center">
					{loading ? (
						<ClipLoader />
					) : emailAdded ? (
						<>
							<h3 className="mb-0">Signed Up!</h3>
							<Check fill="var(--green)" size="3rem" />
						</>
					) : (
						<>
							<h3 className="mb-0">There was an error while trying to sign you up</h3>
							<X fill="red" size="3rem" />
						</>
					)}
				</div>
			)}
		</div>
	);
}

export default MailingListScreen;
