import PropTypes from "prop-types";
import React from "react";
import { Col, Form } from "react-bootstrap";
import states from "../../constants/states";

function AddressBlock(props) {
	const { address, blockClassName, className, required, setAddress, showTitle } = props;
	return (
		<div className={className}>
			{showTitle && <h5>Address</h5>}
			<Form.Row className={`block ${blockClassName}`}>
				<Form.Group as={Col} xs={12} controlId="line1">
					<Form.Label>Line 1</Form.Label>
					<Form.Control
						required={required || address?.line2 || address?.city || address?.state || address?.zip}
						type="text"
						placeholder="Line 1"
						value={address?.line1 || ""}
						onChange={e =>
							setAddress({
								...address,
								line1: e.target.value
							})
						}
					/>
				</Form.Group>
				<Form.Group as={Col} xs={12} controlId="line2">
					<Form.Label>Line 2</Form.Label>
					<Form.Control
						type="text"
						placeholder="Line 2"
						value={address?.line2 || ""}
						onChange={e =>
							setAddress({
								...address,
								line2: e.target.value
							})
						}
					/>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="city">
					<Form.Label>City</Form.Label>
					<Form.Control
						required={required || address?.line1 || address?.line2 || address?.state || address?.zip}
						type="text"
						placeholder="City"
						value={address?.city || ""}
						onChange={e =>
							setAddress({
								...address,
								city: e.target.value
							})
						}
					/>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="state">
					<Form.Label>State</Form.Label>
					<Form.Control
						required={required || address?.line1 || address?.line2 || address?.city || address?.zip}
						as="select"
						className="custom-select"
						placeholder="State"
						value={address?.state || ""}
						onChange={e =>
							setAddress({
								...address,
								state: e.target.value
							})
						}
					>
						<option value="">Select State</option>
						{Object.entries(states).map(state => {
							const [name, abbrev] = state;
							return (
								<option value={abbrev} key={abbrev}>
									{name}
								</option>
							);
						})}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col} xs={12} lg={4} controlId="zip">
					<Form.Label>Zip</Form.Label>
					<Form.Control
						required={required || address?.line1 || address?.line2 || address?.city || address?.state}
						type="text" // so the pattern can be used
						placeholder="Zipcode"
						pattern="^\d{5}(-\d{4})?$"
						value={address?.zip || ""}
						onChange={e =>
							setAddress({
								...address,
								zip: e.target.value
							})
						}
					/>
				</Form.Group>
			</Form.Row>
		</div>
	);
}

AddressBlock.propTypes = {
	address: PropTypes.object.isRequired,
	blockClassName: PropTypes.string,
	className: PropTypes.string,
	required: PropTypes.bool,
	setAddress: PropTypes.func.isRequired,
	showTitle: PropTypes.bool
};

AddressBlock.defaultProps = {
	blockClassName: "",
	className: "",
	required: false,
	showTitle: true
};

export default AddressBlock;
