window.images = {
	contactUsHero:
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FcontactUsHero.jpeg?alt=media&token=69b9e572-69de-47ce-99d1-d0d510e7d748",
	defaultAccount:
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FdefaultAccount.png?alt=media&token=f52d1756-a8ea-4d6b-8e04-4ebf1fdafa62",
	opportunitiesHero:
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FopportunitiesHero.jpeg?alt=media&token=cafda9ee-31fa-49f1-bb34-0a1ee021c526",
	buyPointsHero:
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FplansHero.jpeg?alt=media&token=6d3a9c6f-bee2-451a-87f0-739f53524997",
	vowerLogoPNG:
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FvowerLogo.png?alt=media&token=92b62e23-4600-4702-8987-f26e51813924",
	vowerLogoWithNamePNG:
		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FvowerLogoWithName.png?alt=media&token=ee381c79-1d5a-4dfa-8d5b-611fe7649877",
	register: {
		volunteer:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fregister%2FregisterVolunteer.jpg?alt=media&token=3bdb5a7e-028b-40be-88e7-634cdddc18a3",
		organization:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fregister%2FregisterBusiness.jpg?alt=media&token=27cefa83-c426-441a-83d8-725e1794e7bf"
	},
	homescreen: {
		app: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2Fapp.png?alt=media&token=82e6aa86-063d-4798-9056-6b635f2af3aa",
		buyStuff:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2FbuyStuff.png?alt=media&token=ec847041-91be-4b3e-a86b-50ae2f319b56",
		doGoodWork:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2FdoGoodWork.jpg?alt=media&token=843ce598-cbfd-454b-b236-7cf087628911",
		heroVideo:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2Fhero.mp4?alt=media&token=d9d7de25-4032-44ee-a87e-f0b9cd63d651",
		hiw1: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2Fhiw1.png?alt=media&token=dceefd1c-a858-4d33-9d42-ea181018f82a",
		hiw2: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2Fhiw2.jpg?alt=media&token=01a0f0f8-752a-47f1-b09a-d106018d4f35",
		hiw3: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2Fhiw3.png?alt=media&token=0ef5e673-4ad2-495a-bfc0-f413f374dc06",
		timeIsCurrency:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fhomescreen%2FtimeIsCurrency.png?alt=media&token=4fd23d12-897f-45ef-8c54-a18c79d0d110"
	},
	hiwOrganization: {
		step1: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FhiwOrganization%2Fstep1.png?alt=media&token=8ffa5bd3-f3bc-470f-bdb3-872b8b9acade",
		step2: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FhiwOrganization%2Fstep2.png?alt=media&token=70ace926-186b-4f26-9acc-df43367c814c",
		step3: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FhiwOrganization%2Fstep3.png?alt=media&token=5ddcbecc-dc90-4036-87bf-89aca58855d6",
		step4: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FhiwOrganization%2Fstep4.png?alt=media&token=56b78b6b-7101-4b6f-8cb7-5987f519da16",
		step5: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FhiwOrganization%2Fstep5.png?alt=media&token=b4ee3bd3-9274-4773-af6c-7583e87160fe"
	},
	aboutUs: {
		adrian: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FaboutUs%2FAdrian_Devezin.jpg?alt=media&token=c807ff1a-aafc-4d2e-8500-9cdf84d0658f",
		danny: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FaboutUs%2FDanny_Lockhart.jpg?alt=media&token=3e74fe02-77e3-4ff3-864a-5cd76bc98427",
		james: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FaboutUs%2FJames_Narayanan.jpg?alt=media&token=395f08e2-a01e-41b4-a01d-cf39fef07139",
		rejoice:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FaboutUs%2FRejoice_Jones.jpg?alt=media&token=610ca312-fb64-40cb-917e-3af23f52dd31",
		tra: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2FaboutUs%2FTra_Ho.jpg?alt=media&token=2d5a4a39-caf8-4699-9dd7-e53832ae8be8"
	},
	sap: {
		influencer1:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FInfluencer%20video%201.mp4?alt=media&token=d4b9048c-881e-4f45-b7ed-bacce6508f4b",
		influencer2:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FInfluencer%20video%202.mov?alt=media&token=3ecf99ec-7ab9-45f6-994b-923b20151929",
		influencer3:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FInfluencer%20video%203.mp4?alt=media&token=63cbfc21-9446-4e0b-b8bb-f09de8ea0761",
		influencer4:
			"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FInfluencer%20video%204.mp4?alt=media&token=daf394d0-c658-4f21-83db-ddda28090192",
		step2: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FSTEP%202%20Complete%20Bootcamp.mp4?alt=media&token=5a55cfbb-3297-4af0-9ac6-cce7c9648492",
		step3: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FSTEP%203%20Register%20for%20Cert.png?alt=media&token=a5b11ac4-4bf7-4707-94d9-2d102149fde9",
		step4: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FSTEP%204%20Take%20exam.jpg?alt=media&token=c26d65c3-8002-4625-841d-877b14025be1",
		step5: "https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/static%2Fsap%2FSTEP%205%20certified%20learner.png?alt=media&token=cd4b6c43-ca49-42fb-a955-9563bdc3f781"
	}
};
