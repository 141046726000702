import React from "react";
import { X } from "react-bootstrap-icons";
import "../../assets/css/alert.css";

function AlertTemplate({ style, options, message, close }) {
	return (
		<div style={style} className={`alert ${options.type}`}>
			{message}
			<button onClick={close} className="exit">
				<X />
			</button>
		</div>
	);
}

export default AlertTemplate;
