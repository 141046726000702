import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

function SigninScreen() {
	const theme = useSelector(state => state.theme.theme);

	return (
		<>
			<Helmet>
				<title>Vower | About Us</title>
			</Helmet>
			<div id="about-screen" className="text-center">
				<div className="hero-container">
					<img src={window.images.register.organization} alt="people connected" />
					<div>About Us</div>
				</div>
				<Container fluid>
					<Row className="justify-content-center">
						<Col xs={11} className="mt-5 mb-5" style={{ maxWidth: "1000px" }}>
							<h1 style={{ fontSize: "3.5rem" }}>Our Mission</h1>
							<p style={{ fontSize: "1.5rem" }}>
								<span className="font-weight-bold">To connect the world through service.</span>
								<br />
								Vower was created to connect and help communities realize their true potential. Our
								products and services were created to increase sustainability and ignite economic growth
								in communities everywhere.
								<br />
								We envision a world where service can be used as currency and the time you spend
								improving the world around you is rewarded.
							</p>
						</Col>
					</Row>

					<Row className="justify-content-center text-center mb-3">
						<Col>
							<h1 style={{ fontSize: "3.5rem" }}>Our Team</h1>
						</Col>
					</Row>
					<Row className="justify-content-center text-center mb-5">
						<Col xs={12} md={3} className="d-flex align-items-center mb-2 mb-md-0">
							<div className="square-img-box w-100">
								<Image src={window.images.aboutUs.rejoice} />
							</div>
						</Col>
						<Col xs={12} md={8} lg={6} xl={5} className="mb-md-3 d-flex flex-column justify-content-center">
							<h2>Rejoice Jones</h2>
							<p>
								Michigan Native. Emory Univ Grad. Two-time entrepreneur with over 9 years of experience
								in building strategic partnerships & organizing public/private initiatives for
								non-profit organizations, schools, and small businesses. Loves to play sports, dance,
								and experiment with food as medicine.
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center text-center mb-5">
						<Col xs={12} md={{ span: 3, order: "last" }} className="d-flex align-items-center mb-2 mb-md-0">
							<div className="square-img-box w-100">
								<Image src={window.images.aboutUs.danny} />
							</div>
						</Col>
						<Col xs={12} md={8} lg={6} xl={5} className="mb-md-3 d-flex flex-column justify-content-center">
							<h2>Danny Lockhart</h2>
							<p>
								An innovative and creative mind. Always looking for ways to improve, become more
								efficient and benefit the team. Punctual, yet easy going with a passion for diversity,
								open mindedness and learning. A hard working opportunist who seeks challenge and runs
								towards the roar in all aspects of life. Well traveled and cultured with a high
								appreciation for diversity and the planet earth.
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center text-center mb-5">
						<Col xs={12} md={3} className="d-flex align-items-center mb-2 mb-md-0">
							<div className="square-img-box w-100">
								<Image src={window.images.aboutUs.tra} />
							</div>
						</Col>
						<Col xs={12} md={8} lg={6} xl={5} className="mb-md-3 d-flex flex-column justify-content-center">
							<h2>Tra Ho</h2>
							<p>
								Hotelier became a Digital Nomad. Goal-achiever and strong believer that you can do
								anything if you put your mind to it. Full-time traveler. Part-time hiker. Sometimes
								CrossFit competitors. Craziest thing I have done - camping in a remote area of
								Kazakhstan during winter.
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center text-center mb-5">
						<Col xs={12} md={{ span: 3, order: "last" }} className="d-flex align-items-center mb-2 mb-md-0">
							<div className="square-img-box w-100">
								<Image src={window.images.aboutUs.adrian} />
							</div>
						</Col>
						<Col xs={12} md={8} lg={6} xl={5} className="mb-md-3 d-flex flex-column justify-content-center">
							<h2>Adrian Devezin</h2>
							<p>
								Software developer and owner of Empowr, a non-profit that uses technology to change the
								world and the perception of black people in the media. Also a proud, cajun papa who
								loves to experiment with essential oils and is a true tea aficionado.
								<br />
								<br />
								“I’m a firm believer that education is the foundation of a successful life”
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center text-center mb-5">
						<Col xs={12} md={3} className="d-flex align-items-center mb-2 mb-md-0">
							<div className="square-img-box w-100">
								<Image src={window.images.aboutUs.james} />
							</div>
						</Col>
						<Col xs={12} md={8} lg={6} xl={5} className="mb-md-3 d-flex flex-column justify-content-center">
							<h2>James Narayanan</h2>
							<p>
								Sophomore @ Georgia Tech studying computer science. Full-Stack Developer. Interested in
								robotics. Saxophonist.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}
export default SigninScreen;
