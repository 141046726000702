import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { XSquareFill } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { saveUser } from "../../actions/userActions";
import { deleteFiles, uploadFiles } from "../../actions/fileActions";
import { storage } from "../../firebase";
import AddressBlock from "../blocks/AddressBlock";
import SocialBlock from "../blocks/SocialBlock";
import userUpdateTypes from "../../constants/userUpdateTypes";

function EditProfileModal(props) {
	const dispatch = useDispatch();

	const [user, setUser] = useState({});
	const [correct, setCorrect] = useState(false);
	const [uploading, setUploading] = useState(false);
	// the file of the new image the user uploads
	const [newImageFile, setNewImageFile] = useState(null);
	// the local url of the new image the user uploads
	const [profileImageURL, setProfileImageURL] = useState("");

	useEffect(() => {
		if (props.modalVisible) {
			setUser(props.user);
			setCorrect(true);
			if (props.user?.image) setProfileImageURL(props.user.image);
		}
	}, [props.modalVisible]);

	const handleImageUpload = async e => {
		try {
			const file = e.target.files[0];
			setProfileImageURL(URL.createObjectURL(file));
			setNewImageFile(file);
		} catch (err) {
			console.log(err);
		}
	};

	const deleteOldImage = async file_path => {
		setUploading(true);
		try {
			await dispatch(deleteFiles([file_path]));
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	const uploadNewImage = async () => {
		if (user.image) {
			// If there was an image before, delete it
			await deleteOldImage(user.image_path);
		}
		const results = await dispatch(uploadFiles([newImageFile]));
		const { filePath } = results[0];
		const firebaseURL = await storage.ref(`/${filePath}`).getDownloadURL();
		setUploading(false);
		setUser({ ...user, image: firebaseURL, image_path: filePath });
		setNewImageFile(null);
		return ({ firebaseURL, filePath });
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (!user) return;

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		// Necessary because react does not synchronously update state using the setter method
		let tempImage = user.image;
		let tempImagePath = user.image_path;
		if (user.image && !profileImageURL) {
			// The user deleted their old image
			await deleteOldImage(user.image_path);
			setUser({ ...user, image: "", image_path: "" });
			tempImage = "";
			tempImagePath = "";
		} else if (newImageFile) {
			// The user uploaded a new image
			if (newImageFile.size > 300000) {
				// new image is larger than 300kB
				alert("Please use image with size smaller than 300kB");
				return;
			}
			// uploads the new image to firebase and updates image & image_path
			const response = await uploadNewImage();
			tempImage = response.firebaseURL;
			tempImagePath = response.filePath;
		}

		setUploading(false);

		dispatch(
			saveUser(
				{
					...user,
					image: tempImage,
					image_path: tempImagePath
				},
				userUpdateTypes.SELF
			)
		);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	return (
		<Modal
			dialogClassName="manage-modal"
			size="lg"
			show={props.modalVisible}
			onHide={() => props.setModalVisible(false)}
		>
			<Form noValidate validated onSubmit={handleSubmit} onChange={handleFormChange}>
				<Modal.Body>
					<h2>Edit Profile</h2>

					{user.account_type === "organization" && (
						<Form.Group controlId="name">
							<Form.Label>Name</Form.Label>
							<Form.Control
								required
								type="text"
								value={user.name}
								onChange={e => setUser({ ...user, name: e.target.value })}
							/>
						</Form.Group>
					)}

					{user.account_type === "person" && (
						<>
							<Form.Group controlId="first-name">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									required
									type="text"
									value={user.firstName || user.name}
									onChange={e =>
										setUser({
											...user,
											firstName: e.target.value,
											name: `${e.target.value} ${user.lastName}`
										})
									}
								/>
							</Form.Group>
							<Form.Group controlId="last-name">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									required
									type="text"
									value={user.lastName}
									onChange={e =>
										setUser({
											...user,
											lastName: e.target.value,
											name: `${user.firstName} ${e.target.value}`
										})
									}
								/>
							</Form.Group>
						</>
					)}

					<Form.Group controlId="phone">
						<Form.Label>Phone</Form.Label>
						<Form.Control
							type="tel"
							inputMode="tel"
							value={user.phone}
							onChange={e => setUser({ ...user, phone: e.target.value })}
						/>
					</Form.Group>

					{user.account_type === "person" && (
						<>
							<Form.Group controlId="school_name">
								<Form.Label>School Name</Form.Label>
								<Form.Control
									type="text"
									value={user.school_name}
									onChange={e => setUser({ ...user, school_name: e.target.value })}
								/>
							</Form.Group>

							<Form.Group controlId="grade_level">
								<Form.Label>Grade Level</Form.Label>
								<Form.Control
									type="number"
									inputMode="numeric"
									min={1}
									value={user.grade_level}
									onChange={e => setUser({ ...user, grade_level: e.target.value })}
								/>
							</Form.Group>
						</>
					)}

					<AddressBlock address={user.address || {}} setAddress={address => setUser({ ...user, address })} />

					<SocialBlock social={user.social || {}} setSocial={social => setUser({ ...user, social })} />

					<Form.Group>
						<Form.Label>
							Image
							{profileImageURL && (
								<button
									className="ml-2 wrapper"
									onClick={e => {
										e.preventDefault();
										setNewImageFile(null);
										setProfileImageURL("");
									}}
								>
									<XSquareFill fill="red" size="1.5rem" />
								</button>
							)}
						</Form.Label>
						<div className="block">
							<Form.File
								id="profile-image-upload"
								className="profile-pic-input d-flex justify-content-center"
								label={
									<>
										<Image
											src={profileImageURL || window.images.defaultAccount}
											className={`mb-2 ${user.account_type === "person" && "circle"}`}
											style={{ filter: profileImageURL ? "none" : "var(--icon-invert)" }}
										/>
										<div>Upload Pic</div>
										<span style={{ fontWeight: "lighter" }}>Must be less than 300kB</span>
									</>
								}
								onChange={handleImageUpload}
								onClick={e => {
									// This allows for the same image to be uploaded two times in a row
									e.target.value = "";
								}}
								accept="image/*"
							/>
						</div>
					</Form.Group>
					<Form.Group controlId="bio">
						<Form.Label>Bio</Form.Label>
						<Form.Control
							type="text"
							value={user.bio}
							onChange={e => setUser({ ...user, bio: e.target.value })}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="main" type="submit" disabled={uploading || !correct}>
						Update
					</Button>
					<Button onClick={() => props.setModalVisible(false)} variant="main">
						Close
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

EditProfileModal.propTypes = {
	modalVisible: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	setModalVisible: PropTypes.func.isRequired
};

export default EditProfileModal;
