import React from "react";
import styled from "styled-components";
import RewardsCarousel from "./RewardsCarousel";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Container = styled.div`
	margin: 0;
	width: 75vw;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const RewardsTitle = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "5vw" : "2.5vw")}, 40px);
`;

const Description = styled.p`
	font-family: Inter;
	font-weight: 400;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.1vw" : "1.6vw")}, 22px);
	text-align: center;
`;

const CarouselContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const CarouselLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: min(${props => (props.$windowWidth < 500 ? "6vw" : "6vw")}, 60px);
	margin-right: min(${props => (props.$windowWidth < 500 ? "6vw" : "6vw")}, 60px);
`;

const RewardsLabel = styled.p`
	font-family: Inter;
	font-weight: 700;
	padding: 0;
	margin: 0;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: min(${props => (props.$windowWidth < 500 ? "3.4vw" : "1.8vw")}, 28px);
`;

const ViewAllLabel = styled(Link)`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "2.4vw" : "1.3vw")}, 17px);
	text-decoration: underline;
`;

export default function TopRewards({ showTitle = true }) {
	const { windowWidth } = useWindowDimensions();
	return (
		<Container>
			{showTitle && (
				<>
					<RewardsTitle $windowWidth={windowWidth}>TOP REWARDS</RewardsTitle>
					<Description $windowWidth={windowWidth}>
						Ways to Earn: Learn In-Demand Skills. Complete
						<span style={{ color: "#FFCC00" }}> Freelance </span>&
						<span style={{ color: "#FFCC00" }}> Gig Work.</span>
					</Description>
				</>
			)}
			<CarouselContainer>
				<CarouselLabelContainer $windowWidth={windowWidth}>
					<RewardsLabel $windowWidth={windowWidth}>Latest Rewards</RewardsLabel>
					<ViewAllLabel $windowWidth={windowWidth} to="/incentives" style={{ color: "#0fafb0" }}>
						VIEW ALL
					</ViewAllLabel>
				</CarouselLabelContainer>
				<RewardsCarousel />
			</CarouselContainer>
		</Container>
	);
}
