import React from "react";
import { Button, Form, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { editCartQuantity, removeFromCart } from "../actions/cartActions";
import "../assets/css/cart.css";

function CartScreen(props) {
	const dispatch = useDispatch();
	const { cartItems, totalCost } = useSelector(state => state.cart);

	return (
		<>
			<Helmet>
				<title>Vower | Cart</title>
			</Helmet>
			<div className="cart">
				<div className="cart-title">
					<div className="d-flex">
						<h2 className="mr-2">Shopping Cart</h2>
						<Link to="/incentives">
							<Button variant="main">Buy More</Button>
						</Link>
					</div>
					<div className="price-title"># Points</div>
				</div>
				<div className="cart-list">
					{cartItems.length === 0 ? (
						<div>Cart is empty</div>
					) : (
						cartItems.map(item => (
							<div key={item._id} className="cart-item">
								<div className="cart-image-container">
									<img src={item.image} alt={item.name} />
								</div>
								<div className="cart-item-middle">
									<div>
										<Link to={`/incentive/${item._id}`}>{item.name}</Link>
									</div>
									<div className="cart-item-actions">
										<label className="mr-1" htmlFor={`qty-select-${item._id}`}>
											Quantity:
										</label>
										<select
											className="mr-3 my-select"
											id={`qty-select-${item._id}`}
											value={item.quantity}
											onChange={e => dispatch(editCartQuantity(item._id, e.target.value))}
										>
											{[...Array(item.stock).keys()].map(x => (
												<option key={x + 1} value={x + 1}>
													{x + 1}
												</option>
											))}
										</select>
										<Button variant="main" onClick={() => dispatch(removeFromCart(item._id))}>
											Delete
										</Button>
									</div>
								</div>
								<div className="cart-item-price">
									{item.points} <span>Points</span>
								</div>
							</div>
						))
					)}
				</div>
				<div className="cart-checkout">
					<div>
						<div className="h1 mb-0">Total Cost: </div>
						<div className="d-flex align-items-center">
							<div className="h1 ml-1 mb-0" style={{ fontWeight: 400 }}>
								{totalCost}
							</div>
							<div className="h3 mb-0" style={{ fontWeight: 300 }}>
								&nbsp;Points
							</div>
						</div>
					</div>
					<Link to="/shipping">
						<Button variant="main" disabled={cartItems.length === 0}>
							Proceed to Checkout
						</Button>
					</Link>
				</div>
			</div>
		</>
	);
}

export default CartScreen;
