import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "../assets/css/Navbar.css";
import { getTheme } from "../reducers/themeReducer";
import VowerNavbar from "../components/VowerNavbar";

function CustomNavbar() {
	const { userInfo } = useSelector(state => state.userSignin);
	const { cartItems } = useSelector(state => state.cart);
	const { theme } = useSelector(getTheme);
	const location = useLocation();
	const newNavbarPages = ["/"];

	const Brand = () => (
		<Navbar.Brand as={Link} to="/">
			<img
				src={window.images.vowerLogoWithNamePNG}
				width="70"
				height="70"
				className="d-inline-block align-top"
				alt="Vower logo"
			/>
		</Navbar.Brand>
	);

	return (
		<>
			{newNavbarPages.includes(location.pathname) ? (
				<VowerNavbar />
			) : (
				<Navbar collapseOnSelect expand="md" variant={theme}>
					<div id="left-brand">
						<Brand />
					</div>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="justify-content-between align-items-end align-items-md-center">
							<div className="nav-half">
								<Nav.Link as={Link} eventKey="1" to="/opportunities">
									Opportunities
								</Nav.Link>
								<Nav.Link as={Link} eventKey="2" to="/incentives">
									{userInfo?.isPerson ? "Redeem Points" : "Shop"}
								</Nav.Link>
								<Nav.Link as={Link} eventKey="3" to="/how-it-works">
									How It Works
								</Nav.Link>
							</div>

							<div id="center-brand">
								<Brand />
							</div>

							<div className="nav-half">
								{userInfo ? (
									<>
										{userInfo.isPerson ? (
											<Nav.Link
												as={Link}
												eventKey="4"
												to="/cart"
												className="position-relative mr-1"
											>
												Cart
												<div className="cart-count">{cartItems?.length || 0}</div>
											</Nav.Link>
										) : (
											<Nav.Link as={Link} eventKey="4" to="/manage-opportunities">
												Manage Opportunities
											</Nav.Link>
										)}
										<Nav.Link as={Link} eventKey="5" to={`/profile/${userInfo._id}`}>
											My Profile
										</Nav.Link>
										<Nav.Link as={Link} eventKey="6" to="/logout">
											Logout
										</Nav.Link>
									</>
								) : (
									<>
										<Nav.Link as={Link} eventKey="4" to="/sign-in">
											Sign In
										</Nav.Link>
										<Nav.Link as={Link} eventKey="5" to="/register">
											Register
										</Nav.Link>
									</>
								)}
							</div>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			)}
		</>
	);
}

export default CustomNavbar;
