import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getTheme, setTheme } from "../../reducers/themeReducer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ToggleBG = styled(motion.a)`
	width: min(${props => (props.$windowWidth < 500 ? "14.2vw" : "6.72vw")}, 84px);
	height: min(${props => (props.$windowWidth < 500 ? "6vw" : "3.2vw")}, 40px);
	align-items: center;
	background-color: ${props => (props.theme == "dark" ? "#FFFFFF" : "#E4BE20")};
	border-radius: 100vw;
	padding-left: min(0.2vw, 2px);
	display: flex;
	z-index: 3;
`;

const ToggleFG = styled(motion.div)`
	height: min(${props => (props.$windowWidth < 500 ? "5.7vw" : "3vw")}, 35px);
	width: min(${props => (props.$windowWidth < 500 ? "5.7vw" : "3vw")}, 35px);
	background-color: ${props => (props.theme == "dark" ? "#000000" : "#FFFFFF")};
	border-radius: 100vw;
	z-index: 3;
`;

function DarkModeToggle() {
	const { theme } = useSelector(getTheme);
	const dispatch = useDispatch();
	const { windowWidth } = useWindowDimensions();

	const setDarkMode = () => {
		dispatch(setTheme(theme == "light" ? "dark" : "light"));
	};

	return (
		<ToggleBG $windowWidth={windowWidth} onClick={setDarkMode} theme={theme}>
			<ToggleFG
				$windowWidth={windowWidth}
				theme={theme}
				animate={{
					marginLeft: theme == "dark" ? "0px" : `min(${windowWidth < 500 ? "7.5vw" : "3.2vw"}, 44px)`
				}}
				transition={{
					ease: "ease",
					duration: 0.2
				}}
			/>
		</ToggleBG>
	);
}
export default DarkModeToggle;
