import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeartIconFilled from "../../assets/images/HeartIconFilled.png";
import HeartIconHollow from "../../assets/images/HeartIconHollow.png";
import MoneyIcon from "../../assets/images/MoneyIcon.svg";
import WhiteVowerLogo from "../../assets/images/WhiteVowerLogo.png";
import WorkIcon from "../../assets/images/WorkIcon.svg";
import LocationIcon from "../../assets/images/LocationIcon.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Link } from "react-router-dom";

const Item = styled(Link)`
	width: 100%;
	max-width: ${props => (props.$windowWidth < 500 ? "152px" : "400px")};
	display: flex;
	flex-direction: column;
	margin: 0px min(2vw, 40px) max(7%, 55px) min(2vw, 40px);
	padding: min(1.2vw, 15px) min(1.5vw, 20px) min(1.2vw, 15px) min(1.5vw, 20px);
	background: ${props => (props.theme == "dark" ? "linear-gradient(180deg, #351e41 0%, #20062e 100%)" : "#f6f6f6")};
	border: 1px solid ${props => (props.theme == "dark" ? "#2f133e" : "#25BEBD")};
	border-radius: 13px;
	z-index: 2;
`;

const HeartIcon = styled.img`
	max-width: min(26px, 2.2vw);
	height: min(24px, 2vw);
	user-select: none;
`;

const GigHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: ${props => (props.$windowWidth < 500 ? "12vw" : "min(7vw, 85px)")};
`;

const LogoAndName = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const LogoContainer = styled.div`
	display: inline-block;
	position: relative;
	width: 100vw;
	max-width: ${props => (props.$windowWidth < 500 ? "10vw" : "min(5.5vw, 66px)")};
`;

const HeightFiller = styled.div`
	margin-top: 100%;
`;

const CompanyLogo = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 100%;
	background-color: #777777;
`;

const CompanyNameAndLocation = styled.div`
	display: flex;
	flex-direction: column;
	height: 90%;
	justify-content: center;
	padding-left: min(0.9vw, 14px);
`;

const CompanyName = styled.p`
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: ${props => (props.$windowWidth < 500 ? "2.5vw" : "min(1.6vw, 22px)")};
`;

const Location = styled.p`
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: ${props => (props.$windowWidth < 500 ? "2vw" : "min(1vw, 15px)")};
`;

const GigBody = styled.div`
	height: ${props => (props.$windowWidth < 500 ? "42vw" : "min(28vw, 375px)")};
	overflow-y: hidden;
`;

const Title = styled.p`
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	padding-top: min(1vw, 13px);
	padding-bottom: min(1vw, 13px);
	font-size: ${props => (props.$windowWidth < 500 ? "2vw" : "min(1vw, 13px)")};
`;

const IconDescriptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: min(0.5vw, 6.5px);
`;

const Description = styled.p`
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	padding-left: min(1vw, 13px);
	font-size: ${props => (props.$windowWidth < 500 ? "2vw" : "min(1.12vw, 16px)")};
`;

const Icon = styled.img`
	max-width: ${props => (props.$windowWidth < 500 ? "3.8vw" : "min(2.2vw, 28px)")};
	filter: invert(100%);
	user-select: none;
`;

const SkillsLabel = styled.p`
	padding: min(1.5vw, 19.5px) 0px 0px 0px;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: min(1.26vw, 18px);
	font-size: ${props => (props.$windowWidth < 500 ? "2.3vw" : "min(1.26vw, 18px)")};
`;

const SkillsBody = styled.p`
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: min(1.12vw, 16px);
	font-size: ${props => (props.$windowWidth < 500 ? "2vw" : "min(1.12vw, 16px)")};
`;

const ApplyButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #25bebd;
	border-radius: 4px;
	background-color: ${props => (props.applied ? "rgba(37, 190, 189, 0.38)" : "transparent")};
	width: 100%;
	margin: min(2vw, 24px) 0px 0px 0px;
	padding: min(0.5vw, 8px);
	padding: ${props => (props.$windowWidth < 500 ? "1vw" : "min(0.5vw, 8px)")};
	user-select: none;
`;

const ApplyText = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: ${props => (props.$windowWidth < 500 ? "2.3vw" : "min(1.26vw, 18px)")};
	color: #25bebd;
	margin: 0;
	user-select: none;
`;

export default function GigCard(props) {
	const { opportunity, handleItemApply, handleItemLike } = props;
	const theme = useSelector(state => state.theme.theme);
	const [gig, setGig] = useState(null);
	const [isApplied, setIsApplied] = useState(false);
	const { userInfo } = useSelector(state => state.userSignin);
	const { windowWidth } = useWindowDimensions();

	const loadOpportunity = async () => {
		// const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/opportunities/${opportunity._id}`);
		// const sampleData = {
		// 	_id: "-NRDC9t0w0vJUsPt99qp",
		// 	address: {
		// 		city: "",
		// 		line1: "",
		// 		line2: "",
		// 		state: "",
		// 		zip: ""
		// 	},
		// 	category: "Social Media & Digital Marketing",
		// 	company_name: "Kroonz Wear",
		// 	devOnly: false,
		// 	featured: false,
		// 	images: [
		// 		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1679570544871.jpg?alt=media&token=e1c7eba6-e721-48d4-9942-68c990b7239b",
		// 		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/fMzFlCbEQzYboLc5bNoQ32JN5rg1%2Fimages%2F1679570544869.jpg?alt=media&token=79b97dbd-3a55-45c8-bcf2-bfd406442b87"
		// 	],
		// 	images_paths: [
		// 		"fMzFlCbEQzYboLc5bNoQ32JN5rg1/images/1679570544871.jpg",
		// 		"fMzFlCbEQzYboLc5bNoQ32JN5rg1/images/1679570544869.jpg"
		// 	],
		// 	long_description:
		// 		"Kroonz Wear is a Hat Company Where Every Hat Is A Crown. A Vegan leather company that provides many different varieties of colors, textures, and styles to choose from.\n\n\n\n\nIntern Benefits:\n⚙️College or School Credit, Recommendation letter\n 📍 Store Located: Citizen's Supply (Ponce City Market) Atlanta, GA\n⚙️ Working hours: 10 - 15 hours/week \n📝 Will help Build Resume. Build Transferrable Creative Skills",
		// 	name: "Social Media & Digital Marketing Intern",
		// 	points: "25",
		// 	post_date: 1679570638614,
		// 	remote: true,
		// 	requirements: [
		// 		"We are looking for a special individual who is eager to learn, build their resume with new, in-demand skills, and can represent the KROONZ WEAR Brand"
		// 	],
		// 	short_description: "",
		// 	tasks: [
		// 		"Create Highly dynamic Digital Marketing Assets, video creation and content that will be used for marketing and storytelling. "
		// 	],
		// 	total_spaces: "1",
		// 	userId: "fMzFlCbEQzYboLc5bNoQ32JN5rg1"
		// };
		// setGig(data);
		setGig(opportunity);

		if (userInfo) {
			const { data2 } = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/opportunities/checkstatus/${opportunity._id}/${userInfo._id}`,
				{
					headers: {
						Authorization: `Bearer ${userInfo.token}`
					}
				}
			);
			setIsApplied(data2);
		}
	};

	useEffect(() => {
		loadOpportunity();
	}, []);

	const hasValidAddress = gig && gig.address && gig.address.city && gig.address.state;
	const trimCompany = str => {
		var length = 25;
		var extra = str.length > length ? "..." : "";
		return str.substring(0, length) + extra;
	};

	const trimTitle = str => {
		var length = 50;
		var extra = str.length > length ? "..." : "";
		return str.substring(0, length) + extra;
	};

	return (
		gig && (
			<Item $windowWidth={windowWidth} theme={theme} to={`/opportunity/${gig._id}`}>
				<GigHeader $windowWidth={windowWidth}>
					<LogoAndName>
						<LogoContainer $windowWidth={windowWidth}>
							<HeightFiller />
							<CompanyLogo src={gig.images && gig.images[0]} />
						</LogoContainer>
						<CompanyNameAndLocation>
							<CompanyName $windowWidth={windowWidth}>{trimCompany(gig.company_name)}</CompanyName>
							{gig.remote ? (
								<Location $windowWidth={windowWidth}>Remote</Location>
							) : (
								hasValidAddress && (
									<Location $windowWidth={windowWidth}>
										{gig.address.city + ", " + gig.address.state}
									</Location>
								)
							)}
						</CompanyNameAndLocation>
					</LogoAndName>
					{/* No backend support for liked items */}
					<div style={{ visibility: "hidden" }} onClick={handleItemLike}>
						<HeartIcon alt="Heart icon" src={gig.liked ? HeartIconFilled : HeartIconHollow} />
					</div>
				</GigHeader>
				<GigBody $windowWidth={windowWidth}>
					<Title $windowWidth={windowWidth}>{trimTitle(gig.name)}</Title>
					<IconDescriptionContainer>
						<Icon
							alt="Reward"
							src={gig.is_cash ? MoneyIcon : WhiteVowerLogo}
							style={{ filter: `invert(${theme == "dark" && gig.is_cash ? 1 : 0})` }}
							$windowWidth={windowWidth}
						/>
						<Description $windowWidth={windowWidth}>{gig.compensation}</Description>
					</IconDescriptionContainer>
					<IconDescriptionContainer>
						<Icon
							$windowWidth={windowWidth}
							alt="Work"
							src={WorkIcon}
							style={{ filter: `invert(${theme == "dark" ? 1 : 0})` }}
						/>
						<Description $windowWidth={windowWidth}>{gig.job_type}</Description>
					</IconDescriptionContainer>
					<IconDescriptionContainer>
						<Icon
							$windowWidth={windowWidth}
							alt="Place"
							src={LocationIcon}
							style={{ filter: `invert(${theme == "dark" ? 1 : 0})` }}
						/>
						<Description $windowWidth={windowWidth}>
							{gig.remote ? "Remote" : gig.address.city + ", " + gig.address.state}
						</Description>
					</IconDescriptionContainer>
					<SkillsLabel $windowWidth={windowWidth}>Tasks </SkillsLabel>
					<SkillsBody $windowWidth={windowWidth}>{gig.tasks.join(" ")}</SkillsBody>
				</GigBody>
				<ApplyButton $windowWidth={windowWidth} applied={isApplied}>
					<ApplyText $windowWidth={windowWidth}>{isApplied ? "APPLIED" : "APPLY NOW"}</ApplyText>
				</ApplyButton>
			</Item>
		)
	);
}
