import { init } from "emailjs-com";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "../assets/css/App.css";

import { CustomNavbar } from "../components";
import ErrorBoundary from "../components/ErrorBoundary";
import ScrollToTop from "../components/ScrollToTop";
import VowerFooter from "../components/VowerFooter";
import * as screens from "../screens";
import ROUTES from "./routes.constant";

function App() {
	init("user_5jC9RjTEQi6MEBDiAPdDX");
	return (
		<BrowserRouter>
			<ScrollToTop />
			<div className="grid-container">
				<ErrorBoundary>
					<CustomNavbar />
					<main className="main">
						<Routes>
							<Route path={ROUTES.MANAGEORDERS} element={<screens.ManageOrdersScreen />} />
							<Route path={ROUTES.ORDERID} element={<screens.OrderScreen />} />
							<Route path={ROUTES.SHIPPING} element={<screens.ShippingScreen />} />
							<Route path={ROUTES.PLACEORDER} element={<screens.PlaceOrderScreen />} />

							<Route path={ROUTES.MANAGECATEGORIES} element={<screens.ManageCategoriesScreen />} />

							<Route path={ROUTES.MANAGEOPPORTUNITIES} element={<screens.ManageOpportunitiesScreen />} />
							<Route path={ROUTES.OPPORTUNITIES} element={<screens.OpportunitiesScreen />} />
							<Route path={ROUTES.OPPORTUNITIESID} element={<screens.OpportunityScreen />} />

							<Route path={ROUTES.MANAGEINCENTIVES} element={<screens.ManageIncentivesScreen />} />
							<Route path={ROUTES.INCENTIVES} element={<screens.IncentivesScreen />} />
							<Route path={ROUTES.INCENTIVESID} element={<screens.IncentiveScreen />} />

							<Route path={ROUTES.MANAGEPERSONS} element={<screens.ManagePersonsScreen />} />
							<Route path={ROUTES.MANAGEORGANIZATIONS} element={<screens.ManageOrganizationsScreen />} />

							<Route path={ROUTES.SIGNIN} element={<screens.SigninScreen />} />
							<Route path={ROUTES.LOGOUT} element={<screens.LogoutScreen />} />
							<Route path={ROUTES.RESETPASSWORD} element={<screens.ResetPasswordScreen />} />
							<Route path={ROUTES.REGISTER} element={<screens.RegisterScreen />} />
							<Route path={ROUTES.PROFILEID} element={<screens.ProfileScreen />} />
							<Route path={ROUTES.CART} element={<screens.CartScreen />} />

							<Route path="/" exact element={<screens.HomeScreen />} />
							<Route path={ROUTES.HOWITWORKS} exact element={<screens.HowItWorksScreen />} />
							<Route
								path={ROUTES.HOWITWORKSORGANIZATION}
								exact
								element={<screens.HowItWorksOrganizationScreen />}
							/>
							<Route path={ROUTES.BUYPOINTS} exact element={<screens.BuyPointsScreen />} />
							<Route path={ROUTES.STRIPESUCCESS} exact element={<screens.StripeSuccessScreen />} />
							<Route path={ROUTES.ABOUTUS} exact element={<screens.AboutUsScreen />} />

							<Route path={ROUTES.TERMS} element={<screens.TermsScreen />} />
							<Route path={ROUTES.PRIVACY} element={<screens.PrivacyPolicyScreen />} />
							<Route path={ROUTES.CONTACTUS} element={<screens.ContactUsScreen />} />
							<Route path={ROUTES.MAILINGLIST} element={<screens.MailingListScreen />} />

							<Route path={ROUTES.MANAGECODES} element={<screens.ManageCodesScreen />} />

							<Route path={ROUTES.SAP} element={<screens.SAP />} />

							<Route element={<screens.NotFound />} />
						</Routes>
					</main>
					<VowerFooter />
				</ErrorBoundary>
			</div>
			<CookieConsent
				style={{
					backgroundColor: "var(--main-bg)",
					color: "var(--main-text)",
					flexWrap: "nowrap",
					alignItems: "center"
				}}
				contentStyle={{ marginRight: 0, flex: "auto" }}
				buttonStyle={{ backgroundColor: "var(--sunflower)" }}
			>
				By continuing to use this site, you agree with the <Link to="/privacy-policy">cookie policy</Link>.
			</CookieConsent>
		</BrowserRouter>
	);
}

export default App;
