import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";
import { Check, X } from "react-bootstrap-icons";
import "../assets/css/manageOpportunityStatus.css";

function ManageOpportunityStatus(props) {
	return (
		<div className="manage-opportunity-status">
			{props.status === "applied" && (
				<div className="d-flex flex-wrap" style={{ gap: "0.5rem" }}>
					<Button variant="main" onClick={() => props.updateStatus(props.userID, "signed up")}>
						<Check size="1.5rem" />
						Accept Application
					</Button>
					<Button variant="main" onClick={() => props.updateStatus(props.userID, "rejected")}>
						<X size="1.5rem" strokeWidth="5px" />
						Reject Application
					</Button>
				</div>
			)}
			{props.status === "signed up" && (
				<Button variant="main" onClick={() => props.updateStatus(props.userID, "complete")}>
					<Check size="1.5rem" />
					Mark Complete
				</Button>
			)}
			{props.status === "rejected" && (
				<div className="static-status">
					<X fill="red" size="2rem" />
					Rejected
				</div>
			)}
			{props.status === "complete" && (
				<div className="static-status">
					<Check fill="var(--green)" size="2rem" />
					Completed
				</div>
			)}
		</div>
	);
}

ManageOpportunityStatus.propTypes = {
	updateStatus: PropTypes.func.isRequired,
	status: PropTypes.string.isRequired,
	userID: PropTypes.string.isRequired
};

export default ManageOpportunityStatus;
