import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { deleteCategory, listCategories } from "../../actions/categoryActions";
import ManageCategoryModal from "../../components/modals/ManageCategoryModal";

function CategoriesScreen(props) {
	const [modalVisible, setModalVisible] = useState(false);

	const { userInfo } = useSelector(state => state.userSignin);
	const { categories } = useSelector(state => state.categoryList);
	const { success: successSave } = useSelector(state => state.categorySave);
	const { success: successDelete } = useSelector(state => state.categoryDelete);

	const dispatch = useDispatch();
	useEffect(() => {
		if (userInfo?.isAdmin) {
			if (successSave) {
				setModalVisible(false);
			}
			dispatch(listCategories());
		}
	}, [successSave, successDelete]);

	const createCategory = () => {
		setModalVisible(true);
	};

	const handleDelete = category => {
		if (window.confirm("Do you really want to delete this category?")) {
			dispatch(deleteCategory(category));
		}
	};

	return (
		<>
			{!userInfo.isAdmin && <Navigate to="/" />}
			<Helmet>
				<title>Vower | Manage Categories</title>
			</Helmet>
			<div>
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<h3>Manage Categories</h3>
					<Button variant="main" onClick={createCategory}>
						Create Opportunity Category
					</Button>
				</div>

				<ManageCategoryModal modalVisible={modalVisible} setModalVisible={setModalVisible} />

				<div>
					<Table responsive>
						<thead>
							<tr>
								<th>Category Name</th>
								<th>Category Type</th>
								<th>Delete Category</th>
							</tr>
						</thead>
						<tbody>
							{categories &&
								categories.map(category => (
									<tr key={category._id}>
										<td>{category.category_name}</td>
										<td>{category.category_type}</td>
										<td>
											<Button variant="main" onClick={() => handleDelete(category)}>
												X
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}
export default CategoriesScreen;
