import axios from "axios";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Check } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { detailsOrder } from "../actions/orderActions";

function OrderScreen() {
	const orderDetails = useSelector(state => state.orderDetails);
	const { loading, error, order } = orderDetails;
	const { id } = useParams();
	const navigate = useNavigate();
	const [incentives, setIncentives] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(detailsOrder(id));
	}, []);

	async function getIncentive(id) {
		return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/incentives/${id}`);
	}

	async function getIncentiveData() {
		return Promise.all(
			order.order_items.map(item => getIncentive(item.incentive_id).then(response => response.data))
		);
	}

	useEffect(() => {
		if (order && order.order_items) {
			getIncentiveData().then(incentiveData => setIncentives(incentiveData));
		}
	}, [loading]);

	return (
		<>
			<Helmet>
				<title>Vower | Order</title>
			</Helmet>
			{loading ? (
				<div>Loading ...</div>
			) : error ? (
				<div>{error}</div>
			) : (
				<div>
					<div className="display-3 text-center">
						Your Order Has Been Placed! <Check fill="lime" size="5rem" />
					</div>
					<h1>Order Summary</h1>
					<div>
						<h3>Shipping To:</h3>
						<p>
							{order.mailing_address.line1}, {order.mailing_address.city}, {order.mailing_address.state},{" "}
							{order.mailing_address.zip}
						</p>
					</div>
					<div>
						<h3>Status:</h3>
						<p>{order.order_status}</p>
					</div>
					<div className="cart">
						<div className="cart-list">
							<div className="cart-title">
								<h3>Items Purchased:</h3>
								<div className="price-title"># Points</div>
							</div>
							{incentives &&
								(incentives.length === 0 ? (
									<div>No Items Purchased</div>
								) : (
									incentives.map((item, i) => (
										<div key={item._id} className="cart-item">
											<div className="cart-image-container">
												<img src={item.images[0]} alt="product" />
											</div>
											<div className="cart-item-middle">
												<div>
													<Link to={`/incentive/${item._id}`}>{item.name}</Link>
												</div>
												<div>
													<b>Quantity:</b> {order.order_items[i]?.quantity}
												</div>
											</div>
											<div className="cart-item-price">
												<span>
													<Image src={window.images.vowerLogoPNG} alt="vower logo" />
												</span>
												{item.points} <span>Points</span>
											</div>
										</div>
									))
								))}
						</div>
					</div>
					<div>
						<h3>Total Cost:</h3>
						<p>{order.total_cost} Vower Points</p>
					</div>
				</div>
			)}
		</>
	);
}

export default OrderScreen;
