import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function HowItWorksScreen() {
	const theme = useSelector(state => state.theme.theme);

	return (
		<>
			<Helmet>
				<title>Vower | How It Works</title>
			</Helmet>
			<div className="text-center mb-md-4" id="how-it-works-screen">
				<div className="hero-container">
					<img src={window.images.register.organization} alt="people connected" />
					<div>How To Get Involved</div>
				</div>
				<Row className="text-center justify-content-center align-items-center mt-3 mt-md-5">
					<Col xs={12} md={6} lg={5} xl={4} className="mb-3 mb-md-0">
						<Link to="/how-it-works-organization">
							<div className="position-relative">
								<div className="square-img-box">
									<Image fluid src={window.images.register.organization} />
								</div>
								<h1>Get Your Organization Involved</h1>
							</div>
						</Link>
					</Col>
					<Col xs={12} md={6} lg={5} xl={4}>
						<HashLink to="/#how-it-works">
							<div className="position-relative">
								<div className="square-img-box">
									<Image src={window.images.register.volunteer} />
								</div>
								<h1>Get Yourself Involved</h1>
							</div>
						</HashLink>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default HowItWorksScreen;
