import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import aps from "../../assets/images/aps.png";
import goodie from "../../assets/images/goodie.png";
import google from "../../assets/images/google.png";
import microsoft from "../../assets/images/microsoft.png";
import sap from "../../assets/images/sap/sap-logo.png";
import trapMusicMuseum from "../../assets/images/trapMusicMuseum.png";
import we from "../../assets/images/we.webp";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Container = styled.div`
	margin: 0;
	width: 75vw;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Title = styled.p`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "5vw" : "2.5vw")}, 40px);
	margin-top: min(${props => (props.$windowWidth < 500 ? "5vw" : "2.5vw")}, 25px);
	text-align: center;
`;

const VowerLogo = styled.img`
	width: min(${props => (props.$windowWidth < 500 ? "14vw" : "7vw")}, 90px);
`;

const PartnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100vw;
	margin-bottom: ${props => (props.$windowWidth < 500 ? "5vw" : "min(2.5vw, 25px)")};
	height: ${props => (props.$windowWidth < 500 ? "30vw" : "min(10vw, 80px)")};
	margin-bottom: ${props => (props.$windowWidth < 500 ? "10vw" : "0px")};
	flex-wrap: wrap;
`;

const PartnerImage = styled.img`
	padding-left: min(3vw, 50px);
	max-height: min(${props => (props.$windowWidth < 500 ? "20vw" : "10vw")}, 150px);
	max-width: min(${props => (props.$windowWidth < 500 ? "24vw" : "12vw")}, 450px);
`;

export default function Partners() {
	const theme = useSelector(state => state.theme.theme);
	const { windowWidth } = useWindowDimensions();

	return (
		<Container>
			<VowerLogo $windowWidth={windowWidth} src={window.images.vowerLogoPNG} />
			<Title $windowWidth={windowWidth}>PARTNERS, CUSTOMERS, COURSE CREATORS</Title>
			<PartnerContainer $windowWidth={windowWidth}>
				<PartnerImage $windowWidth={windowWidth} src={google} />
				<PartnerImage $windowWidth={windowWidth} src={sap} />
				<PartnerImage
					$windowWidth={windowWidth}
					src={we}
					style={{ filter: `invert(${theme == "dark" ? 0 : 1})` }}
				/>
				<PartnerImage
					$windowWidth={windowWidth}
					src={goodie}
					style={{ filter: `invert(${theme == "light" ? 0 : 1})` }}
				/>
				<PartnerImage $windowWidth={windowWidth} src={aps} />
				<PartnerImage $windowWidth={windowWidth} src={trapMusicMuseum} />
				<PartnerImage $windowWidth={windowWidth} src={microsoft} />
			</PartnerContainer>
		</Container>
	);
}
