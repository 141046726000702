import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import SAPHeroImage from "../../assets/images/sap/sap-hero.png";

const Tagline = styled.div`
	margin-bottom: 1rem;
	font-size: 1.5rem;
	font-weight: 600;
	text-align: center;
`;

const Hero = styled(Link)`
	position: relative;
	margin-left: -1rem;
	margin-right: -1rem;
	border: none;
	background: none;
`;

const HeroImage = styled.img`
	width: 100vw;
`;

export default function SAPHero() {
	return (
		<>
			<Tagline>HOTTEST OPPORTUNITY RIGHT NOW!</Tagline>
			<Hero to="/sap">
				<HeroImage src={SAPHeroImage} alt="SAP Hero" />
			</Hero>
		</>
	);
}
