import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function HowItWorksOrganizationScreen() {
	const { userInfo } = useSelector(state => state.userSignin);

	return (
		<>
			<Helmet>
				<title>Vower | How It Works - Organizations</title>
			</Helmet>
			<div>
				<h1 className="text-center">Find the talent your project needs</h1>
				<h2 className="text-center">How it Works</h2>
				<Container className="text-center mt-5">
					<Row className="justify-content-center align-items-center">
						<Col xs={12} md={6} className="mb-3 mb-md-0">
							<h1 style={{ color: "var(--sunflower)" }}>1. Sign Up</h1>
							<p className="how-it-works-p">Complete the registration and verification process.</p>
							{!userInfo && (
								<Link to="/register">
									<Button variant="main">Register Now</Button>
								</Link>
							)}
						</Col>
						<Col xs={12} md={6}>
							<Image src={window.images.hiwOrganization.step1} fluid />
						</Col>
					</Row>
					<hr className="divider" />
					<Row className="justify-content-center align-items-center">
						<Col xs={12} md={6} className="mb-3 mb-md-0">
							<h1 style={{ color: "var(--sunflower)" }}>2. Purchase</h1>
							<p className="how-it-works-p">
								We do things differently. With Vower, you pay for the work you need done in points. Buy
								Points here.
							</p>
							<Link to="/buy-points">
								<Button variant="main">Buy Points</Button>
							</Link>
						</Col>
						<Col xs={12} md={{ span: 6, order: "first" }}>
							<Image src={window.images.hiwOrganization.step2} fluid />
						</Col>
					</Row>
					<hr className="divider" />
					<Row className="justify-content-center align-items-center">
						<Col xs={12} md={6} className="mb-3 mb-md-0">
							<h1 style={{ color: "var(--sunflower)" }}>3. Post</h1>
							<p className="how-it-works-p">Post your opportunity.</p>
							{userInfo?.isOrganization && (
								<Link to="/manage-opportunities">
									<Button variant="main">Post Now</Button>
								</Link>
							)}
						</Col>
						<Col xs={12} md={6}>
							<Image src={window.images.hiwOrganization.step3} fluid />
						</Col>
					</Row>
					<hr className="divider" />
					<Row className="justify-content-center align-items-center">
						<Col xs={12} md={6} className="mb-3 mb-md-0">
							<h1 style={{ color: "var(--sunflower)" }}>4. Choose</h1>
							<p className="how-it-works-p">Select your talent.</p>
						</Col>
						<Col xs={12} md={{ span: 6, order: "first" }}>
							<Image src={window.images.hiwOrganization.step4} fluid />
						</Col>
					</Row>
					<hr className="divider" />
					<Row className="justify-content-center align-items-center">
						<Col xs={12} md={6} className="mb-3 mb-md-0">
							<h1 style={{ color: "var(--sunflower)" }}>5. Confirm</h1>
							<p className="how-it-works-p">Confirm the details. Get the help you need.</p>
						</Col>
						<Col xs={12} md={6}>
							<Image src={window.images.hiwOrganization.step5} fluid />
						</Col>
					</Row>
				</Container>
				{!userInfo && (
					<Container className="text-center" style={{ margin: "8rem auto" }}>
						<h1 style={{ fontSize: "3.5rem" }}>Are you ready to Vower up?</h1>
						<Link to="/register">
							<Button variant="main" className="mt-2" style={{ fontSize: "1.25rem" }}>
								Get Started
							</Button>
						</Link>
					</Container>
				)}
			</div>
		</>
	);
}

export default HowItWorksOrganizationScreen;
