export const USER_REFRESH = "USER_REFRESH";
export const USER_REFRESH_SUCCESS = "USER_REFRESH_SUCCESS";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";
export const USER_RESET_FAIL = "USER_RESET_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_SAVE_REQUEST = "USER_SAVE_REQUEST";
export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const USER_SAVE_FAIL = "USER_SAVE_FAIL";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_LIST_EVENTS_REQUEST = "USER_LIST_EVENTS_REQUEST";
export const USER_LIST_EVENTS_SUCCESS = "USER_LIST_EVENTS_SUCCESS";
export const USER_LIST_EVENTS_FAIL = "USER_LIST_EVENTS_FAIL";

export const USER_DEL_EVENT_REQUEST = "USER_DEL_EVENT_REQUEST";
export const USER_DEL_EVENT_SUCCESS = "USER_DEL_EVENT_SUCCESS";
export const USER_DEL_EVENT_FAIL = "USER_DEL_EVENT_FAIL";
