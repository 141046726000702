import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { deleteUser, listUsers } from "../../actions/userActions";
import ManageOrganizationModal from "../../components/modals/ManageOrganizationsModal";

function ManageOrganizationsScreen(props) {
	const dispatch = useDispatch();

	const [modalVisible, setModalVisible] = useState(false);
	const [org, setOrg] = useState({});

	const { userInfo } = useSelector(state => state.userSignin);
	const { users: orgs } = useSelector(state => state.userList);
	const { success: successSave } = useSelector(state => state.userSave);
	const { success: successDelete } = useSelector(state => state.userDelete);

	useEffect(() => {
		if (!userInfo) return;

		if (successSave) {
			setModalVisible(false);
		}
		dispatch(listUsers("organization"));
	}, [userInfo, successSave, successDelete]);

	const openModal = org => {
		setOrg(org);
		setModalVisible(true);
	};

	// handler to remove an organization
	const handleDelete = org => {
		if (window.confirm(`Do you really want to delete ${org.name}?`)) {
			dispatch(deleteUser(org));
		}
	};

	return (
		<>
			{!userInfo.isAdmin && <Navigate to="/" />}
			<Helmet>
				<title>Vower | Manage Organizations</title>
			</Helmet>
			<div>
				<div>
					<h3>Manage Organizations</h3>
					{/* <Button variant="main" onClick={() => openModal({})}>
						Create Organization
					</Button> */}
				</div>

				<ManageOrganizationModal modalVisible={modalVisible} setModalVisible={setModalVisible} org={org} />

				<div>
					<Table responsive>
						<thead>
							<tr>
								<th>Name</th>
								<th>Phone</th>
								<th>Email</th>
								<th>Address</th>
								<th>Points</th>
								<th>Verified</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{orgs &&
								orgs.map(org => (
									<tr key={org._id}>
										<td>
											<Link to={`/profile/${org._id}`}>{org.name}</Link>
										</td>
										<td>{org.phone}</td>
										<td>{org.email}</td>
										<td>
											{org.address?.line1 && (
												<>
													{org.address.line1}, {org.address.line2 && `${org.address.line2}, `}
													{org.address.city}, {org.address.state}, {org.address.zip}
												</>
											)}
										</td>
										<td>{org.points || 0}</td>
										<td>{org.verified ? "Yes" : "No"}</td>
										<td>
											<Button variant="main" onClick={() => openModal(org)}>
												Edit
											</Button>
										</td>
										<td>
											<Button variant="main" onClick={() => handleDelete(org)}>
												X
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}
export default ManageOrganizationsScreen;
