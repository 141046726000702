import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

function PrivacyPolicyScreen() {
	return (
		<>
			<Helmet>
				<title>Vower | Privacy Policy</title>
			</Helmet>
			<Container id="privacy-screen">
				<h1>Privacy Policy</h1>
				<p>
					Last updated: August 20, 2020.
					<br />
					This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of
					Your information when You use the Service and tells You about Your privacy rights and how the law
					protects You. We use Your Personal data to provide and improve the Service. By using the Service,
					You agree to the collection and use of information in accordance with this Privacy Policy.
					<br />
					Please read these terms and conditions carefully before using Our Service. The words of which the
					initial letter is capitalized have meanings defined under the following conditions. The following
					definitions shall have the same meaning regardless of whether they appear in singular or in plural.
					<h4>Definitions</h4>
					For the purposes of these Terms and Conditions: <b>Account</b> means a unique account created for
					You to access our Service or parts of our Service. <b>Company</b> (referred to as either the
					“Company", “We”, “Us”, or “Our” in this Agreement) refers to Vower, Inc, 6595 Roswell Rd, Suite
					G-3147, Atlanta, GA 30328. <b>Cookies</b> are small files that are placed on Your computer, mobile
					device or any other device by a website, containing the details of Your browsing history on that
					website among its many uses. <b>Country</b> refers to: Delaware, United States. <b>Device</b> means
					any device that can access the Service such as a computer, a cellphone or a digital tablet.{" "}
					<b>Personal Data</b> is any information that relates to an identified or identifiable individual.{" "}
					<b>Service</b> refers to the Website. <b>Service Provider</b> means any natural or legal person who
					processes the data on behalf of the Company. It refers to third-party companies or individuals
					employed by the Company to facilitate the Service, to provide the Service on behalf of the Company,
					to perform services related to the Service or to assist the Company in analyzing how the Service is
					used. <b>Third-party Social Media Service</b> means any services or content (including data,
					information, products or services) provided by a third-party that may be displayed, included or made
					available by the Service. <b>Website</b> refers to Vower, accessible from "
					<a href="https://www.vower.org">www.vower.org</a>". <b>You</b> means the individual accessing or
					using the Service, or the company, or other legal entity on behalf of which such individual is
					accessing or using the Service, as applicable.
					<h4>Collecting and Using Your Personal Data</h4>
					We may ask You to provide Us with certain personally identifiable information that can be used to
					contact or identify You. Personally identifiable information may include, but is not limited to:
					Email address, First and last name, Phone number, and Usage Data. Usage Data is collected
					automatically when using the Service. Usage Data may include information such as Your Device's
					Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service
					that You visit, the time and date of Your visit, the time spent on those pages, unique device
					identifiers and other diagnostic data.When You access the Service by or through a mobile device,We
					may collect certain information automatically, including, but not limited to, the type of mobile
					device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
					operating system, the type of mobile Internet browser You use, unique device identifiers and other
					diagnostic data. We may also collect information that Your browser sends whenever You visit our
					Service or when You access the Service by or through a mobile device.
					<h4>Tracking Technologies and Cookies</h4>
					We use Cookies and similar tracking technologies to track the activity on Our Service and store
					certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
					information and to improve and analyze Our Service. You can instruct Your browser to refuse all
					Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies,You may
					not be able to use some parts of our Service. Cookies can be "Persistent" or "Session" Cookies.
					Persistent Cookies remain on your personal computer or mobile device when You go offline, while
					Session Cookies are deleted as soon as You close your web browser. Learn more about cookies:{" "}
					<a href="https://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">
						All About Cookies
					</a>
					. We use both session and persistent Cookies for the purposes set out below:
					<ul>
						<li>
							<h4>Necessary/ Essential Cookies:</h4> <b>Type:</b> Session Cookies. <b>Administered by:</b>{" "}
							Us.
							<b>Purpose:</b> These Cookies are essential to provide You with services available through
							the Website and to enable You to use some of its features. They help to authenticate users
							and prevent fraudulent use of user accounts. Without these Cookies, the services that You
							have asked for cannot be provided, and We only use these Cookies to provide You with those
							services.
						</li>
						<li>
							<h4>Cookies Policy / Notice Acceptance Cookies</h4>
							<b>Type:</b> Persistent Cookies. <b>Administered by:</b> Us. <b>Purpose:</b> These Cookies
							identify if users have accepted the use of cookies on the Website.
						</li>
						<li>
							<h4>Functionality Cookies</h4> <b>Type:</b> Persistent Cookies. <b>Administered by:</b> Us.{" "}
							<b>Purpose:</b> These Cookies allow us to remember choices You make when You use the
							Website, such as remembering your login details or language preference. The purpose of these
							Cookies is to provide You with a more personal experience and to avoid You having to
							re-enter your preferences every time You use the Website.
						</li>
						<li>
							<h4>Tracking and Performance Cookies</h4> <b>Type:</b> Persistent Cookies.{" "}
							<b>Administered by:</b> Third-Parties. <b>Purpose:</b> These Cookies are used to track
							information about traffic to the Website and how users use the Website. The information
							gathered via these Cookies may directly or indirectly identify you as an individual visitor.
							This is because the information collected is typically linked to a pseudonymous identifier
							associated with the device you use to access the Website. We may also use these Cookies to
							test new pages, features or new functionality of the Website to see how our users react to
							them. For more information about the cookies we use and your choices regarding cookies,
							please visit our Cookies Policy or the Cookies section of our Privacy Policy. Use of Your
							Personal Data The Company may use Personal Data for the following purposes:
							<ul>
								<li className="mt-3">
									<b>To provide and maintain our Service</b>, including to monitor the usage of our
									Service. ‍
								</li>
								<li>
									<b>To manage Your Account:</b> to manage Your registration as a user of the Service.
									The Personal Data You provide can give You access to different functionalities of
									the Service that are available to You as a registered user.
								</li>
								<li>
									<b>For the performance of a contract:</b> the development, compliance and
									undertaking of the purchase contract for the products, items or services You have
									purchased or of any other contract with Us through the Service.
								</li>
								<li>
									<b>To contact You:</b> To contact You by email, telephone calls, SMS, or other
									equivalent forms of electronic communication, such as a mobile application's push
									notifications regarding updates or informative communications related to the
									functionalities, products or contracted services, including the security updates,
									when necessary or reasonable for their implementation.‍
								</li>
								<li>
									<b>To provide You</b> with news, special offers and general information about other
									goods, services and events which we offer that are similar to those that you have
									already purchased or inquired about unless You have opted not to receive such
									information. ‍
								</li>
								<li>
									<b>To manage Your requests:</b> To attend and manage Your requests to Us.We may
									share your personal information in the following situations: ‍
								</li>
								<li>
									<b>With Service Providers:</b> We may share Your personal information with Service
									Providers to monitor and analyze the use of our Service, to contact You. ‍
								</li>
								<li>
									<b>With Business Partners:</b> We may share or transfer Your personal information in
									connection with, or during negotiations of, any merger, sale of Company
									assets,financing, or acquisition of all or a portion of our business to another
									company. ‍With Affiliates: We may share Your information with Our affiliates, in
									which case we will require those affiliates to honor this Privacy Policy. Affiliates
									include Our parent company and any other subsidiaries, joint venture partners or
									other companies that We control or that are under common control with Us.
								</li>
								<li>
									<b>With Business partners:</b> We may share Your information with Our business
									partners to offer You certain products, services or promotions.
								</li>
								<li>
									<b>With other users:</b> when You share personal information or otherwise interact
									in the public areas with other users, such information may be viewed by all users
									and may be publicly distributed outside. If You interact with other users or
									register through a Third-Party Social Media Service, Your contacts on the
									Third-Party Social Media Service may see Your name, profile, pictures and
									description of Your activity. Similarly, other users will be able to view
									descriptions of Your activity, communicate with You and view Your profile. Retention
									of Your Personal Data The Company will retain Your Personal Data only for as long as
									is necessary for the purposes set out in this Privacy Policy. We will retain and use
									Your Personal Data to the extent necessary to comply with our legal obligations (for
									example, if we are required to retain your data to comply with applicable laws),
									resolve disputes, and enforce our legal agreements and policies. The Company will
									also retain Usage Data for internal analysis purposes. Usage Data is generally
									retained for a shorter period of time, except when this data is used to strengthen
									the security or to improve the functionality of Our Service, or We are legally
									obligated to retain this data for longer time periods.Transfer of Your Personal Data
									Your information, including Personal Data, is processed at the Company's operating
									offices and in any other places where the parties involved in the processing are
									located. It means that this information may be transferred to — and maintained on —
									computers located outside of Your state,province, country or other governmental
									jurisdiction where the data protection laws may differ than those from Your
									jurisdiction.Your consent to this Privacy Policy followed by Your submission of such
									information represents Your agreement to that transfer.The Company will take all
									steps reasonably necessary to ensure that Your data is treated securely and in
									accordance with this Privacy Policy and no transfer of Your Personal Data will take
									place to an organization or a country unless there are adequate controls in place
									including the security of Your data and other personal information. Disclosure of
									Your Personal Data Business Transactions If the Company is involved in a merger,
									acquisition or asset sale, Your Personal Data may be transferred. We will provide
									notice before Your Personal Data is transferred and becomes subject to a different
									Privacy Policy. Law enforcement Under certain circumstances, the Company may be
									required to disclose Your Personal Data if required to do so by law or in response
									to valid requests by public authorities (e.g. a court or a government agency).
								</li>
							</ul>
						</li>
					</ul>
					<h4>Other legal requirements</h4>
					The Company may disclose Your Personal Data in the good faith belief that such action is necessary
					to:
					<ul>
						<li>Comply with a legal obligation</li>
						<li>Protect and defend the rights or property of the Company</li>
						<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
						<li>Protect the personal safety of Users of the Service or the public</li>
						<li>Protect against legal liability Security of Your Personal Data</li>
					</ul>
					The security of Your Personal Data is important to Us, but remember that no method of transmission
					over the Internet, or method of electronic storage is 100% secure. While We strive to use
					commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
					security.Detailed Information on the Processing of Your Personal Data Service Providers have access
					to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or
					use it for any other purpose.
					<h4>Analytics</h4>
					We may use third-party Service providers to monitor and analyze the use of our Service.{" "}
					<b>Google Analytics</b> is a web analytics service offered by Google that tracks and reports website
					traffic. Google uses the data collected to track and monitor the use of our Service. This data is
					shared with other Google services. Google may use the collected data to contextualize and
					personalize the ads of its own advertising network.You can opt-out of having made your activity on
					the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on.
					The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing
					information with Google Analytics about visits activity.For more information on the privacy
					practices of Google, please visit the Google Privacy & Terms web page:{" "}
					<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
						https://policies.google.com/privacy
					</a>
					<h4>Your California Privacy Rights (California's Shine the Light Law)</h4>
					Under California Civil Code Section 1798 (California's Shine the Light law), California residents
					with an established business relationship with us can request information once a year about sharing
					their Personal Data with third parties for the third parties' direct marketing purposes.If you'd
					like to request more information under the California Shine the Light law, and if you are a
					California resident, You can contact Us using the contact information provided below.California
					Privacy Rights for Minor Users (California Business and Professions Code Section 22581)California
					Business and Professions Code section 22581 allow California residents under the age of 18 who are
					registered users of online sites, services or applications to request and obtain removal of content
					or information they have publicly posted.To request removal of such data, and if you are a
					California resident, You can contact Us using the contact information provided below, and include
					the email address associated with Your account. Be aware that Your request does not guarantee
					complete or comprehensive removal of content or information posted online and that the law may not
					permit or require removal in certain circumstances. Links to Other Websites. Our Service may contain
					links to other websites that are not operated by Us. If You click on a third party link, You will be
					directed to that third party's site. We strongly advise You to review the Privacy Policy of every
					site You visit.We have no control over and assume no responsibility for the content, privacy
					policies or practices of any third party sites or services. Changes to this Privacy Policy We may
					update our Privacy Policy from time to time. We will notify You of any changes by posting the new
					Privacy Policy on this page.We will let You know via email and/or a prominent notice on Our Service,
					prior to the change becoming effective and update the "Last updated" date at the top of this Privacy
					Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this
					Privacy Policy are effective when they are posted on this page.
					<h4>Contact Us</h4>
					If you have any questions about this Privacy Policy, You can contact us by email at{" "}
					<a href="mailto:info@vower.org" target="_blank" rel="noopener noreferrer">
						info@vower.org
					</a>
				</p>
			</Container>
		</>
	);
}

export default PrivacyPolicyScreen;
