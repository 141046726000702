import PropTypes from "prop-types";
import React from "react";
import { ClipLoader } from "react-spinners";

function Loading(props) {
	return (
		<div className={`mt-3 ml-auto mr-auto text-center d-flex flex-column align-items-center ${props.className}`}>
			<ClipLoader />
			<div className="mt-3 h4">{props.text}</div>
		</div>
	);
}

Loading.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string
};

Loading.defaultProps = {
	className: "",
	text: "Loading..."
};

export default Loading;
