import React from "react";
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "../assets/css/social.css";
import styled from "styled-components";

const Footer = styled.div`
	grid-area: footer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #000000;
	width: 100vw !important;
	padding: 2vw 5vw 2vw 5vw;
	margin: 0px;
`;

const MainSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
`;

const LeftSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 3vw;
`;

const VowerLogo = styled.img`
	height: min(15vw, 110px);
`;

const SocialSection = styled.div`
	display: flex;
	width: min(50vw, 225px);
	height: min(12vw, 65px);
	flex-direction: column;
	justify-content: space-between;
	margin-left: min(5vw, 40px);
`;

const IconDescription = styled.p`
	font-family: Inter;
	font-weight: 600;
	font-size: min(3vw, 18px);
	margin: 0;
	color: #ffffff;
`;

const IconRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const RightSection = styled.div`
	display: flex;
	flex-direction: row;
	width: min(70vw, 500px);
	justify-content: space-between;
`;

const LinkSection = styled.div`
	display: flex;
	flex-direction: column;
`;

const LinkHeader = styled.p`
	font-family: Inter;
	font-weight: 600;
	font-size: min(3vw, 18px);
	margin-bottom: min(1vw, 10px);
	color: #ffffff;
`;

const LinkText = styled(Link)`
	font-family: Inter;
	font-weight: 400p;
	font-size: min(3vw, 18px);
	margin-bottom: min(0.5vw, 5px);
`;

const CopyrightDisclaimer = styled.p`
	font-family: Inter;
	font-weight: 400p;
	font-size: min(3vw, 18px);
	margin-bottom: min(0.5vw, 5px);
	margin-top: 3vw;
	color: #ffffff;
`;

export default function VowerFooter() {
	return (
		<Footer>
			<MainSection>
				<LeftSection>
					<Link to="/">
						<VowerLogo src={window.images.vowerLogoPNG} alt="vower" />
					</Link>
					<SocialSection>
						<IconDescription>Follow us </IconDescription>
						<IconRow>
							<a href="https://www.instagram.com/thevowerinc/" target="_blank" rel="noopener noreferrer">
								<Instagram className="social-icon" />
							</a>
							<a
								href="https://www.youtube.com/channel/UCCJtCqrFxQd35owHTOTIHJw"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Youtube className="social-icon" />
							</a>
							<a href="https://www.linkedin.com/in/vower/" target="_blank" rel="noopener noreferrer">
								<Linkedin className="social-icon" />
							</a>
							<a href="https://twitter.com/TheVowerInc" target="_blank" rel="noopener noreferrer">
								<Twitter className="social-icon" />
							</a>
							<a href="https://www.facebook.com/TheVowerInc/" target="_blank" rel="noopener noreferrer">
								<Facebook className="social-icon" />
							</a>
						</IconRow>
					</SocialSection>
				</LeftSection>
				<RightSection>
					<LinkSection>
						<LinkHeader style={{ color: "#FFFFFF" }}>For Talents</LinkHeader>
						<LinkText style={{ color: "#FFFFFF" }} to="/opportunities">
							Find gigs
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/incentives">
							Shop
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/how-it-works">
							Learn more
						</LinkText>
					</LinkSection>
					<LinkSection>
						<LinkHeader>For Customers</LinkHeader>
						<LinkText style={{ color: "#FFFFFF" }} to="/how-it-works">
							How it works
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/buy-points">
							Pricing
						</LinkText>
					</LinkSection>
					<LinkSection>
						<LinkHeader>Vower</LinkHeader>
						<LinkText style={{ color: "#FFFFFF" }} to="/about-us">
							About us
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/terms">
							Terms
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/privacy-policy">
							Privacy
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/contact-us">
							Contact us
						</LinkText>
						<LinkText style={{ color: "#FFFFFF" }} to="/mailing-list">
							Mailing list
						</LinkText>
					</LinkSection>
				</RightSection>
			</MainSection>
			<CopyrightDisclaimer>All rights reserved © 2022 Vower</CopyrightDisclaimer>
		</Footer>
	);
}
