/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { listCategories } from "../../actions/categoryActions";
import { deleteOpportunity, listOwnOpportunities } from "../../actions/opportunityActions";
import { detailsUser } from "../../actions/userActions";
import ManageOpportunityModal from "../../components/modals/ManageOpportunityModal";

function OpportunitiesScreen(props) {
	const dispatch = useDispatch();

	const { userInfo } = useSelector(state => state.userSignin);
	const { user } = useSelector(state => state.userDetails);
	const { opportunities } = useSelector(state => state.opportunityListOwn);
	const { success: successSave, error: errorSave } = useSelector(state => state.opportunitySave);
	const { success: successDelete } = useSelector(state => state.opportunityDelete);
	const { categories } = useSelector(state => state.categoryList);

	const [modalVisible, setModalVisible] = useState(false);
	const [opportunity, setOpportunity] = useState({});

	useEffect(() => {
		if (!userInfo) return;

		dispatch(listCategories("opportunity_category"));
		if (userInfo.isAdmin || userInfo.isOrganization) {
			if (successSave) {
				setModalVisible(false);
			}
			dispatch(listOwnOpportunities());
		}
		if (userInfo.isOrganization) {
			dispatch(detailsUser(userInfo._id));
		}
	}, [userInfo, successSave, successDelete]);

	const openModal = opportunity => {
		setModalVisible(true);
		setOpportunity(opportunity);
	};

	const handleDelete = opportunity => {
		if (window.confirm("Do you really want to delete this opportunity?\nYou will NOT be refunded points.")) {
			dispatch(deleteOpportunity(opportunity));
		}
	};

	const dateOptions = {
		weekday: "long",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric"
	};

	return (
		<>
			{!userInfo?.isAdmin && !userInfo?.isOrganization && <Navigate to="/sign-in" />}
			<Helmet>
				<title>Vower | Manage Opportunities</title>
			</Helmet>
			<div>
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<h3>Manage Opportunities</h3>
					<div className="d-flex align-items-center">
						{!userInfo?.isAdmin && userInfo?.isOrganization && (
							<div className="mr-3">
								<b>Points Remaining:</b> {user?.points}
							</div>
						)}
						{(userInfo?.isAdmin || (userInfo?.isOrganization && user?.verified)) && (
							<Button variant="main" onClick={() => openModal({})}>
								Create Opportunity
							</Button>
						)}
					</div>
				</div>

				<ManageOpportunityModal
					opportunity={opportunity}
					categories={categories}
					userInfo={userInfo}
					user={user}
					error={errorSave}
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
				/>

				<Table responsive>
					<thead className="position-sticky ">
						<tr>
							<th>Name</th>
							<th>Actions</th>
							<th>Category</th>
							{userInfo?.isAdmin && <th>Company Name</th>}
							<th>Remote</th>
							<th>Address</th>
							<th>Points</th>
							<th># of Participants</th>
							<th>Start</th>
							<th>End</th>
							<th>Post Date</th>
							<th>Description</th>
							{userInfo?.isAdmin && (
								<>
									<th>Featured</th>
									<th>Development Only</th>
								</>
							)}
						</tr>
					</thead>
					<tbody>
						{opportunities &&
							opportunities.map(opportunity => (
								<tr key={opportunity._id}>
									<td>
										<Link to={`/opportunity/${opportunity._id}`}>{opportunity.name}</Link>
									</td>
									<td
										className="d-flex justify-content-center align-items-center"
										style={{ gap: "0.5rem" }}
									>
										<Button variant="main" onClick={() => openModal(opportunity)}>
											Edit
										</Button>
										<Button variant="main" onClick={() => handleDelete(opportunity)}>
											Delete
										</Button>
									</td>
									<td>{opportunity.category}</td>
									{userInfo?.isAdmin && <td>{opportunity.company_name}</td>}
									<td>{opportunity.remote ? "Yes" : "No"}</td>
									<td>
										{opportunity.remote
											? "N/A"
											: opportunity.address?.line1 && (
													<>
														{opportunity.address.line1},{" "}
														{opportunity.address.line2 && `${opportunity.address.line2}, `}
														{opportunity.address.city}, {opportunity.address.state},{" "}
														{opportunity.address.zip}
													</>
											  )}
									</td>
									<td>{opportunity.points}</td>
									<td>{opportunity.total_spaces}</td>
									<td>
										{opportunity.remote
											? "N/A"
											: new Date(opportunity.start_date_time).toLocaleTimeString(
													"en-US",
													dateOptions
											  )}
									</td>
									<td>
										{opportunity.remote
											? "N/A"
											: new Date(opportunity.end_date_time).toLocaleTimeString(
													"en-US",
													dateOptions
											  )}
									</td>
									<td>{new Date(opportunity.post_date).toLocaleTimeString("en-US", dateOptions)}</td>
									<td className="shorten-description">{opportunity.long_description}</td>
									{userInfo?.isAdmin && (
										<>
											<td>{opportunity.featured ? "Yes" : "No"}</td>
											<td>{opportunity.devOnly ? "Yes" : "No"}</td>
										</>
									)}
								</tr>
							))}
					</tbody>
				</Table>
			</div>
		</>
	);
}
export default OpportunitiesScreen;
