import {
	CART_ADD_ITEM,
	CART_CLEAR,
	CART_EDIT_QUANTITY,
	CART_REMOVE_ITEM,
	CART_SAVE_SHIPPING,
	CART_SAVE_ADDRESS_SELECTION
} from "../constants/cartConstants";

function cartReducer(state = { cartItems: [], shipping: {}, totalCost: 0, addressSelection: "new" }, action) {
	let cartItems;
	let totalCost;
	switch (action.type) {
		case CART_ADD_ITEM:
			const newItem = action.payload;
			const alreadyInCart = state.cartItems.find(item => item._id === newItem._id);
			// If the item is already in the cart, increase it's quantity by one instead of adding it to the array
			if (alreadyInCart) {
				totalCost = 0;
				cartItems = state.cartItems.map(item => {
					if (item._id === newItem._id) {
						totalCost += item.points * (item.quantity + newItem.quantity);
						return { ...item, quantity: item.quantity + newItem.quantity };
					}
					totalCost += item.points * item.quantity;
					return item;
				});
			} else {
				cartItems = [...state.cartItems, newItem];
				totalCost = state.totalCost + newItem.points * newItem.quantity;
			}
			return { ...state, cartItems, totalCost };
		case CART_CLEAR:
			return { ...state, cartItems: [], totalCost: 0 };
		case CART_EDIT_QUANTITY:
			totalCost = 0;
			cartItems = state.cartItems.map(item => {
				let updatedItem = item;
				if (item._id === action.payload._id) {
					updatedItem = { ...item, quantity: action.payload.quantity };
				}
				totalCost += updatedItem.points * updatedItem.quantity;
				return updatedItem;
			});
			return { ...state, cartItems, totalCost };
		case CART_REMOVE_ITEM:
			totalCost = 0;
			cartItems = state.cartItems.filter(item => {
				const keep = item._id !== action.payload;
				if (keep) {
					totalCost += item.points * item.quantity;
				}
				return keep;
			});
			return { ...state, cartItems, totalCost };
		case CART_SAVE_SHIPPING:
			return { ...state, shipping: action.payload.address };
		case CART_SAVE_ADDRESS_SELECTION:
			return { ...state, addressSelection: action.payload };
		default:
			return state;
	}
}

export { cartReducer };
