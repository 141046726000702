import React, { useEffect, useState } from "react";
import {
	Alert,
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	FormControl,
	Image,
	InputGroup,
	Row,
	SplitButton
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../actions/userActions";
import "../assets/css/forms.css";
import CustomRecaptcha from "../components/CustomRecaptcha";
import Loading from "../components/Loading";
import SlidingSelector from "../components/SlidingSelector";

function escapeRegex(string) {
	return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const socialObj = {
	twitter: "",
	instagram: "",
	linkedin: "",
	tiktok: "",
	facebook: "",
	portfolio: "",
	website: ""
};

function RegisterScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [account_type, setAccountType] = useState("person");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [orgName, setOrgName] = useState("");
	const [imageURL, setImageURL] = useState("");
	const [email, setEmail] = useState("");
	const [currentSocial, setCurrentSocial] = useState("twitter");
	const [currentSocialTitle, setCurrentSocialTitle] = useState("social");
	const [social, setSocial] = useState(socialObj);
	const [socialPlaceholder, setSocialPlaceholder] = useState("");
	const [socialPattern, setSocialPattern] = useState("");
	const [bio, setBio] = useState("");
	const [password, setPassword] = useState("");
	const [rePassword, setRePassword] = useState("");
	const [validated, setValidated] = useState(false);

	const [justRegisteredOrg, setJustRegisteredOrg] = useState(false);

	const [isHuman, setIsHuman] = useState(false);
	const { userInfo, loading, error } = useSelector(state => state.userSignin);

	const [image_file, setImageFile] = useState("");
	const [file_name, setFileName] = useState("");
	const [isAvatarUp, setIsAvatarUp] = useState(false);
	const [invalidPictureSubmit, setInvalidPictureSubmit] = useState(false);

	const isPerson = account_type === "person";
	const isOrganization = account_type === "organization";

	useEffect(() => {
		if (userInfo) {
			navigate(`/profile/${userInfo._id}`, { justRegisteredOrg });
			if (userInfo.isPerson) {
				navigate("/opportunities");
			}
		}
	}, [userInfo]);

	useEffect(() => {
		if (isOrganization) {
			setCurrentSocial("website");
			setCurrentSocialTitle("website");
			setSocialPlaceholder("yourwebsite.com");
			setSocialPattern(null);
		} else {
			setCurrentSocial("twitter");
			setCurrentSocialTitle("social");
			setSocialPlaceholder("");
			setSocialPattern("");
		}
	}, [account_type]);

	const handleImageUpload = e => {
		setImageURL(URL.createObjectURL(e.target.files[0]));
		const image = e.target.files[0];
		setImageFile(image);
		setIsAvatarUp(true);
	};

	let name = "";
	if (isPerson) {
		name = `${firstName} ${lastName}`;
	} else if (isOrganization) {
		name = orgName;
	}

	const handleValidate = e => {
		const form = e.currentTarget;

		if (form.checkValidity() === true) {
			setValidated(true);
		}
	};
	const disabled = !isHuman;

	const handleSubmit = e => {
		e.preventDefault();

		if (disabled) {
			return;
		}

		if (image_file) {
			setFileName(`${Date.now()}.${image_file.name.split(".").pop()}`);
			setInvalidPictureSubmit(false);
		} else {
			setInvalidPictureSubmit(true);
		}

		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			setInvalidPictureSubmit(true);
			e.stopPropagation();
		} else {
			if (isOrganization) {
				setJustRegisteredOrg(true);
			}

			dispatch(
				register(
					name,
					firstName,
					lastName,
					email,
					password,
					account_type,
					imageURL,
					image_file,
					file_name,
					bio,
					social
				)
			);
		}

		setValidated(true);
	};

	const handleSocialChange = input => {
		setCurrentSocial(input.target.name);
		setCurrentSocialTitle(input.target.name);

		switch (input.target.name) {
			case "twitter":
				setSocialPlaceholder("Twitter Handle");
				setSocialPattern("[0-9a-zA-Z_]{4,15}");
				break;
			case "instagram":
				setSocialPlaceholder("Instagram Username");
				setSocialPattern("^[\\w](?!.*?\\.{2})[\\w.]{1,28}[\\w]$");
				break;
			case "linkedin":
				setSocialPlaceholder("linkedin.com/in/profile/");
				setSocialPattern(
					"^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
				);
				break;

			case "facebook":
				setSocialPlaceholder("Facebook Username");
				setSocialPattern("^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:.(?!.))){0,28}(?:[A-Za-z0-9_]))?)$");
				break;

			case "tiktok":
				setSocialPlaceholder("TikTok Profile");
				setSocialPattern("[0-9a-zA-Z_]{4,15}");
				break;
			case "portfolio":
				setSocialPlaceholder("yourwebsite.com");
				setSocialPattern(null);
				break;
			case "website":
				setSocialPlaceholder("yourwebsite.com");
				setSocialPattern(null);
				break;
			default:
				undefined;
		}
	};
	const handleSocialInput = input => {
		setSocial({
			...social,
			[`${currentSocial}`]: input.target.value
		});
	};
	return (
		<div id="register-screen">
			<Container fluid>
				<Row className="justify-content-center mb-3 text-center">
					<h1>Are You Ready to Vower Up?</h1>
				</Row>
				<Row className="justify-content-center mb-3">
					<Col
						xs={12}
						className="d-flex justify-content-center justify-content-md-start"
						style={{ maxWidth: 800 }}
					>
						<SlidingSelector
							choices={["Person", "Organization"]}
							currentChoice={account_type}
							setCurrentChoice={newChoice => setAccountType(newChoice.toLowerCase())}
						/>
					</Col>
				</Row>
				{(isOrganization || isPerson) && (
					<Row className="justify-content-center">
						<Col xs={12} style={{ maxWidth: 800 }}>
							<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
								className="d-flex flex-column justify-content-center"
								onChange={handleValidate}
							>
								<Row>
									<Col xs={12} sm={8}>
										{isOrganization && (
											<Form.Group controlId="register-organization-input">
												<Form.Control
													required
													type="text"
													placeholder="Organization Name"
													value={orgName}
													onChange={input => setOrgName(input.target.value)}
												/>
												<Form.Control.Feedback type="invalid">
													Please enter the name of your organization
												</Form.Control.Feedback>
											</Form.Group>
										)}

										{isPerson && (
											<>
												<Form.Group controlId="register-firstname-input">
													<Form.Control
														required
														type="text"
														placeholder="First Name"
														value={firstName}
														onChange={input => setFirstName(input.target.value)}
													/>
													<Form.Control.Feedback type="invalid">
														Please enter your first name
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="register-lastname-input">
													<Form.Control
														required
														type="text"
														placeholder="Last Name"
														value={lastName}
														onChange={input => setLastName(input.target.value)}
													/>
													<Form.Control.Feedback type="invalid">
														Please enter your last name
													</Form.Control.Feedback>
												</Form.Group>
											</>
										)}
										<Form.Group controlId="register-email-input">
											<Form.Control
												required
												type="email"
												placeholder="Email"
												value={email}
												onChange={input => setEmail(input.target.value)}
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a valid email
											</Form.Control.Feedback>
										</Form.Group>
										<InputGroup className="mb-3">
											<SplitButton
												variant="outline-secondary"
												title={currentSocialTitle.replace(/^\w/, c => c.toUpperCase())}
												id="segmented-button-dropdown-1"
											>
												<Dropdown.Item
													value="twitter"
													name="twitter"
													onClick={handleSocialChange}
												>
													Twitter
												</Dropdown.Item>
												<Dropdown.Item
													value="instagram"
													name="instagram"
													onClick={handleSocialChange}
												>
													Instagram
												</Dropdown.Item>
												<Dropdown.Item
													value="linkedin"
													name="linkedin"
													onClick={handleSocialChange}
												>
													Linkedin
												</Dropdown.Item>
												<Dropdown.Item
													value="tiktok"
													name="tiktok"
													onClick={handleSocialChange}
												>
													TikTok
												</Dropdown.Item>
												<Dropdown.Item
													value="facebook"
													name="facebook"
													onClick={handleSocialChange}
												>
													Facebook
												</Dropdown.Item>
												<Dropdown.Item
													value={isPerson ? "portfolio" : "website"}
													name={isPerson ? "portfolio" : "website"}
													onClick={handleSocialChange}
												>
													{isPerson ? "Portfolio" : "Website"}
												</Dropdown.Item>
											</SplitButton>
											<FormControl
												value={social[`${currentSocial}`]}
												onChange={handleSocialInput}
												aria-label="Text input with dropdown button"
												required
												type="text"
												placeholder={socialPlaceholder}
												pattern={socialPattern}
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a valid social media account
											</Form.Control.Feedback>
										</InputGroup>
									</Col>
									<Col
										xs={12}
										sm={4}
										className="px-2 d-flex flex-column justify-content-center align-items-center text-center"
									>
										<Form.Group controlId="upload-image-input">
											<Form.File
												id="profile-image-upload"
												required
												className="profile-pic-input"
												label={
													<>
														<Image
															src={imageURL || window.images.defaultAccount}
															className={`mb-2 profile-image ${isPerson && "circle"}`}
															style={{ filter: imageURL ? "none" : "var(--icon-invert)" }}
														/>
														<div>{isPerson ? "Upload Pic" : "Upload Logo"}</div>
													</>
												}
												onChange={handleImageUpload}
												accept="image/*"
											/>
											{!isAvatarUp && isPerson && (
												<span
													style={{ fontWeight: "light" }}
													className={`${invalidPictureSubmit && "text-warning"}`}
												>
													Avatar Required
												</span>
											)}
											{!isAvatarUp && isOrganization && (
												<span
													className={`${invalidPictureSubmit && "text-warning"}`}
													style={{ fontWeight: "light" }}
												>
													Required
												</span>
											)}
										</Form.Group>
									</Col>
								</Row>
								<Form.Group controlId="register-password-input">
									<Form.Control
										required
										type="password"
										placeholder="Password"
										value={password}
										onChange={input => setPassword(input.target.value)}
										pattern="[\w!@#$%^&*]{8,25}"
									/>
									<Form.Control.Feedback type="invalid">
										<div>Please enter a password (8 to 25 characters)</div>
										<div>Can contain lowercase & uppercase letters, numbers, and symbols</div>
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="register-repassword-input">
									<Form.Control
										required
										type="password"
										placeholder="Confirm Password"
										value={rePassword}
										onChange={input => setRePassword(input.target.value)}
										pattern={escapeRegex(password)}
									/>
									<Form.Control.Feedback type="invalid">
										Please make sure passwords match
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="register-bio-input">
									<Form.Control
										as="textarea"
										placeholder="Bio (optional)"
										value={bio}
										onChange={input => setBio(input.target.value)}
									/>
								</Form.Group>

								<div className="d-flex justify-content-center mb-3">
									<CustomRecaptcha onChange={() => setIsHuman(true)} />
								</div>
								{error && <Alert variant="danger">{error}</Alert>}
								{loading ? (
									<Loading text="Registering..." />
								) : (
									<Button
										type="submit"
										disabled={disabled}
										variant="main"
										className="align-self-center"
									>
										Register
									</Button>
								)}
							</Form>
						</Col>
					</Row>
				)}
				<Row className="justify-content-center">
					<Col className="d-flex flex-column" style={{ maxWidth: "min(85%, 500px)" }}>
						<div className="separator">
							<hr />
							<div>or</div>
							<hr />
						</div>

						<Link to="/sign-in" className="align-self-center">
							<Button variant="main">Sign In to Existing Account</Button>
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
export default RegisterScreen;
