import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { deleteUser, listUsers, saveUser } from "../../actions/userActions";
import AddressBlock from "../../components/blocks/AddressBlock";
import SocialBlock from "../../components/blocks/SocialBlock";
import userUpdateTypes from "../../constants/userUpdateTypes";

function ManagePersonsScreen(props) {
	const [modalVisible, setModalVisible] = useState(false);
	const [correct, setCorrect] = useState(true);
	const [id, setId] = useState("");
	const [school_id, setSchoolId] = useState("");
	const [school_name, setSchoolName] = useState("");
	const [name, setName] = useState("");
	const [points, setPoints] = useState("");
	const [hours_completed, setHoursCompleted] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [grade_level, setGradeLevel] = useState("");
	const defaultSocial = { twitter: "", instagram: "", facebook: "" };
	const [social, setSocial] = useState(defaultSocial);
	const [rating, setRating] = useState("");
	const [comments, setComments] = useState("");
	const [is_volunteer, setIsVolunteer] = useState(true);
	const [is_intern, setIsIntern] = useState(true);
	const [is_student, setIsStudent] = useState(true);
	const defaultAddress = { line1: "", line2: "", city: "", state: "", zip: "" };
	const [address, setAddress] = useState(defaultAddress);
	const [image, setImage] = useState("");
	const [image_path, setImagePath] = useState("");
	const [account_type, setAccountType] = useState("");
	const [uploading, setUploading] = useState(false);

	const userSignin = useSelector(state => state.userSignin);
	const { userInfo } = userSignin;

	const userList = useSelector(state => state.userList);
	const { users } = userList;

	const userSave = useSelector(state => state.userSave);
	const { success: successSave } = userSave;

	const userDelete = useSelector(state => state.userDelete);
	const { success: successDelete } = userDelete;

	const dispatch = useDispatch();
	useEffect(() => {
		if (!userInfo) return;
		if (successSave) {
			setModalVisible(false);
		}
		dispatch(listUsers("person"));
	}, [userInfo, successSave, successDelete]);

	// handle to open create/edit modal
	const openModal = user => {
		setModalVisible(true);
		setCorrect(true);
		setId(user._id);
		setSchoolId(user.school_id);
		setSchoolName(user.school_name);
		setName(user.name);
		setPoints(user.points);
		setHoursCompleted(user.hours_completed);
		setPhone(user.phone);
		setEmail(user.email);
		setGradeLevel(user.grade_level);
		setSocial(user.social || defaultSocial);
		setRating(user.rating);
		setComments(user.comments);
		setIsVolunteer(user.is_volunteer);
		setIsIntern(user.is_intern);
		setIsStudent(user.is_student);
		setAddress(user.address || defaultAddress);
		setAccountType("person");
		setImage(user.image);
		setImagePath(user.image_path);
	};

	const closeModal = () => setModalVisible(false);

	// handler to save user info
	const handleSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		dispatch(
			saveUser(
				{
					_id: id,
					school_id,
					school_name,
					name,
					points,
					hours_completed,
					phone,
					email,
					address,
					grade_level,
					social,
					rating,
					comments,
					is_volunteer,
					is_intern,
					is_student,
					account_type,
					image,
					image_path
				},
				userUpdateTypes.MANAGE_PERSON
			)
		);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	const handleDelete = user => {
		if (window.confirm(`Do you really want to delete ${user.email}?`)) {
			dispatch(deleteUser(user));
		}
	};

	return (
		<>
			{!userInfo.isAdmin && <Navigate to="/" />}
			<Helmet>
				<title>Vower | Manage Users</title>
			</Helmet>
			<div>
				<div>
					<h3>Manage Persons</h3>
				</div>

				<Modal dialogClassName="manage-modal" size="lg" show={modalVisible} onHide={closeModal}>
					<Form noValidate validated onSubmit={handleSubmit} onChange={handleFormChange}>
						<Modal.Body>
							<h2>{id ? "Update" : "Create"} User</h2>

							<Form.Group controlId="name">
								<Form.Label>User Name</Form.Label>
								<Form.Control
									required
									type="text"
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</Form.Group>
							<Form.Group controlId="school_name">
								<Form.Label>School Name</Form.Label>
								<Form.Control
									type="text"
									value={school_name}
									onChange={e => setSchoolName(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="points">
								<Form.Label>Points</Form.Label>
								<Form.Control
									type="number"
									inputMode="numeric"
									min={0}
									value={points}
									onChange={e => setPoints(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="hours_completed">
								<Form.Label>Hours Completed</Form.Label>
								<Form.Control
									type="number"
									inputMode="numeric"
									min={0}
									value={hours_completed}
									onChange={e => setHoursCompleted(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="phone">
								<Form.Label>Phone</Form.Label>
								<Form.Control
									type="tel"
									inputMode="tel"
									value={phone}
									onChange={e => setPhone(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId="email">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} />
							</Form.Group>

							<Form.Group controlId="grade_level">
								<Form.Label>Grade Level</Form.Label>
								<Form.Control
									type="text"
									value={grade_level}
									onChange={e => setGradeLevel(e.target.value)}
								/>
							</Form.Group>

							<AddressBlock address={address} setAddress={setAddress} />

							<SocialBlock social={social} setSocial={setSocial} />

							<Form.Group controlId="rating">
								<Form.Label>Rating</Form.Label>
								<Form.Control type="text" value={rating} onChange={e => setRating(e.target.value)} />
							</Form.Group>

							<Form.Group controlId="comments">
								<Form.Label>Comments</Form.Label>
								<Form.Control
									as="textarea"
									value={comments}
									onChange={e => setComments(e.target.value)}
								/>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="main" type="submit" disabled={!correct}>
								{id ? "Update" : "Create"}
							</Button>
							<Button variant="main" onClick={closeModal}>
								Close
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>

				<div>
					<Table responsive>
						<thead>
							<tr>
								<th>Name</th>
								<th>School Name</th>
								<th>Points</th>
								<th>Hours Completed</th>
								<th>Phone</th>
								<th>Email</th>
								<th>Grade Level</th>
								<th>Rating</th>
								<th>Comments</th>
								<th>Address</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{users &&
								users.map(user => (
									<tr key={user._id}>
										<td>
											<Link to={`/profile/${user._id}`}>{user.name}</Link>
										</td>
										<td>{user.school_name}</td>
										<td>{user.points}</td>
										<td>{user.hours_completed}</td>
										<td>{user.phone}</td>
										<td>{user.email}</td>
										<td>{user.grade_level}</td>
										<td>{user.rating}</td>
										<td>{user.comments}</td>
										<td>
											{user.address && (
												<>
													{user.address.line1},{" "}
													{user.address.line2 && `${user.address.line2}, `}
													{user.address.city}, {user.address.state}, {user.address.zip}
												</>
											)}
										</td>
										<td>
											<Button variant="main" onClick={() => openModal(user)}>
												Edit
											</Button>
										</td>
										<td>
											<Button variant="main" onClick={() => handleDelete(user)}>
												X
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}
export default ManagePersonsScreen;
