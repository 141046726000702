/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { positions, useAlert } from "react-alert";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import { Check, Facebook, Instagram, Link45deg, Linkedin, Twitter, X } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { deleteEvent, detailsUser, listMyEvents } from "../actions/userActions";
import { listAllVolunteers } from "../actions/opportunityActions";
import "../assets/css/modal.css";
import Loading from "../components/Loading";
import EditProfileModal from "../components/modals/EditProfileModal";

const getClickableLink = link => (link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`);

function ProfileScreen() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const location = useLocation();
	// account_type for user id
	const [personAccount, setPersonAccount] = useState(false);
	const [organizationAccount, setOrganizationAccount] = useState(false);
	// roles for current user
	const [isAdmin, setIsAdmin] = useState(false);
	const [isOrganization, setIsOrganization] = useState(false);
	const [isSelf, setIsSelf] = useState(false);

	const [modalVisible, setModalVisible] = useState(false);
	const [currUser, setCurrUser] = useState({});
	const defaultSocial = { twitter: "", instagram: "", facebook: "", linkedin: "" };
	const defaultAddress = { line1: "", line2: "", city: "", state: "", zip: "" };

	const { userInfo } = useSelector(state => state.userSignin);
	const { user, loading } = useSelector(state => state.userDetails);
	const { success: successSave } = useSelector(state => state.userSave);
	const { allUsers } = useSelector(state => state.opportunityListAllVolunteers);
	// const [statuses, setStatuses] = useState({});
	// const [loadingStatuses, setLoadingStatuses] = useState({});

	useEffect(() => {
		if (successSave) {
			setModalVisible(false);
			dispatch(detailsUser(id, ["orders"]));
		}
		return () => {};
	}, [successSave]);

	const { loading: loadingEvents, events } = useSelector(state => state.userListEvents);
	const { success: successDelEvent } = useSelector(state => state.userDelEvent);

	useEffect(() => {
		setIsAdmin(userInfo?.isAdmin);
		setIsOrganization(userInfo?.isOrganization);
		// current user is the owner of the profile
		const isSelf = userInfo?._id == id;
		setIsSelf(isSelf);
		// load eventdata when current user is admin or current user is the owner of the profile
		if (userInfo?.isAdmin || isSelf) dispatch(listMyEvents(id));
		dispatch(detailsUser(id, ["orders"]));
	}, [id]);

	useEffect(() => {
		if (user) {
			setPersonAccount(user.account_type === "person");
			setOrganizationAccount(user.account_type === "organization");
		}
	}, [user]);

	useEffect(() => {
		if (isOrganization) {
			dispatch(listAllVolunteers());
		}
	}, [isOrganization]);

	useEffect(() => {
		if (successDelEvent) dispatch(listMyEvents(id));
	}, [successDelEvent]);

	const alert = useAlert();
	useEffect(() => {
		if (location && location.state && location.state?.justRegisteredOrg) {
			alert.show(
				<div className="text-center">
					<div className="display-4 mb-3">Registration Complete!</div>
					<p>
						Thank you for Registering. We will be verifying your account shortly. Once we're done, you'll
						receive an email letting you know you're all good to post opportunities and buy points. Until
						then, hang tight.
					</p>
				</div>,
				{ type: "justRegistered", position: positions.MIDDLE }
			);
		}
	}, []);

	const openModal = () => {
		setCurrUser({ ...user, social: user.social || defaultSocial, address: user.address || defaultAddress });
		setModalVisible(true);
	};

	const handleCancel = event => {
		if (window.confirm("Do you really want to cancel your application / registration this event?")) {
			dispatch(deleteEvent(id, event.opportunity_id));
		}
	};

	const dateOptions = {
		weekday: "long",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric"
	};

	// const updateUserStatus = async (userId, status, opportunityId, opportunityName) => {
	// 	const newLoadingStatuses = loadingStatuses;
	// 	newLoadingStatuses[userId] = true;
	// 	setLoadingStatuses({ ...newLoadingStatuses });
	// 	await axios.post(
	// 		`${process.env.REACT_APP_BACKEND_URL}/opportunities/update-user-status/`,
	// 		{ opportunityId, userId, status },
	// 		{
	// 			headers: {
	// 				Authorization: `Bearer ${userInfo.token}`
	// 			}
	// 		}
	// 	);

	// 	const newStatuses = statuses;
	// 	newStatuses[userId] = status;
	// 	setStatuses({ ...newStatuses });
	// 	newLoadingStatuses[userId] = false;
	// 	setLoadingStatuses({ ...newLoadingStatuses });

	// 	if (status === "signed up") {
	// 		const { data: userDetails } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, {
	// 			headers: {
	// 				Authorization: `Bearer ${userInfo.token}`
	// 			}
	// 		});
	// 		emailjs.send("Gmail", "application_accepted", {
	// 			email: userDetails.email,
	// 			name: userDetails.name,
	// 			opportunityId,
	// 			opportunityName
	// 		});
	// 	}
	// };

	return (
		<>
			<Helmet>
				<title>Vower | Profile</title>
			</Helmet>
			<Container>
				<Row className="justify-content-between  align-items-center mb-3 mb-md-0 w-100">
					<div className="d-flex flex-column justify-content-between align-items-center">
						<div className="display-4">{isAdmin && isSelf ? "Admin Portal" : !loading && user?.name}</div>
						{!isAdmin && isSelf && !loading && (
							<Button variant="main" onClick={openModal} className="mt-3">
								Edit Profile
							</Button>
						)}
					</div>
					<div>
						{isOrganization && user?.verified && (
							<Link to="/buy-points" className="mr-2">
								<Button variant="main">Buy Points</Button>
							</Link>
						)}
					</div>
				</Row>
				<Row className="justify-content-center justify-content-md-end">
					{isSelf && (isAdmin || (isOrganization && user?.verified)) && (
						<Link to="/manage-opportunities" className="mb-2 mr-2">
							<Button variant="main">Manage Opportunities</Button>
						</Link>
					)}
					{isSelf && isAdmin && (
						<>
							<Link to="/manage-persons" className="mb-2 mr-2">
								<Button variant="main">Manage Persons</Button>
							</Link>
							<Link to="/manage-organizations" className="mb-2 mr-2">
								<Button variant="main">Manage Organizations</Button>
							</Link>
							<Link to="/manage-incentives" className="mb-2 mr-2">
								<Button variant="main">Manage Incentives</Button>
							</Link>
							<Link to="/manage-categories" className="mb-2 mr-2">
								<Button variant="main">Manage Categories</Button>
							</Link>
							<Link to="/manage-orders" className="mb-2 mr-2">
								<Button variant="main">Manage Orders</Button>
							</Link>
							<Link to="/manage-codes" className="mb-2 mr-2">
								<Button variant="main">Manage Codes</Button>
							</Link>
						</>
					)}
				</Row>

				<EditProfileModal
					modalVisible={modalVisible}
					user={currUser}
					userInfo={userInfo}
					setModalVisible={setModalVisible}
				/>

				{loading ? (
					<Loading />
				) : (
					(personAccount || organizationAccount) && (
						<Row className="m-3 p-3 w-100">
							<Col
								xs={12}
								md={6}
								className="mb-4 mb-md-0 d-flex flex-column justify-content-center align-items-center"
							>
								<Image
									src={user.image || window.images.defaultAccount}
									alt="user"
									className={`mb-3 img-fluid profile-image ${personAccount && "circle"}`}
									style={{
										filter: user.image ? "none" : "var(--icon-invert)",
										cursor: isSelf ? "pointer" : "default"
									}}
									onClick={() => isSelf && openModal()}
								/>
								<p>{user.bio}</p>
								{user.social && (
									<div className="social-links">
										{user.social.twitter && (
											<a
												href={`https://twitter.com/${user.social.twitter}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Twitter className="social-icon twitter-icon" />
											</a>
										)}
										{user.social.instagram && (
											<a
												href={`https://instagram.com/${user.social.instagram}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Instagram className="social-icon instagram-icon" />
											</a>
										)}
										{user.social.facebook && (
											<a
												href={`https://facebook.com/${user.social.facebook}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Facebook className="social-icon facebook-icon" />
											</a>
										)}
										{user.social.linkedin && (
											<a
												href={getClickableLink(user.social.linkedin)}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Linkedin className="social-icon linkedin-icon" />
											</a>
										)}
										{user.social.tiktok && (
											<a
												href={`https://tiktok.com/@${user.social.tiktok}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<span className="iconify" data-icon="fa-brands:tiktok" />
											</a>
										)}
										{user.social.portfolio && (
											<a
												href={getClickableLink(user.social.portfolio)}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Link45deg className="social-icon bi-link-45deg" />
											</a>
										)}
										{user.social.website && (
											<a
												href={getClickableLink(user.social.website)}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Link45deg className="social-icon bi-link-45deg" />
											</a>
										)}
									</div>
								)}
							</Col>
							<Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
								{personAccount && (isAdmin || isSelf) && (
									<div
										className="d-flex flex-column align-items-center"
										style={{ maxWidth: "200px" }}
									>
										<div className="display-2">{user.points || 0}</div>
										<h3 className="mb-3 d-flex align-items-center">
											<Image
												src={window.images.vowerLogoPNG}
												style={{ width: "2rem", height: "auto" }}
												className="mr-3"
											/>
											Points
										</h3>
										<Link to="/incentives">
											<Button variant="main">Redeem Points</Button>
										</Link>
									</div>
								)}
								{organizationAccount && (isAdmin || isSelf) && (
									<div
										className="d-flex flex-column align-items-center"
										style={{ maxWidth: "200px" }}
									>
										<div className="d-flex align-items-center">
											{!user.verified && "Not "}Verified{" "}
											{user.verified ? (
												<Check fill="var(--green)" size="2rem" />
											) : (
												<X fill="red" size="2rem" />
											)}
										</div>
										<div className="display-2">{user.points || 0}</div>
										<h3 className="mb-3 d-flex align-items-center text-center">
											<Image
												src={window.images.vowerLogoPNG}
												style={{ width: "2rem", height: "auto" }}
												className="mr-3"
											/>
											Points Remaining
										</h3>
									</div>
								)}
							</Col>
						</Row>
					)
				)}

				{allUsers && isOrganization && isSelf && (
					<>
						<hr />
						<h2>All Applicants</h2>
						<div className="volunteer-list">
							<Table responsive>
								<thead>
									<tr>
										<th>Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th>Opportunity</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{allUsers &&
										allUsers.map(user => (
											<tr key={user._id}>
												<td>
													<Link to={`/profile/${user._id}`}>{user.name}</Link>
												</td>
												<td>
													<a
														href={`mailto:${user.email}`}
														target="_blank"
														rel="noopener noreferrer"
													>
														{user.email}
													</a>
												</td>
												<td>{user.phone}</td>
												<td>
													<Link to={`/opportunity/${user.opportunityId}`}>
														{user.opportunityName}
													</Link>
												</td>
												<td style={{ textTransform: "capitalize" }}>{user.status}</td>
											</tr>
										))}
								</tbody>
							</Table>
						</div>
					</>
				)}
				{(personAccount || isAdmin) &&
					(isAdmin || isSelf) &&
					(loadingEvents ? (
						<Loading text="Loading Events..." />
					) : (
						Array.isArray(events) &&
						events.length > 0 && (
							<Row>
								<h2>Opportunities You Have Signed Up For</h2>
								<Table responsive>
									<thead>
										<tr>
											<th>Event</th>
											<th>Start</th>
											<th>End</th>
											<th>Status</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{events.map(event => (
											<tr key={event._id}>
												<td>
													{" "}
													<Link to={`/opportunity/${event.opportunity_id}`}>
														{event.name}
													</Link>
												</td>
												<td>
													{event.start_date_time
														? new Date(event.start_date_time).toLocaleTimeString(
																"en-US",
																dateOptions
														  )
														: "Remote"}
												</td>
												<td>
													{event.end_date_time
														? new Date(event.end_date_time).toLocaleTimeString(
																"en-US",
																dateOptions
														  )
														: "Remote"}
												</td>
												<td>
													{typeof event.status == "string" &&
														event.status.charAt(0).toUpperCase() +
															event.status.substring(1, event.status.length)}
												</td>
												<td>
													{(event.status === "applied" || event.status === "signed up") && (
														<Button variant="main" onClick={() => handleCancel(event)}>
															Cancel
														</Button>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Row>
						)
					))}

				{personAccount &&
					(isAdmin || isSelf) &&
					(loading ? (
						<Loading text="Loading Orders..." />
					) : (
						Array.isArray(user.orders) &&
						user.orders.length > 0 && (
							<Row>
								<h2>Your Past Orders</h2>
								<Table responsive>
									<thead>
										<tr>
											<th>Items</th>
											<th>Total Cost</th>
											<th>Time Ordered</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{user.orders.map(order => (
											<tr key={order._id}>
												<td>
													{order.order_items.map(item => (
														<div key={item.incentive_id}>
															<Link to={`/incentive/${item.incentive_id}`}>
																{item.incentive_id}
															</Link>
															: {item.quantity}
														</div>
													))}
												</td>
												<td>{order.total_cost}</td>
												<td>
													{new Date(order.order_date).toLocaleTimeString(
														"en-US",
														dateOptions
													)}
												</td>
												<td>{order.order_status}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Row>
						)
					))}
			</Container>
		</>
	);
}

export default ProfileScreen;
