import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Carousel } from "react-bootstrap";
import "../../assets/css/Carousel.css";
import GigCard from "./GigCard";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { KroonzWear, LinwoodCourt, KroonzWearTwo, HundredBlackMen, SAP, Vower } from "./sampleGigs";
import LeftCarouselArrow from "../../assets/images/LeftCarouselArrow.svg";
import RightCarouselArrow from "../../assets/images/RightCarouselArrow.svg";

const CarouselWrapper = styled.div`
	width: ${props => (props.$windowWidth < 500 ? "90vw" : "min(1200px, 82vw)")};
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Container = styled(Carousel)`
	margin: 0;
	width: ${props => (props.$windowWidth < 500 ? "90vw" : "min(1200px, 82vw)")};
	display: flex;
	flex-direction: column;
	align-items: center;
	height: auto;
	z-index: 1;
`;

const ItemContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const Arrow = styled.img`
	max-width: min(${props => (props.$windowWidth < 500 ? "3vw" : "2vw")}, 20px);
	margin-top: ${props => (props.$windowWidth < 500 ? "-20vw" : "max(-120px, -10vw)")};
	cursor: pointer;
`;

export default function GigsCarousel() {
	const [gigs, setGigs] = useState([]);
	const { windowWidth } = useWindowDimensions();
	const gigsRef = useRef();

	const PrevIcon = () => {
		return <Arrow $windowWidth={windowWidth} onClick={() => gigsRef.current.prev()} src={LeftCarouselArrow} />;
	};

	const NextIcon = () => {
		return <Arrow $windowWidth={windowWidth} onClick={() => gigsRef.current.next()} src={RightCarouselArrow} />;
	};

	useEffect(() => {
		let sampleGigs = [];
		if (windowWidth > 500) {
			sampleGigs = [
				[KroonzWear, LinwoodCourt, HundredBlackMen],
				[KroonzWearTwo, SAP, Vower]
			];
			// sampleGigs = [
			// 	["-NRC1yowxaw3OmZFtDZk", "-NOu4kgk_Zd3KcLI0cdZ", "-NI4QwW3Uwfkgk7TKK1N"],
			// 	["-NRDC9t0w0vJUsPt99qp", "-NRDEs-rEZpiIGOoa7Os", "-NREnRZ9h6cs9TSl4fH4"]
			// ];
		} else {
			sampleGigs = [
				[KroonzWear, LinwoodCourt],
				[HundredBlackMen, KroonzWearTwo],
				[SAP, Vower]
			];
			// sampleGigs = [
			// 	["-NRC1yowxaw3OmZFtDZk", "-NOu4kgk_Zd3KcLI0cdZ"],
			// 	["-NRDC9t0w0vJUsPt99qp", "-NI4QwW3Uwfkgk7TKK1N"],
			// 	["-NRDEs-rEZpiIGOoa7Os", "-NREnRZ9h6cs9TSl4fH4"]
			// ];
		}
		setGigs(sampleGigs);
	}, []);

	// const handleItemLike = (index, i2) => {
	// 	const newGigValue = {
	// 		...gigs[index][i2],
	// 		liked: !gigs[index][i2].liked
	// 	};
	// 	let newGigs = [...gigs];
	// 	newGigs[index][i2] = newGigValue;
	// 	setGigs(newGigs);
	// };

	// const handleItemApply = (index, i2) => {
	// 	const newGigValue = {
	// 		...gigs[index][i2],
	// 		applied: !gigs[index][i2].applied
	// 	};
	// 	let newGigs = [...gigs];
	// 	newGigs[index][i2] = newGigValue;
	// 	setGigs(newGigs);
	// };

	return (
		<CarouselWrapper $windowWidth={windowWidth}>
			<PrevIcon />
			<Container $windowWidth={windowWidth} ref={gigsRef} controls={false}>
				{gigs.map((itemGroup, index) => (
					<Carousel.Item key={itemGroup + index} style={{ width: "100%" }}>
						<ItemContainer>
							{itemGroup.map((item, i2) => (
								<GigCard
									opportunity={item}
									handleItemLike={() => {}}
									handleItemApply={() => {}}
									key={item + index + i2}
								/>
							))}
						</ItemContainer>
					</Carousel.Item>
				))}
			</Container>
			<NextIcon />
		</CarouselWrapper>
	);
}
