import emailjs from "emailjs-com";
import React, { useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomRecaptcha from "../components/CustomRecaptcha";
import Loading from "../components/Loading";

function ContactUsScreen() {
	const theme = useSelector(state => state.theme.theme);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [validated, setValidated] = useState(false);
	const [sending, setSending] = useState(false);
	const [sent, setSent] = useState(false);
	const [error, setError] = useState(false);

	const [isHuman, setIsHuman] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();

		if (!isHuman) {
			return;
		}

		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.stopPropagation();
		} else {
			setSending(true);
			emailjs
				.sendForm("Gmail", "contact_us", e.target)
				.then(result => {
					setSent(true);
					setSending(false);
					if (result.status !== 200) {
						setError(true);
						console.log(result);
					}
				})
				.then(error => {
					console.log(error);
				});
			emailjs.sendForm("Gmail", "contact_us_users", e.target);
		}

		setValidated(true);
	};

	return (
		<>
			<Helmet>
				<title>Vower | Contact Us</title>
			</Helmet>
			<div id="contactus-page">
				<div className="hero-container">
					<Image src={window.images.contactUsHero} />
					<div>
						Say Hello.
						<br />
						We'd love to hear from you.
					</div>
				</div>
				{sending ? (
					<Loading className="mt-5" />
				) : sent ? (
					<div className="text-center">
						{error ? (
							<div>
								<div className="mt-5 mb-3 display-4">There was an error :(</div>
								<p>Try Again Later</p>
							</div>
						) : (
							<div className="mt-5 mb-3 display-4">Message Sent!</div>
						)}
						<Link to="/">
							<Button variant="main">Go Home</Button>
						</Link>
					</div>
				) : (
					<div className="mt-5 d-flex flex-column align-items-center">
						<div style={{ width: "min(85%, 500px)" }}>
							<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
								className="d-flex flex-column justify-content-center"
							>
								<Form.Group controlId="contactus-name-input">
									<Form.Control
										required
										type="text"
										placeholder="Name"
										name="name"
										text={name}
										onChange={input => setName(input.target.value)}
									/>
									<Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="contactus-email-input">
									<Form.Control
										required
										type="email"
										placeholder="Email"
										name="email"
										text={email}
										onChange={input => setEmail(input.target.value)}
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a valid email
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="contactus-message-input">
									<Form.Control
										required
										as="textarea"
										placeholder="Message"
										name="message"
										text={message}
										onChange={input => setMessage(input.target.value)}
									/>
									<Form.Control.Feedback type="invalid">
										Please enter your message
									</Form.Control.Feedback>
								</Form.Group>
								<div className="d-flex justify-content-center mb-3">
									<CustomRecaptcha onChange={() => setIsHuman(true)} />
								</div>
								<Button type="submit" disabled={!isHuman} variant="main" className="align-self-center">
									Submit
								</Button>
							</Form>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default ContactUsScreen;
