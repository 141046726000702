import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import SAPIncentives from "../../components/sap/SAPIncentives";

import "../../assets/css/SAP.scss";
import SAPLogo from "../../assets/images/sap/sap-logo.png";

const steps = [
	{
		title: "Watch, Create & Share",
		points: 10,
		content: (
			<p>
				Watch these videos.
				<br />
				Recreate and share your own.
			</p>
		),
		link: "/opportunity/-NdgM7OuKny_hOH31tug",
		assets: [{ type: "video", url: window.images.sap.influencer1 }]
	},
	{
		title: "Complete the Learning Journey",
		points: 20,
		content: (
			<p>
				It's time to train for the job of your dreams.
				<br />
				Complete the Learning Journey that will take you there.
			</p>
		),
		link: "/opportunity/-NdgQUbADtPxTO3FZCGY",
		assets: [{ type: "video", url: window.images.sap.step2 }]
	},
	{
		title: "Register for the Certification Exam",
		points: 1,
		content: (
			<p>
				We see you {String.fromCodePoint(0x1f440)}. You Geek {String.fromCodePoint(0x1f913)}. You look Gewwdd
				Learning. Time to Register for the Certification Exam
			</p>
		),
		link: "/opportunity/-NdgY38fonAQ1mg7d2Lk",
		assets: [{ type: "image", url: window.images.sap.step3 }]
	},
	{
		title: "Take the Certification Exam",
		points: 5,
		content: <p>Somebody's got a test to Slay. That somebody is YOU. Good Luck!</p>,
		link: "/opportunity/-NdgZXTAjRYH-TQzB-tm",
		assets: [{ type: "image", url: window.images.sap.step4 }]
	},
	{
		title: "Certified Learner",
		points: 25,
		content: (
			<p>
				{String.fromCodePoint(0x1f389)} Congratulations, you are now a SAP Certified Learner. You're where you
				belong! Collect your Coins
			</p>
		),
		link: "/opportunity/-Ndg_K8rGwwj_NQYgfcG",
		assets: [{ type: "image", url: window.images.sap.step5 }]
	}
];

function SAPScreen() {
	const stepsRef = useRef();

	const [stepsScrollPos, setStepsScrollPos] = useState(0);
	const [stepWidth, setStepWidth] = useState(0);
	const [showLeft, setShowLeft] = useState(false);
	const [showRight, setShowRight] = useState(true);

	useEffect(() => {
		const stepsDiv = stepsRef.current;
		stepsDiv.addEventListener("scroll", () => {
			setStepsScrollPos(stepsDiv.scrollLeft);
			setShowLeft(stepsDiv.scrollLeft > 0);
			setShowRight(stepsDiv.scrollLeft + stepsDiv.clientWidth < stepsDiv.scrollWidth);
		});

		window.addEventListener("resize", handleResize);
		handleResize();
	}, []);

	function handleResize() {
		const stepsDiv = stepsRef.current;
		const step = stepsDiv.querySelector(".step");
		setStepWidth(step.offsetWidth);
	}

	function getCurrStep() {
		if (stepWidth === 0) return 1;
		return Math.round(stepsScrollPos / stepWidth) + 1;
	}
	function scrollLeft() {
		const currStep = getCurrStep();
		if (currStep === 1) return;
		stepsRef.current.scrollBy(-stepWidth, 0);
	}
	function scrollRight() {
		const currStep = getCurrStep();
		if (currStep === steps.length) return;
		stepsRef.current.scrollBy(stepWidth, 0);
	}

	return (
		<>
			<Helmet>
				<title>Vower | SAP Certification Journey</title>
			</Helmet>
			<div id="SAP-Cert">
				<div className="sap-hero">
					<h1>
						<img src={SAPLogo} alt="SAP Logo" />
						Certification Journey
					</h1>
					<p>
						Learn Fast. Learn Slow. Learn Tired. Learn Excited. Learn even if you're feeling Insecure.
						Whatever you do. Don't Stop Learning.
					</p>
				</div>
				<Container>
					<Row className="total-rewards p-4">
						<span>TOTAL REWARDS:</span> <span className="highlight animate">61 POINTS</span>
					</Row>
				</Container>
				<div className="steps-container">
					<div className="steps p-3 px-md-4" ref={stepsRef}>
						{steps.map((step, i) => {
							const firstAsset = step.assets[0];

							return (
								<div className="step p-4 p-md-5" id={`step-${i + 1}`} key={step.title}>
									<Row xs={1} md={2}>
										<Col className="mb-3 mb-md-0">
											<div className="header">
												<div className="number">Step {i + 1}</div>
												<div className="points highlight">
													{step.points} Vower Point{step.points > 1 ? "s" : ""}
												</div>
											</div>
											<h2>{step.title}</h2>
											{step.content}
											<Link to={step.link}>START &rarr;</Link>
										</Col>
										<Col>
											{firstAsset.type === "image" ? (
												<img src={firstAsset.url} alt={`step ${i + 1}`} />
											) : (
												<video src={firstAsset.url} controls />
											)}
										</Col>
									</Row>
								</div>
							);
						})}
					</div>
					<div className="nav-buttons">
						<button className={`left ${showLeft ? "show" : "hide"}`} onClick={scrollLeft}>
							{"<"}
						</button>
						<button className={`right ${showRight ? "show" : "hide"}`} onClick={scrollRight}>
							{">"}
						</button>
					</div>
				</div>
				<SAPIncentives />
			</div>
		</>
	);
}

export default SAPScreen;
