/* eslint-disable indent */
import axios from "axios";
import delay from "delay";
import emailjs from "emailjs-com";
import Cookie from "js-cookie";
import { CART_SAVE_SHIPPING } from "../constants/cartConstants";
import {
	USER_DELETE_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DEL_EVENT_FAIL,
	USER_DEL_EVENT_REQUEST,
	USER_DEL_EVENT_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_LIST_EVENTS_FAIL,
	USER_LIST_EVENTS_REQUEST,
	USER_LIST_EVENTS_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LOGOUT,
	USER_REFRESH,
	USER_REFRESH_SUCCESS,
	USER_RESET_FAIL,
	USER_RESET_REQUEST,
	USER_RESET_SUCCESS,
	USER_SAVE_FAIL,
	USER_SAVE_REQUEST,
	USER_SAVE_SUCCESS,
	USER_SIGNIN_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS
} from "../constants/userConstants";
import userUpdateTypes from "../constants/userUpdateTypes";
import { auth, storage } from "../firebase";

const refreshToken = () => async dispatch => {
	dispatch({ type: USER_REFRESH });
	try {
		while (!auth.currentUser) {
			// Wait for firebase to load the current user
			await delay(200);
		}
		const idToken = await auth.currentUser.getIdToken();
		// Use fetch to avoid infinite loop with axios interceptor
		const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/signin`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ idToken })
		});
		const data = await response.json();
		Cookie.set("userInfo", JSON.stringify(data));
		dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
		dispatch({ type: USER_REFRESH_SUCCESS });
	} catch (error) {
		dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
	}
};

const signin = (email, password) => async dispatch => {
	dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });

	try {
		// use firebase to sign user in, pass the info back to generate token
		await auth.signInWithEmailAndPassword(email, password);
		const idToken = await auth.currentUser.getIdToken(true);
		const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/signin`, { idToken });
		Cookie.set("userInfo", JSON.stringify(response.data));
		dispatch({ type: USER_SIGNIN_SUCCESS, payload: response.data });
	} catch (error) {
		dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
	}
};

const resetPassword = email => async dispatch => {
	dispatch({ type: USER_RESET_REQUEST, payload: { email } });
	try {
		await auth.sendPasswordResetEmail(email);
		dispatch({ type: USER_RESET_SUCCESS, payload: "" });
	} catch (error) {
		dispatch({ type: USER_RESET_FAIL, payload: error.message });
	}
};

const uploadImage = async (imageFile, filePath) => {
	const uploadTask = storage.ref(filePath).put(imageFile);
	return new Promise((resolve, reject) =>
		uploadTask.on(
			"state_changed",
			snapShot => {
				console.log(snapShot); // takes a snap shot of the process as it is happening
			},
			err => {
				reject();
				console.log(err);
			},
			async () => {
				const firebase_url = await storage.ref(filePath).getDownloadURL();
				console.log("image", firebase_url);
				resolve(firebase_url);
			}
		)
	);
};

const register =
	(name, firstName, lastName, email, password, account_type, imageURL, image_file, file_name, bio, social) =>
	async dispatch => {
		dispatch({
			type: USER_SIGNIN_REQUEST,
			payload: {
				name,
				firstName,
				lastName,
				email,
				password,
				account_type,
				imageURL,
				image_file,
				file_name,
				bio,
				social
			}
		});

		try {
			await auth.createUserWithEmailAndPassword(email, password);
			const token = await auth.currentUser.getIdToken(true);
			let image = imageURL || ""; // blob url (backup in case storage does not work)
			let image_path = "";

			if (image_file) {
				const userId = auth.currentUser.uid;
				image_path = `/${userId}/images/${file_name}`;
				image = await uploadImage(image_file, image_path);
			}
			await auth.currentUser.updateProfile({
				displayName: name,
				photoURL: image
			});
			await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/register`, {
				token,
				name,
				firstName,
				lastName,
				email,
				account_type,
				image,
				image_path,
				bio,
				social
			});

			// use firebase to sign user in, pass the info back to generate token
			await auth.signInWithEmailAndPassword(email, password);
			const idToken = await auth.currentUser.getIdToken(true);
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/signin`, { idToken });
			Cookie.set("userInfo", JSON.stringify(response.data));
			const isOrg = account_type === "organization";
			emailjs.send("Gmail", `register_confirm_${isOrg ? "org" : "user"}`, {
				name: response.data.name,
				email: response.data.email
			});

			dispatch({ type: USER_SIGNIN_SUCCESS, payload: response.data });
		} catch (error) {
			dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
		}
	};

const logout = () => async dispatch => {
	dispatch({ type: USER_LOGOUT });
	dispatch({ type: CART_SAVE_SHIPPING, payload: {} });
	Cookie.remove("userInfo");
	Cookie.remove("shipping");
	try {
		await auth.signOut();
		console.log("Signed Out");
	} catch (error) {
		console.error("Sign Out Error", error);
	}
};

const listUsers = account_type => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({ type: USER_LIST_REQUEST });

		const url_address = `${process.env.REACT_APP_BACKEND_URL}/users/listusers/${account_type}`;

		const { data } = await axios.get(url_address, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		dispatch({ type: USER_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: USER_LIST_FAIL, payload: error.message });
	}
};

const saveUser =
	(user, updateType, updateDetails = {}) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: USER_SAVE_REQUEST, payload: user });
			const {
				userSignin: { userInfo }
			} = getState();

			if (!user._id) {
				dispatch({ type: USER_SAVE_FAIL, payload: "Can't save user without id" });
			} else {
				// also update display name in firebase auth
				if (user.name != userInfo.name && !userInfo.isAdmin) {
					await auth.currentUser.updateProfile({
						displayName: user.name
					});
				}

				const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${user._id}`, user, {
					headers: {
						Authorization: `Bearer ${userInfo.token}`
					}
				});

				// If the org was just verified, send a notification
				if (updateType === userUpdateTypes.MANAGE_ORG && updateDetails.justVerified) {
					emailjs.send("Gmail", "verified", {
						name: user.name,
						email: user.email
					});
				} else if (updateType === userUpdateTypes.BUY_POINTS && updateDetails.points) {
					emailjs.send("Gmail", "buy_points", {
						name: user.name,
						email: user.email,
						points: updateDetails.points,
						price: updateDetails.price
					});
				}

				if (!userInfo.isAdmin) {
					// admin user may update normal user's info, in that case, don't have to reset cookie for admin
					Cookie.set("userInfo", JSON.stringify(data));
				}

				dispatch({ type: USER_SAVE_SUCCESS, payload: data });
			}
		} catch (error) {
			dispatch({ type: USER_SAVE_FAIL, payload: error.message });
		}
	};

// delete an user from firebase
const deleteUser = user => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		// if not admin, cannot delete
		if (!userInfo.isAdmin) {
			dispatch({ type: USER_DELETE_FAIL, payload: "Not authorized to Delete!" });
			return;
		}

		dispatch({ type: USER_DELETE_REQUEST, payload: user });
		const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/users/${user._id}`, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			},
			data: {
				user
			}
		});

		// also delete associated profile image from firebase storage
		if (user.image_path) {
			await storage.ref(user.image_path).delete();
		}

		dispatch({ type: USER_DELETE_SUCCESS, payload: data, success: true });
	} catch (error) {
		dispatch({ type: USER_DELETE_FAIL, payload: error.message });
	}
};

// get the details info of an user
const detailsUser =
	(userId, expand = []) =>
	async (dispatch, getState) => {
		try {
			const {
				userSignin: { userInfo }
			} = getState();
			dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
			const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, {
				params: { expand },
				headers: {
					Authorization: `Bearer ${userInfo?.token}`
				}
			});
			dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: USER_DETAILS_FAIL, payload: error.message });
		}
	};

// list all the events that a user has signed up
const listMyEvents = userId => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();
		dispatch({ type: USER_LIST_EVENTS_REQUEST, payload: userId });
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/listmyevents/${userId}`, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		});
		dispatch({ type: USER_LIST_EVENTS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: USER_LIST_EVENTS_FAIL, payload: error.message });
	}
};

// user remove an event for the his events list -- cancel the sign up
const deleteEvent = (userId, opportunity_id) => async (dispatch, getState) => {
	try {
		const {
			userSignin: { userInfo }
		} = getState();

		dispatch({ type: USER_DEL_EVENT_REQUEST, payload: { opportunity_id } });
		const { data } = await axios.put(
			`${process.env.REACT_APP_BACKEND_URL}/users/deletemyevent/${userId}`,
			{ opportunity_id },
			{
				headers: {
					Authorization: `Bearer ${userInfo.token}`
				}
			}
		);
		dispatch({ type: USER_DEL_EVENT_SUCCESS, payload: data.message });
	} catch (error) {
		dispatch({ type: USER_DEL_EVENT_FAIL, payload: error.message });
	}
};

export {
	refreshToken,
	signin,
	register,
	resetPassword,
	logout,
	listMyEvents,
	deleteEvent,
	listUsers,
	saveUser,
	deleteUser,
	detailsUser
};
