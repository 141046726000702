import PropTypes from "prop-types";
import React from "react";

function FakeRadioButton(props) {
	return (
		<div
			style={{
				...props.style,
				borderRadius: "50%",
				border: "1px solid gray",
				backgroundColor: props.outerColor,
				minWidth: `${props.size}rem`,
				minHeight: `${props.size}rem`,
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
			className={props.className}
		>
			{props.selected && (
				<div
					style={{
						borderRadius: "50%",
						backgroundColor: props.innerColor,
						minWidth: `${props.size / 2}rem`,
						minHeight: `${props.size / 2}rem`
					}}
				/>
			)}
		</div>
	);
}

FakeRadioButton.propTypes = {
	selected: PropTypes.bool.isRequired,
	outerColor: PropTypes.string,
	innerColor: PropTypes.string,
	size: PropTypes.number,
	style: PropTypes.object,
	className: PropTypes.string
};

FakeRadioButton.defaultProps = {
	outerColor: "white",
	innerColor: "var(--turquoise)",
	size: 1,
	style: {},
	className: ""
};

export default FakeRadioButton;
