export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_SAVE_REQUEST = "CATEGORY_SAVE_REQUEST";
export const CATEGORY_SAVE_SUCCESS = "CATEGORY_SAVE_SUCCESS";
export const CATEGORY_SAVE_FAIL = "CATEGORY_SAVE_FAIL";

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";
