export const OPPORTUNITY_LIST_REQUEST = "OPPORTUNITY_LIST_REQUEST";
export const OPPORTUNITY_LIST_SUCCESS = "OPPORTUNITY_LIST_SUCCESS";
export const OPPORTUNITY_LIST_FAIL = "OPPORTUNITY_LIST_FAIL";

export const OPPORTUNITY_OWN_LIST_REQUEST = "OPPORTUNITY_LIST_REQUEST";
export const OPPORTUNITY_OWN_LIST_SUCCESS = "OPPORTUNITY_LIST_SUCCESS";
export const OPPORTUNITY_OWN_LIST_FAIL = "OPPORTUNITY_LIST_FAIL";

export const OPPORTUNITY_DETAILS_REQUEST = "OPPORTUNITY_DETAILS_REQUEST";
export const OPPORTUNITY_DETAILS_SUCCESS = "OPPORTUNITY_DETAILS_SUCCESS";
export const OPPORTUNITY_DETAILS_FAIL = "OPPORTUNITY_DETAILS_FAIL";

export const OPPORTUNITY_SAVE_REQUEST = "OPPORTUNITY_SAVE_REQUEST";
export const OPPORTUNITY_SAVE_SUCCESS = "OPPORTUNITY_SAVE_SUCCESS";
export const OPPORTUNITY_SAVE_FAIL = "OPPORTUNITY_SAVE_FAIL";

export const OPPORTUNITY_DELETE_REQUEST = "OPPORTUNITY_DELETE_REQUEST";
export const OPPORTUNITY_DELETE_SUCCESS = "OPPORTUNITY_DELETE_SUCCESS";
export const OPPORTUNITY_DELETE_FAIL = "OPPORTUNITY_DELETE_FAIL";

export const OPPORTUNITY_SIGNUP_REQUEST = "OPPORTUNITY_SIGNUP_REQUEST";
export const OPPORTUNITY_SIGNUP_SUCCESS = "OPPORTUNITY_SIGNUP_SUCCESS";
export const OPPORTUNITY_SIGNUP_FAIL = "OPPORTUNITY_SIGNUP_FAIL";

export const OPPORTUNITY_LIST_VOLUNTEERS_REQUEST = "OPPORTUNITY_LIST_VOLUNTEERS_REQUEST";
export const OPPORTUNITY_LIST_VOLUNTEERS_SUCCESS = "OPPORTUNITY_LIST_VOLUNTEERS_SUCCESS";
export const OPPORTUNITY_LIST_VOLUNTEERS_FAIL = "OPPORTUNITY_LIST_VOLUNTEERS_FAIL";

export const OPPORTUNITY_LIST_NUM_VOLUNTEERS_REQUEST = "OPPORTUNITY_LIST_NUM_VOLUNTEERS_REQUEST";
export const OPPORTUNITY_LIST_NUM_VOLUNTEERS_SUCCESS = "OPPORTUNITY_LIST_NUM_VOLUNTEERS_SUCCESS";
export const OPPORTUNITY_LIST_NUM_VOLUNTEERS_FAIL = "OPPORTUNITY_LIST_NUM_VOLUNTEERS_FAIL";

export const OPPORTUNITY_CHECK_STATUS_REQUEST = "OPPORTUNITY_CHECK_STATUS_REQUEST";
export const OPPORTUNITY_CHECK_STATUS_SUCCESS = "OPPORTUNITY_CHECK_STATUS_SUCCESS";
export const OPPORTUNITY_CHECK_STATUS_FAIL = "OPPORTUNITY_CHECK_STATUS_FAIL";

export const OPPORTUNITY_LIST_ALL_VOLUNTEERS_REQUEST = "OPPORTUNITY_LIST_ALL_VOLUNTEERS_REQUEST";
export const OPPORTUNITY_LIST_ALL_VOLUNTEERS_SUCCESS = "OPPORTUNITY_LIST_ALL_VOLUNTEERS_SUCCESS";
export const OPPORTUNITY_LIST_ALL_VOLUNTEERS_FAIL = "OPPORTUNITY_LIST_ALL_VOLUNTEERS_FAIL";
