import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../assets/css/Carousel.css";
import HeartIconFilled from "../../assets/images/HeartIconFilled.png";
import HeartIconHollow from "../../assets/images/HeartIconHollow.png";
import axios from "axios";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Item = styled(Link)`
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	margin-left: min(2vw, 40px);
	margin-right: min(2vw, 40px);
	margin-bottom: max(7%, 55px);
`;

const RewardName = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-size: ${props => (props.$windowWidth < 500 ? "2.8vw" : "min(1.8vw, 24px)")};
	padding-top: min(1.4vw, 20px);
	height: min(4vw, 100px);
	margin: 0;
`;

const HeartIcon = styled.img`
	max-width: min(26px, 2.2vw);
	max-height: min(24px, 2vw);
	margin-top: min(1.8vw, 24px);
	user-select: none;
`;

const RewardImage = styled.img`
	width: 100%;
	max-width: 400px;
`;

const VowerPointContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: min(1.8vw, 24px);
	max-height: min(36px, 2.4vw);
`;

const VowerLogo = styled.img`
	max-height: ${props => (props.$windowWidth < 500 ? "4vw" : "min(30px, 2vw)")};
	max-width: ${props => (props.$windowWidth < 500 ? "4.4vw" : "min(33px, 2.2vw)")};
`;

const VowerPoints = styled.p`
	padding: 0;
	margin: 0;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: ${props => (props.$windowWidth < 500 ? "2.6vw" : "min(1.6vw, 22px)")};
	padding-left: min(1vw, 16px);
`;

const ShopNowButton = styled.div`
	font-family: Inter;
	font-weight: 700;
	font-size: min(${props => (props.$windowWidth < 500 ? "2.5vw" : "1.28vw")}, 16px);
	padding: 0;
	background: linear-gradient(180deg, #0fafb0 0%, #1d7a7a 100%);
	box-shadow: 0px 5px 41px 4px rgba(1, 103, 104, 0.7);
	border-radius: min(${props => (props.$windowWidth < 500 ? "1.5vw" : "0.8vw")}, 8px);
	align-items: center;
	justify-content: center;
	width: ${props => (props.$windowWidth < 500 ? "70%" : "80%")};
	margin-top: ${props => (props.$windowWidth < 500 ? "2.5vw" : "min(1.4vw, 20px)")};
	height: min(3vw, 45px);
	height: min(${props => (props.$windowWidth < 500 ? "5.9vw" : "3vw")}, 45px);
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
`;

export default function RewardCard(props) {
	const { id, handleItemLike, index, i2 } = props;
	const { windowWidth } = useWindowDimensions();
	const [incentive, setIncentive] = useState(null);

	const loadReward = async () => {
		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/incentives/${id}`);
		// const sampleData = {
		// 	_id: "-MUfWvhuS6viBJDv-V8s",
		// 	category: "Beauty",
		// 	featured: false,
		// 	images: [
		// 		"https://firebasestorage.googleapis.com/v0/b/vower-e3d5a.appspot.com/o/3CioiUVBQMYrgCH0zPjPRbM1YEq2%2Fimages%2F1614564274223.png?alt=media&token=005c3846-d8e8-47eb-ad07-1b63539d6a2c"
		// 	],
		// 	images_paths: ["/3CioiUVBQMYrgCH0zPjPRbM1YEq2/images/1614564274223.png"],
		// 	long_description:
		// 		"10A Grade 100% Virgin Human Hair, Full Cuticle Aligned, Can Be Dyed, Permed, Bleached, Highlighted, Curled, or Styled As Your Own Hair. Double Machine Weft for hair bundles. No Smelling, No Shedding, No Tangle, Tight and Neat. True to Length, 95-100g (3.2oz-3.5oz/bundle) per Bundle.",
		// 	name: "Peruvian Straight Hair Bundles: 18, 20, 22",
		// 	points: "60",
		// 	post_date: 1646104080620,
		// 	short_description: "Hair Bundles",
		// 	stock: 0,
		// 	userID: "fMzFlCbEQzYboLc5bNoQ32JN5rg1"
		// };
		setIncentive(data);
	};

	useEffect(() => {
		loadReward();
	}, []);

	return (
		incentive && (
			<Item key={id} to={`/incentive/${id}`}>
				<RewardImage alt="Reward" src={incentive.images && incentive.images[0]} />
				<div
					style={{
						height: windowWidth < 500 ? "10vw" : "min(5.5vw, 100px)",
						display: "flex",
						flexDirection: "row"
					}}
				>
					<RewardName $windowWidth={windowWidth}>{incentive.name}</RewardName>
					{/* Currently no backend support for liked items */}
					<div style={{ visibility: "hidden" }} onClick={() => handleItemLike(index, i2)}>
						<HeartIcon alt="Heart icon" src={incentive.liked ? HeartIconFilled : HeartIconHollow} />
					</div>
				</div>
				<VowerPointContainer>
					<VowerLogo $windowWidth={windowWidth} alt="Vower Logo" src={window.images.vowerLogoPNG} />
					<VowerPoints $windowWidth={windowWidth}>{incentive.points} Vower Points</VowerPoints>
				</VowerPointContainer>
				<ShopNowButton $windowWidth={windowWidth}>SHOP NOW</ShopNowButton>
			</Item>
		)
	);
}
