import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { detailsUser, saveUser } from "../actions/userActions";
import Loading from "../components/Loading";
import userUpdateTypes from "../constants/userUpdateTypes";

function StripeSuccessScreen() {
	const dispatch = useDispatch();
	const { userInfo } = useSelector(state => state.userSignin);
	const { user } = useSelector(state => state.userDetails);

	const [loading, setLoading] = useState(true);
	const [paid, setPaid] = useState(false);
	const [points, setPoints] = useState(0);
	const [price, setPrice] = useState(0);
	const [customerID, setCustomerID] = useState();
	const [paymentIntent, setPaymentIntent] = useState();

	const location = useLocation();
	const sessionId = location.search.replace("?session_id=", "");

	useEffect(() => {
		if (!userInfo) return;

		dispatch(detailsUser(userInfo._id));
	}, [userInfo]);

	useEffect(() => {
		if (user && !loading) {
			if (process.env.NODE_ENV === "production") {
				// Don't add points if they have already been added for the given checkout session
				if (Array.isArray(user.paymentIntents) && user.paymentIntents.includes(paymentIntent)) return;

				const newPaymentIntents = [...(user.paymentIntents || []), paymentIntent];
				dispatch(
					saveUser(
						{
							...user,
							paymentIntents: newPaymentIntents,
							points: parseInt(user.points || 0) + parseInt(points),
							customerID
						},
						userUpdateTypes.BUY_POINTS,
						{ points, price }
					)
				);
			} else {
				// Don't add points if they have already been added for the given checkout session
				if (Array.isArray(user.testPaymentIntents) && user.testPaymentIntents.includes(paymentIntent)) return;

				const newPaymentIntents = [...(user.testPaymentIntents || []), paymentIntent];
				dispatch(
					saveUser(
						{
							...user,
							testPaymentIntents: newPaymentIntents,
							points: parseInt(user.points || 0) + parseInt(points),
							testCustomerID: customerID
						},
						userUpdateTypes.BUY_POINTS,
						{ points, price }
					)
				);
			}
		}
	}, [user, loading]);

	useEffect(() => {
		async function getSession() {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/stripe/checkout-session?sessionId=${sessionId}`
			);

			const { data } = response;

			if (data.payment_status !== "paid") {
				setLoading(false);
				return;
			}

			const stripeProduct = data.line_items.data[0];
			const numPoints = stripeProduct.price.metadata.points;
			setPoints(numPoints);
			setPrice(stripeProduct.amount_total / 100);
			setCustomerID(data.customer);
			setPaymentIntent(data.payment_intent);
			setPaid(true);
			setLoading(false);
		}
		getSession();
	}, [sessionId]);

	return (
		<>
			<Helmet>
				<title>Vower | Points Purchase Success</title>
			</Helmet>
			<div className="text-center">
				{userInfo?.isOrganization && user?.verified && (
					<>
						{loading ? (
							<Loading />
						) : !paid ? (
							<p>You have not yet paid for this purchase</p>
						) : (
							<>
								<h1>Congratulations!</h1>
								<p>
									You have successfully purchased the points package for{" "}
									{price.toLocaleString("en-US", {
										style: "currency",
										currency: "USD"
									})}
									<br />
									{points} points have been added to your account
								</p>
							</>
						)}
					</>
				)}
				{((userInfo?.isOrganization && !user?.verified) || !userInfo?.isOrganization || userInfo?.isPerson) && (
					<>
						<h1>I am sorry.</h1>
						<p>Only Verified Organizations can buy points from Vower.</p>
					</>
				)}
				<Link to="/manage-opportunities" className="mr-3">
					<Button variant="main">Post Now!</Button>
				</Link>
				<Link to={`/profile/${userInfo._id}`}>
					<Button variant="main">Go to Your Profile</Button>
				</Link>
			</div>
		</>
	);
}

export default StripeSuccessScreen;
