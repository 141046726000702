import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveUser } from "../../actions/userActions";
import AddressBlock from "../blocks/AddressBlock";
import SocialBlock from "../blocks/SocialBlock";
import userUpdateTypes from "../../constants/userUpdateTypes";

function ManageOrganizationModal(props) {
	const dispatch = useDispatch();

	const [org, setOrg] = useState({});
	const [correct, setCorrect] = useState(false);

	useEffect(() => {
		if (props.modalVisible) setOrg(props.org);
	}, [props.modalVisible]);

	const hideModal = () => {
		props.setModalVisible(false);
		setCorrect(false);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	const handleFormSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		props.setModalVisible(false);
		dispatch(saveUser(org, userUpdateTypes.MANAGE_ORG, { justVerified: !props.org.verified && org.verified }));
	};

	return (
		<Modal dialogClassName="manage-modal" size="lg" show={props.modalVisible} onHide={hideModal}>
			<Form noValidate validated onSubmit={handleFormSubmit} onChange={handleFormChange}>
				<Modal.Body>
					<h2>{org._id ? "Update" : "Create"} Organization</h2>

					<Form.Group controlId="name">
						<Form.Label>Company Name</Form.Label>
						<Form.Control
							required
							type="text"
							value={org.name}
							onChange={e => setOrg({ ...org, name: e.target.value })}
						/>
					</Form.Group>

					<Form.Group controlId="phone">
						<Form.Label>Phone</Form.Label>
						<Form.Control
							type="tel"
							inputMode="tel"
							value={org.phone}
							onChange={e => setOrg({ ...org, phone: e.target.value })}
						/>
					</Form.Group>

					<Form.Group controlId="email">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							value={org.email}
							onChange={e => setOrg({ ...org, email: e.target.value })}
						/>
					</Form.Group>

					<AddressBlock
						address={org.address || {}}
						setAddress={address => {
							setOrg({ ...org, address });
						}}
					/>

					<Form.Group controlId="points">
						<Form.Label>Points</Form.Label>
						<Form.Control
							required
							type="number"
							inputMode="numeric"
							min={0}
							max={100}
							value={org.points}
							onChange={e => setOrg({ ...org, points: e.target.value })}
						/>
					</Form.Group>

					<SocialBlock
						social={org.social || {}}
						setSocial={social => {
							setOrg({ ...org, social });
						}}
					/>

					<Form.Group controlId="verified">
						<Form.Check
							label="Verified"
							checked={org.verified}
							onChange={e => setOrg({ ...org, verified: e.target.checked })}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="main" type="submit" disabled={!correct}>
						{org._id ? "Update" : "Create"}
					</Button>
					<Button variant="main" onClick={hideModal}>
						Close
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

ManageOrganizationModal.propTypes = {
	modalVisible: PropTypes.bool.isRequired,
	org: PropTypes.object.isRequired,
	setModalVisible: PropTypes.func.isRequired
};

export default ManageOrganizationModal;
