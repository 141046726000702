import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Cart, Filter } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listCategories } from "../actions/categoryActions";
import { listIncentives } from "../actions/incentiveActions";
import { detailsUser } from "../actions/userActions";
import Loading from "../components/Loading";
import Sidebar from "../components/Sidebar";

function IncentiveScreen() {
	const dispatch = useDispatch();

	const { userInfo } = useSelector(state => state.userSignin);
	const { user } = useSelector(state => state.userDetails);
	const { incentives, loading } = useSelector(state => state.incentiveList);
	const { categories } = useSelector(state => state.categoryList);

	const [sidebarExpanded, setSidebarExpanded] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState("");
	const [selectedFilterName, setSelectedFilterName] = useState("");
	const [lastKey, setLastKey] = useState("");

	const handleScroll = debounce(
		useCallback(() => {
			// screen reached bottom
			if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
				// prevent from calling the API multiple times
				if (loading) return;

				// if there are no more entries to load, don't call the API
				const newLastKey = incentives?.length ? incentives[incentives.length - 1]._id : "";
				if (lastKey === newLastKey) return;

				const params = {
					filterName: selectedFilterName,
					filter: selectedFilter,
					lastKey: newLastKey
				};

				setLastKey(newLastKey);
				dispatch(listIncentives(params, false));
			}
		}, [incentives, loading]),
		100
	);

	useEffect(() => {
		dispatch(listCategories("incentive_category"));
		dispatch(listIncentives());
		if (userInfo?.isPerson) dispatch(detailsUser(userInfo._id));
	}, []);

	useLayoutEffect(() => {
		document.removeEventListener("scroll", handleScroll);
		document.addEventListener("scroll", handleScroll);

		// Function that runs when the component is being unmounted
		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, [incentives, loading]);

	const updateSelectedFilter = (filterName, value) => {
		const newFilterName = value === selectedFilter ? "" : filterName;
		let newFilter = value === selectedFilter ? "" : value;

		if (value === "Affordable") {
			newFilter = user?.points || 0;
		}

		setSelectedFilterName(newFilterName);
		setSelectedFilter(newFilter);
		setLastKey("");

		const params = {
			filterName: newFilterName,
			filter: newFilter
		};

		dispatch(listIncentives(params));

		window.scrollTo(0, 0);
	};

	return (
		<>
			<Helmet>
				<title>Vower | Incentives</title>
			</Helmet>
			<div id="incentives-screen">
				<div className="mt-3 mt-md-5 sidebar-container">
					<Sidebar
						filters={{
							Category: categories.map(category => category.category_name),
							Availability: ["In Stock"],
							...(userInfo?.isPerson && { Affordability: ["Affordable"] })
						}}
						selectedFilter={selectedFilter}
						updateSelectedFilter={updateSelectedFilter}
						expanded={sidebarExpanded}
						setExpanded={setSidebarExpanded}
					/>
					<div>
						<Container>
							<div className="d-flex align-items-center mb-4 mb-md-3">
								<div className="sidebar-expander" onClick={() => setSidebarExpanded(!sidebarExpanded)}>
									<div>Filters</div>
									<Filter
										size="2.5rem"
										style={{
											padding: "0.25rem 0",
											borderRadius: "5px"
										}}
									/>
								</div>
								<Link to="/cart" className="ml-auto mr-2">
									<Cart size="2rem" />
								</Link>
							</div>
							{/* Has 2 columns between xs and sm using css */}
							<Row xs={1} sm={3} md={2} lg={3} xl={4} className="xs-double">
								{incentives?.length > 0
									? incentives.map(incentive => (
											<Col key={incentive._id} className="mb-4 d-flex justify-content-center">
												<Link to={`/incentive/${incentive._id}`} className="listing">
													<div className="square-img-box listing-image">
														<Image src={incentive.images?.[0]} alt={incentive.name} />
													</div>
													<div style={{ fontSize: "1.25rem" }}>{incentive.name}</div>
													<div className="points">
														<Image
															src={window.images.vowerLogoPNG}
															className="vower-logo"
															alt="Vower"
														/>
														<span>{incentive.points}</span> Points
													</div>
												</Link>
											</Col>
									  ))
									: !loading && (
											<Col style={{ maxWidth: "100%", flexBasis: "100%" }}>
												<p style={{ fontSize: "1.5rem" }}>
													No incentives match your filters :(
												</p>
											</Col>
									  )}
							</Row>
							<Row xs={1} sm={3} md={2} lg={3} xl={4} className="xs-double">
								{loading && <Loading />}
							</Row>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
}
export default IncentiveScreen;
