import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { detailsUser } from "../actions/userActions";
import Loading from "../components/Loading";
import Testimonials from "../components/Testimonials";

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const pointsPackage = process.env.REACT_APP_POINTS_PACKAGE_STRIPE_ID;
const stripePromise = loadStripe(apiKey);

function BuyPointsScreen(props) {
	const theme = useSelector(state => state.theme.theme);

	const dispatch = useDispatch();

	const { userInfo } = useSelector(state => state.userSignin);
	const { user, loading } = useSelector(state => state.userDetails);

	const [show, setShow] = useState(false);
	const [packages, setPackages] = useState([]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		if (!userInfo) return;

		dispatch(detailsUser(userInfo._id));
	}, [userInfo]);

	useEffect(() => {
		// Get different package tiers from stripe
		async function getPrices() {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/stripe/product-prices?product=${pointsPackage}`
			);
			setPackages(
				response.data.data.map(price => ({
					price: price.unit_amount / 100,
					points: price.metadata.points,
					id: price.id
				}))
			);
		}
		getPrices();
	}, []);

	const openCheckout = async (priceId, email, customerID) => {
		const stripe = await stripePromise;

		const response = await axios.post(
			`${process.env.REACT_APP_BACKEND_URL}/stripe/create-checkout-session`,
			{ priceId, customer_email: email, customer: customerID },
			{
				headers: {
					Authorization: `Bearer ${apiKey}`
				}
			}
		);

		const { id } = response.data;
		await stripe.redirectToCheckout({
			sessionId: id
		});
	};

	function BuyButton({ priceId }) {
		if (loading) {
			return <Loading />;
		}

		const isOrg = userInfo?.isOrganization;
		const { verified } = user;
		const handleClick = isOrg && verified ? () => openCheckout(priceId, user.email, user.customerID) : handleShow;

		return (
			<Button variant="main" onClick={handleClick}>
				Purchase Package
			</Button>
		);
	}

	function BuyModal() {
		if (loading) return <div />;

		const isOrg = userInfo?.isOrganization;
		const isPerson = userInfo?.isPerson;
		const { verified } = user;

		return (
			<Modal show={show} onHide={handleClose} dialogClassName="manage-modal">
				<Modal.Body className="d-flex flex-column align-items-center text-center">
					{!userInfo && (
						<>
							<h2>You Are Not Signed In</h2>
							<p>Sign up for or sign into an account below!</p>
							<div
								className="d-flex flex-wrap justify-content-center align-items-center"
								style={{ gap: "0.5rem" }}
							>
								<Link to="/register">
									<Button variant="main">Register a New Account</Button>
								</Link>
								<div>or</div>
								<Link to="/sign-in">
									<Button variant="main">Sign In</Button>
								</Link>
							</div>
						</>
					)}
					{isPerson && (
						<>
							<h2>Personal Accounts cannot buy points</h2>
						</>
					)}
					{isOrg && !verified && (
						<>
							<h2>Your Organization Must be Verified to Purchase a Plan</h2>
							<p>
								Before you can choose a plan, we need to verify you. You can contact us using the form
								below if you urgently require verification.
							</p>
							<Link to="/contact-us">
								<Button variant="main">Contact Us</Button>
							</Link>
						</>
					)}
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="main" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<>
			<Helmet>
				<title>Vower | Buy Points</title>
			</Helmet>

			<div>
				<div className="hero-container">
					<img src={window.images.buyPointsHero} alt="volunteers" />
					<div>Want to get volunteers for your business?</div>
				</div>

				<BuyModal />

				<Container className="mt-5">
					<div className="text-center">
						<h1 className="mb-4">Choose a Points Package</h1>
						<Row xs={1} md={2} style={{ rowGap: "1rem" }} className="xs-double">
							{!packages ? (
								<Loading />
							) : (
								packages
									.sort((a, b) => a.points - b.points)
									.map((pack, i) => {
										const { price, points, id } = pack;
										return (
											<Col key={price}>
												<div className="points-package">
													{i === packages.length - 1 && (
														<div className="best-deal">Best Deal</div>
													)}
													<div className="num-points">
														<img
															src={window.images.vowerLogoPNG}
															alt="vower"
															className="mr-2"
														/>
														<div>{points} Points</div>
													</div>
													<div className="price">
														{price.toLocaleString("en-US", {
															style: "currency",
															currency: "USD"
														})}
													</div>
													{/* <div>
													{(price / parseInt(points)).toLocaleString("en-US", {
														style: "currency",
														currency: "USD"
													})}
													/point
												</div> */}
													<BuyButton priceId={id} />
												</div>
											</Col>
										);
									})
							)}
						</Row>
					</div>

					<div
						className="justify-content-center text-center"
						style={{ marginTop: "8rem", marginBottom: "3rem" }}
					>
						<Testimonials />
					</div>
					<div className="d-flex justify-content-center mb-5">
						<Link to="/register">
							<Button variant="main" style={{ fontSize: "1.75rem", padding: "0.5rem 1rem" }}>
								Register Now
							</Button>
						</Link>
					</div>
				</Container>
			</div>
		</>
	);
}

export default BuyPointsScreen;
