import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Carousel } from "react-bootstrap";
import "../../assets/css/Carousel.css";
import RewardCard from "./RewardCard";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LeftCarouselArrow from "../../assets/images/LeftCarouselArrow.svg";
import RightCarouselArrow from "../../assets/images/RightCarouselArrow.svg";

const CarouselWrapper = styled.div`
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: ${props => (props.$windowWidth < 500 ? "90vw" : "min(1200px, 82vw)")};
`;

const Container = styled(Carousel)`
	margin: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: auto;
	z-index: 1;
`;

const ItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const Arrow = styled.img`
	max-width: min(${props => (props.$windowWidth < 500 ? "3vw" : "2vw")}, 20px);
	margin-top: min(${props => (props.$windowWidth < 500 ? "15vw" : "10vw")}, 150px);
	cursor: pointer;
`;

export default function RewardsCarousel() {
	const [rewards, setRewards] = useState([]);
	const { windowWidth } = useWindowDimensions();
	const rewardsRef = useRef();

	const PrevIcon = () => {
		return <Arrow $windowWidth={windowWidth} onClick={() => rewardsRef.current.prev()} src={LeftCarouselArrow} />;
	};

	const NextIcon = () => {
		return <Arrow $windowWidth={windowWidth} onClick={() => rewardsRef.current.next()} src={RightCarouselArrow} />;
	};

	useEffect(() => {
		let sampleRewards = [];
		if (windowWidth > 500) {
			sampleRewards = [
				["-MUfWvhuS6viBJDv-V8s", "-MUfXQdtV0qXdYxsMuih", "-MUfXpr0y-J2PQgv_R3l"],
				["-MUetqA3EBrQs0wEzCRk", "-MUfe3lXmtqtkqeI6Wrc", "-Mx088hq_-bjmn7sZkLt"],
				["-NBMTqxYCv-7dJxzsBo5", "-MUfellkT8UlfXWxPnmP", "-ND7BfH1jPlX5_MuDqzy"]
			];
		} else {
			sampleRewards = [
				["-MUfWvhuS6viBJDv-V8s", "-MUfXQdtV0qXdYxsMuih"],
				["-MUetqA3EBrQs0wEzCRk", "-MUfe3lXmtqtkqeI6Wrc"],
				["-Mx088hq_-bjmn7sZkLt", "-NBMTqxYCv-7dJxzsBo5"],
				["-MUfellkT8UlfXWxPnmP", "-ND7BfH1jPlX5_MuDqzy"]
			];
		}
		setRewards(sampleRewards);
	}, []);

	// const handleItemLike = (index, i2) => {
	// 	const newRewardValue = {
	// 		...rewards[index][i2],
	// 		liked: !rewards[index][i2].liked
	// 	};
	// 	let newRewards = [...rewards];
	// 	newRewards[index][i2] = newRewardValue;
	// 	setRewards(newRewards);
	// };

	return (
		<CarouselWrapper $windowWidth={windowWidth}>
			<PrevIcon />
			<Container controls={true} ref={rewardsRef} nextIcon={NextIcon} prevIcon={PrevIcon}>
				{rewards.map((itemGroup, index) => (
					<Carousel.Item key={itemGroup + index} style={{ width: "100%" }}>
						<ItemContainer>
							{itemGroup.map((item, i2) => (
								<RewardCard
									key={item + i2 + index}
									id={item}
									index={index}
									i2={i2}
									handleItemLike={() => {}}
								/>
							))}
						</ItemContainer>
					</Carousel.Item>
				))}
			</Container>
			<NextIcon />
		</CarouselWrapper>
	);
}
