import React, { useEffect } from "react";
import { Alert, Button, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { editCartQuantity, removeFromCart } from "../actions/cartActions";
import { createOrder } from "../actions/orderActions";

function PlaceOrderScreen() {
	const cart = useSelector(state => state.cart);
	const { success, order, error } = useSelector(state => state.orderCreate);
	const navigate = useNavigate();

	const { cartItems, totalCost, shipping } = cart;

	const dispatch = useDispatch();

	const placeOrderHandler = () => {
		dispatch(createOrder({ order: cart }));
	};
	useEffect(() => {
		if (success) {
			navigate(`/order/${order._id}`);
		}
	}, [success]);

	const disablePlaceOrder = cartItems.length === 0;

	return (
		<>
			{!shipping.line1 && <Navigate to="/shipping" />}
			<Helmet>
				<title>Vower | Place Order</title>
			</Helmet>
			<div>
				<div>
					<div>
						<div>
							<div className="d-flex">
								<h3 className="mr-2">Mailing Address</h3>
								<Link to="/shipping">
									<Button variant="main">Edit Address</Button>
								</Link>
							</div>
							<div>
								{shipping.line1}, {shipping.city}, {shipping.state}, {shipping.zip}
							</div>
						</div>
						<div className="mt-3">
							<div className="cart-title">
								<h3>Order Items:</h3>
								<div className="price-title"># Points</div>
							</div>
							<div className="cart-list">
								{cartItems.length === 0 ? (
									<div>Cart is empty</div>
								) : (
									cartItems.map(item => (
										<div key={item._id} className="cart-item">
											<div className="cart-image-container">
												<img src={item.image} alt={item.name} />
											</div>
											<div className="cart-item-middle">
												<div>
													<Link to={`/incentive/${item._id}`}>{item.name}</Link>
												</div>
												<div className="cart-item-actions">
													<label className="mr-1" htmlFor={`qty-select-${item._id}`}>
														Quantity:
													</label>
													<select
														className="mr-3 my-select"
														id={`qty-select-${item._id}`}
														value={item.quantity}
														onChange={e =>
															dispatch(editCartQuantity(item._id, e.target.value))
														}
													>
														{[...Array(item.stock).keys()].map(x => (
															<option key={x + 1} value={x + 1}>
																{x + 1}
															</option>
														))}
													</select>
													<Button
														variant="main"
														onClick={() => dispatch(removeFromCart(item._id))}
													>
														Delete
													</Button>
												</div>
											</div>
											<div className="cart-item-price">
												<span>
													<Image src={window.images.vowerLogoPNG} alt="vower logo" />
												</span>
												{item.points} <span>Points</span>
											</div>
										</div>
									))
								)}
							</div>
							<div className="cart-checkout">
								<div>
									<div className="h1 mb-0">Total Cost: </div>
									<div className="d-flex align-items-center">
										<div className="mr-2">
											<Image
												src={window.images.vowerLogoPNG}
												alt="vower logo"
												style={{ maxHeight: "3rem" }}
											/>
										</div>
										<div className="h1 mb-0">{totalCost}</div>
										<div className="h3 mb-0" style={{ fontWeight: 400 }}>
											&nbsp;Points
										</div>
									</div>
								</div>
								<Button variant="main" onClick={placeOrderHandler} disabled={disablePlaceOrder}>
									Place Order
								</Button>
							</div>
							{error && (
								<Alert variant="danger" className="mt-3 mb-0">
									{error}
								</Alert>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PlaceOrderScreen;
