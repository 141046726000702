/* src/firebase.js
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyCwpLWzjhEhe4psuI9e_XAU4GPCHF0vCoQ",
	authDomain: "vower-e3d5a.firebaseapp.com",
	databaseURL: "https://vower-e3d5a.firebaseio.com/",
	projectId: "vower-e3d5a",
	storageBucket: "vower-e3d5a.appspot.com",
	messagingSenderId: "106168829623",
	appId: "1:106168829623:web:5996f62aa144a7403aaf32"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();

export { auth, storage, firebase };
