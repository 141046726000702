import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signin } from "../actions/userActions";
import "../assets/css/forms.css";
import Loading from "../components/Loading";

function SigninScreen() {
	const dispatch = useDispatch();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const { userInfo, loading, error } = useSelector(state => state.userSignin);

	useEffect(() => {
		if (userInfo) {
			let redirect = `/profile/${userInfo._id}`;
			if (userInfo.isPerson) {
				redirect = "/opportunities";
			}
			navigate(redirect);
		}
	}, [userInfo]);

	const handleSubmit = e => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.stopPropagation();
		} else {
			dispatch(signin(email, password));
		}
	};

	return (
		<>
			<Helmet>
				<title>Vower | Sign In</title>
			</Helmet>
			<div id="signin-page" className="d-flex flex-column align-items-center">
				<h1 className="text-center mb-4">Sign In</h1>
				<div style={{ width: "min(85%, 500px)" }}>
					<Form noValidate onSubmit={handleSubmit} className="d-flex flex-column justify-content-center">
						{/* <div className="text-center" style={{ fontSize: "1.5rem" }}>
							Social Sign In
						</div>
						<SocialSignIn />

						<div className="separator">
							<hr />
							<div>or</div>
							<hr />
						</div>

						<div className="text-center mb-3" style={{ fontSize: "1.5rem" }}>
							Email & Password Sign In
						</div> */}
						<Form.Group controlId="signin-email-input">
							<Form.Control
								required
								type="email"
								placeholder="Email"
								text={email}
								onChange={input => setEmail(input.target.value)}
							/>
							<Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="signin-password-input">
							<Form.Control
								required
								type="password"
								placeholder="Password"
								text={password}
								onChange={input => setPassword(input.target.value)}
							/>
							<Form.Control.Feedback type="invalid">Please enter your password</Form.Control.Feedback>
						</Form.Group>
						<Row>
							<Form.Group as={Col} xs={12} sm className="text-center text-sm-left">
								<Form.Check label="Remember Me" />
							</Form.Group>
							<Form.Group as={Col} cs={12} sm className="text-center text-sm-right">
								<Link to="/reset-password">Forgot Password?</Link>
							</Form.Group>
						</Row>
						{error && <Alert variant="danger">{error}</Alert>}
						{loading ? (
							<Loading text="Signing In..." />
						) : (
							<Button type="submit" variant="main" className="align-self-center">
								Sign In
							</Button>
						)}

						<div className="separator">
							<hr />
							<div>or</div>
							<hr />
						</div>

						<Link to="/register" className="align-self-center">
							<Button variant="main">Create New Account</Button>
						</Link>
					</Form>
				</div>
			</div>
		</>
	);
}
export default SigninScreen;
