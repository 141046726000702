export const INCENTIVE_LIST_REQUEST = "INCENTIVE_LIST_REQUEST";
export const INCENTIVE_LIST_SUCCESS = "INCENTIVE_LIST_SUCCESS";
export const INCENTIVE_LIST_FAIL = "INCENTIVE_LIST_FAIL";

export const INCENTIVE_DETAILS_REQUEST = "INCENTIVE_DETAILS_REQUEST";
export const INCENTIVE_DETAILS_SUCCESS = "INCENTIVE_DETAILS_SUCCESS";
export const INCENTIVE_DETAILS_FAIL = "INCENTIVE_DETAILS_FAIL";

export const INCENTIVE_SAVE_REQUEST = "INCENTIVE_SAVE_REQUEST";
export const INCENTIVE_SAVE_SUCCESS = "INCENTIVE_SAVE_SUCCESS";
export const INCENTIVE_SAVE_FAIL = "INCENTIVE_SAVE_FAIL";

export const INCENTIVE_DELETE_REQUEST = "INCENTIVE_DELETE_REQUEST";
export const INCENTIVE_DELETE_SUCCESS = "INCENTIVE_DELETE_SUCCESS";
export const INCENTIVE_DELETE_FAIL = "INCENTIVE_DELETE_FAIL";

export const INCENTIVE_REVIEW_SAVE_REQUEST = "INCENTIVE_REVIEW_SAVE_REQUEST";
export const INCENTIVE_REVIEW_SAVE_SUCCESS = "INCENTIVE_REVIEW_SAVE_SUCCESS";
export const INCENTIVE_REVIEW_SAVE_FAIL = "INCENTIVE_REVIEW_SAVE_FAIL";
export const INCENTIVE_REVIEW_SAVE_RESET = "INCENTIVE_REVIEW_SAVE_RESET";
