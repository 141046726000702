import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveCategory } from "../../actions/categoryActions";

function ManageCategoryModal(props) {
	const dispatch = useDispatch();

	const [category, setCategory] = useState({});
	const [correct, setCorrect] = useState(false);

	useEffect(() => {
		if (props.modalVisible) setCategory({ category_name: "", category_type: "opportunity_category" });
	}, [props.modalVisible]);

	const hideModal = () => {
		props.setModalVisible(false);
		setCorrect(false);
	};

	const handleFormChange = e => {
		setCorrect(e.currentTarget.checkValidity());
	};

	const handleFormSubmit = e => {
		e.preventDefault();

		const form = e.currentTarget;
		setCorrect(form.checkValidity());
		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		props.setModalVisible(false);
		dispatch(saveCategory(category));
	};

	return (
		<Modal dialogClassName="manage-modal" size="lg" show={props.modalVisible} onHide={hideModal}>
			<Form noValidate validated onSubmit={handleFormSubmit} onChange={handleFormChange}>
				<Modal.Body>
					<h2>Create Category</h2>

					<Form.Group controlId="category_name">
						<Form.Label htmlFor="category_name">Category Name</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder="Category Name"
							pattern="^[^\s]+(\s+[^\s]+)*$"
							onChange={e => setCategory({ ...category, category_name: e.target.value })}
						/>
					</Form.Group>

					<Form.Group controlId="category_type">
						<Form.Label htmlFor="category_type">Category Type</Form.Label>
						<Form.Control
							required
							as="select"
							className="custom-select"
							onChange={e => setCategory({ ...category, category_type: e.target.value })}
						>
							<option value="opportunity_category">Opportunity</option>
							<option value="incentive_category">Incentive</option>
						</Form.Control>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="main" type="submit" disabled={!correct}>
						Create
					</Button>
					<Button variant="main" onClick={hideModal}>
						Close
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

ManageCategoryModal.propTypes = {
	modalVisible: PropTypes.bool.isRequired,
	setModalVisible: PropTypes.func.isRequired
};

export default ManageCategoryModal;
