import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { deleteOrder, listOrders } from "../../actions/orderActions";
import ManageOrderModal from "../../components/modals/ManageOrderModal";

function ManageOrdersScreen(props) {
	const dispatch = useDispatch();

	const { userInfo } = useSelector(state => state.userSignin);
	const { orders } = useSelector(state => state.orderList);
	const { success: successDelete } = useSelector(state => state.orderDelete);
	const { success: successEdit } = useSelector(state => state.orderEdit);

	const [currOrder, setCurrOrder] = useState({});
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		dispatch(listOrders());
	}, [successDelete, successEdit]);

	const editOrder = order => {
		setCurrOrder(order);
		setModalVisible(true);
	};

	const handleDelete = order => {
		if (window.confirm("Are you sure you want to delete this order?")) {
			dispatch(deleteOrder(order));
		}
	};

	return (
		<>
			{!userInfo.isAdmin && <Navigate to="/" />}
			<Helmet>
				<title>Vower | Orders</title>
			</Helmet>
			<div>
				<ManageOrderModal order={currOrder} modalVisible={modalVisible} setModalVisible={setModalVisible} />
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<h3>Manage Order</h3>
				</div>
				<Table responsive>
					<thead>
						<tr>
							<th>ID</th>
							<th>Date</th>
							<th>Total Cost</th>
							<th>User Id</th>
							<th>Order Status</th>
							<th>Edit</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						{orders &&
							orders.map(order => (
								<tr key={order._id}>
									<td>
										<Link to={`/order/${order._id}`}>{order._id}</Link>
									</td>
									<td>{new Date(order.order_date).toString()}</td>
									<td>{order.total_cost}</td>
									<td>
										<Link to={`/profile/${order.user_id}`}>{order.user_id}</Link>
									</td>
									<td>{order.order_status}</td>
									<td>
										<Button variant="main" onClick={() => editOrder(order)}>
											Edit
										</Button>
									</td>
									<td>
										<Button variant="main" onClick={() => handleDelete(order)}>
											Delete
										</Button>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			</div>
		</>
	);
}
export default ManageOrdersScreen;
