import PropTypes from "prop-types";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";

function CustomRecaptcha(props) {
	const theme = useSelector(state => state.theme.theme);
	return (
		<ReCAPTCHA onChange={e => props.onChange(e)} theme={theme} sitekey="6LdrTYAaAAAAAHXIP1ROI3k6ll5LozUAeL0aCjon" />
	);
}

CustomRecaptcha.propTypes = {
	onChange: PropTypes.func.isRequired
};

export default CustomRecaptcha;
